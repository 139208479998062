import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AffiliateProductDetailsHeaderComponent } from '@modules/admin/affiliates/components/affiliate-product-details-header/affiliate-product-details-header.component';
import { MatButtonModule } from '@angular/material/button';
import { MatChipsModule } from '@angular/material/chips';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { EventTemplateService } from '@services/events/event-template.service';
import { EventTemplate } from '@models/design-templates/event-template.model';
import { DesignTemplateOverlayPageComponent } from '@modules/admin/design-templates/create-template/dialogs/design-template-overlay-page/design-template-overlay-page.component';
import { EventTemplateAccess } from '@models/design-templates/event-template-access.enum';
import { EventTemplateRelevance } from '@models/design-templates/event-template-relevance.enum';
import { DesignTemplateDetailsHeaderComponent } from '@modules/admin/design-templates/components/design-template-details-header/design-template-details-header.component';

@Component({
  selector: 'app-design-template-details',
  standalone: true,
  imports: [
    CommonModule,
    AffiliateProductDetailsHeaderComponent,
    MatButtonModule,
    MatChipsModule,
    TranslateModule,
    DesignTemplateDetailsHeaderComponent,
  ],
  providers: [EventTemplateService],
  templateUrl: './design-template-details.page.html',
  styleUrl: './design-template-details.page.scss',
})
export class DesignTemplateDetailsPage implements OnInit {
  eventTemplate?: EventTemplate;
  createdAt: Date | undefined;

  currentLang = 'de';

  constructor(
    private route: ActivatedRoute,
    private eventTemplateService: EventTemplateService,
    private dialog: MatDialog,
    private translateService: TranslateService,
  ) {
    this.translateService.onLangChange.subscribe(() => {
      // let language = localStorage.getItem('userLang');
      // this.currentLang = language ? language : 'de';
      this.currentLang = this.translateService.currentLang;
    });

    // let language = localStorage.getItem('userLang');
    // this.currentLang = language ? language : 'de';
    this.currentLang = this.translateService.currentLang;
  }

  ngOnInit() {
    const eventTemplateId = this.route.snapshot.params['id'];

    this.eventTemplateService
      .get(eventTemplateId, this.currentLang)
      .subscribe((eventTemplate) => {
        this.eventTemplate = eventTemplate;
        this.createdAt = this.eventTemplate.createdAt;
      });
  }

  onPause(event: EventTemplate) {
    this.eventTemplate = event;
  }

  onContinue(event: EventTemplate) {
    this.eventTemplate = event;
  }

  onArchive(event: EventTemplate) {
    this.eventTemplate = event;
  }

  onRestore(event: EventTemplate) {
    this.eventTemplate = event;
  }

  editEventTemplate() {
    const dialogRef = this.dialog.open(DesignTemplateOverlayPageComponent, {
      width: '100%',
      height: '100%',
      maxWidth: '100%',
      panelClass: 'overlay-page-dialog',
      data: {
        eventTemplate: this.eventTemplate,
        isEdit: true,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result?.refresh) {
        this.eventTemplate = result.eventTemplate;
        this.createdAt = result.eventTemplate?.createdAt;
      }
    });
  }

  getDisplayStatus(status?: EventTemplateAccess) {
    return EventTemplateAccess.getDisplayName(status);
  }

  getDisplayRelevance(relevance?: EventTemplateRelevance) {
    return EventTemplateRelevance.getDisplayName(relevance);
  }
}
