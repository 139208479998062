<div class="product-details-header-container">
  <div class="left-side" (click)="backToList()">
    <i class="far fa-chevron-left"></i>

    <h1 class="page-header">
      {{ "APP.CAMPAIGNS.LIST_HEADER" | translate }}
    </h1>
  </div>

  <div></div>

  <div class="right-side" *ngIf="campaign">
    <button
      mat-menu-item
      (click)="sendGhostVotes(campaign.id)"
      *ngIf="campaign.status === CampaignStatus.ACTIVE"
    >
      <span class="menu-item-content">
        <i class="far fa-ghost"></i>
        <span class="text">{{
            "APP.CAMPAIGNS.ACTIONS.GHOST_VOTE" | translate
          }}</span>
      </span>
    </button>

    <button
      mat-menu-item
      (click)="setStatus(campaign.id, CampaignStatus.INACTIVE)"
      *ngIf="campaign.status === CampaignStatus.ACTIVE"
    >
      <span class="menu-item-content">
        <i class="far fa-pause"></i>
        <span class="text">{{
          "APP.AFFILIATE_PRODUCTS_PAGE_LIST.ACTIONS.PAUSE" | translate
        }}</span>
      </span>
    </button>

    <button
      mat-menu-item
      (click)="setStatus(campaign.id, CampaignStatus.ACTIVE)"
      *ngIf="campaign.status === CampaignStatus.INACTIVE"
    >
      <span class="menu-item-content">
        <i class="far fa-play"></i>
        <span class="text">{{
          "APP.AFFILIATE_PRODUCTS_PAGE_LIST.ACTIONS.CONTINUE" | translate
        }}</span>
      </span>
    </button>

    <button mat-menu-item (click)="deleteCampaign(campaign)">
      <span class="menu-item-content">
        <i class="far fa-trash"></i>
        <span class="text">{{
          "APP.AFFILIATE_PRODUCTS_PAGE_LIST.ACTIONS.DELETE" | translate
        }}</span>
      </span>
    </button>
  </div>
</div>

<hr class="hr" />
