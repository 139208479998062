import { NativeDateAdapter } from '@angular/material/core';
import { Injectable } from '@angular/core';
import moment from 'moment';

@Injectable()
export class AppDateAdapter extends NativeDateAdapter {
  override format(date: Date, displayFormat: Object): string {
    return moment(date).format('DD. MMMM YYYY');
  }

  override parse(value: any): Date | null {
    if (!value) {
      return null;
    }

    // We have no way using the native JS Date to set the parse format or locale, so we ignore these
    // parameters.
    if (typeof value === 'number') {
      return new Date(value);
    }

    const parts = value.split('.');
    return new Date(
      parseInt(parts[2], 10),
      parseInt(parts[1], 10) - 1,
      parseInt(parts[0], 10),
    );
  }

  override getFirstDayOfWeek(): number {
    return 1;
  }
}

export const APP_DATE_FORMATS = {
  parse: {
    dateInput: { day: 'numeric', month: 'numeric', year: 'numeric' },
  },
  display: {
    dateInput: 'input',
    monthYearLabel: { year: 'numeric', month: 'numeric' },
    dateA11yLabel: { year: 'numeric', month: 'long', day: 'numeric' },
    monthYearA11yLabel: { year: 'numeric', month: 'long' },
  },
};
