<div class="list-page-container">
  <div>
    <h1 class="page-header">{{ "APP.CAMPAIGNS.LIST_HEADER" | translate }}</h1>

    <hr class="hr" />
  </div>

  <div>
    <div>
      <app-filter
        [isCampaignListPage]="true"
        (filterChange)="onFilterChange($event)"
      ></app-filter>
    </div>

    <div class="table-scrollable" [class.loading]="dataSource.loading$ | async">
      <mat-table matSort [dataSource]="dataSource" class="mat-elevation-z8">
        <!-- Add this checkbox column definition inside the table -->
        <ng-container matColumnDef="select">
          <mat-header-cell *matHeaderCellDef>
            <mat-checkbox (change)="masterToggle()"></mat-checkbox>
          </mat-header-cell>
          <mat-cell *matCellDef="let row">
            <mat-checkbox (change)="toggleSelection(row)"></mat-checkbox>
          </mat-cell>
        </ng-container>

        <!-- Name Column -->
        <ng-container matColumnDef="name">
          <mat-header-cell mat-sort-header *matHeaderCellDef>{{
            "APP.DESIGN_TEMPLATES.TABLE_HEADERS.NAME" | translate
          }}</mat-header-cell>
          <mat-cell *matCellDef="let campaign">
            <div class="name-and-picture-col">
              <img
                *ngIf="campaign.logoUrl"
                [src]="campaign.logoUrl"
                alt="Campaign Logo"
              />
              <div class="ellipsis-field">{{ campaign.name }}</div>
            </div>
          </mat-cell>
        </ng-container>

        <!-- ID Column -->
        <ng-container matColumnDef="id">
          <mat-header-cell mat-sort-header *matHeaderCellDef>{{
            "APP.DESIGN_TEMPLATES.TABLE_HEADERS.ID" | translate
          }}</mat-header-cell>
          <mat-cell *matCellDef="let campaign">{{ campaign.id }}</mat-cell>
        </ng-container>

        <!-- Creation Date Column -->
        <ng-container matColumnDef="creationDate">
          <mat-header-cell mat-sort-header *matHeaderCellDef>{{
            "APP.CREATION_DATE" | translate
          }}</mat-header-cell>
          <mat-cell *matCellDef="let campaign">{{
            campaign.createdAt.getTime() > 0
              ? (campaign.createdAt | date: "dd.MM.yyy")
              : "n/a"
          }}</mat-cell>
        </ng-container>

        <!-- URI Column -->
        <ng-container matColumnDef="uri">
          <mat-header-cell mat-sort-header *matHeaderCellDef>{{
            "APP.CAMPAIGNS.URI" | translate
          }}</mat-header-cell>
          <mat-cell *matCellDef="let campaign">{{ campaign.uri }} </mat-cell>
        </ng-container>

        <!-- Actions Column -->
        <ng-container matColumnDef="actions" stickyEnd>
          <mat-header-cell *matHeaderCellDef>{{
            "APP.DESIGN_TEMPLATES.TABLE_HEADERS.ACTIONS" | translate
          }}</mat-header-cell>
          <mat-cell *matCellDef="let campaign">
            <button
              mat-icon-button
              [matMenuTriggerFor]="menu"
              class="more-vert-rotate-90"
              (click)="$event.stopPropagation()"
            >
              <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #menu="matMenu" class="table-actions-container">
              <button mat-menu-item (click)="onEdit(campaign)">
                <span class="menu-item-content">
                  <i class="far fa-pen"></i>
                  <span class="text">{{ "APP.EDIT" | translate }}</span>
                </span>
              </button>
              <button
                mat-menu-item
                (click)="setStatus(campaign.id, CampaignStatus.INACTIVE)"
                *ngIf="campaign.status === CampaignStatus.ACTIVE"
              >
                <span class="menu-item-content">
                  <i class="far fa-pause"></i>
                  <span class="text">{{
                    "APP.AFFILIATE_PRODUCTS_PAGE_LIST.ACTIONS.PAUSE" | translate
                  }}</span>
                </span>
              </button>

              <button
                mat-menu-item
                (click)="setStatus(campaign.id, CampaignStatus.ACTIVE)"
                *ngIf="campaign.status === CampaignStatus.INACTIVE"
              >
                <span class="menu-item-content">
                  <i class="far fa-play"></i>
                  <span class="text">{{
                    "APP.AFFILIATE_PRODUCTS_PAGE_LIST.ACTIONS.CONTINUE"
                      | translate
                  }}</span>
                </span>
              </button>

              <button mat-menu-item (click)="deleteCampaign(campaign)">
                <span class="menu-item-content">
                  <i class="far fa-trash"></i>
                  <span class="text">{{
                    "APP.AFFILIATE_PRODUCTS_PAGE_LIST.ACTIONS.DELETE"
                      | translate
                  }}</span>
                </span>
              </button>
            </mat-menu>
          </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row
          [hidden]="dataSource.loading$ | async"
          *matRowDef="let row; columns: displayedColumns"
          (click)="onRowClick(row, $event)"
        ></mat-row>

        <div
          class="spinner-container d-flex justify-content-center"
          *ngIf="dataSource.loading$ | async"
        >
          <mat-spinner></mat-spinner>
        </div>
      </mat-table>
    </div>

    <app-paginator
      [totalItems]="dataSource.numRecords$ | async"
      [pageSizeOptions]="pageSizeOptions"
      (changePage)="onPageChange($event)"
    >
    </app-paginator>
  </div>

  <div class="add-button-container">
    <button
      (click)="addCampaign()"
      type="button"
      mat-raised-button
      class="actionButton btn-outline-secondary addButton"
    >
      <span class="actions">
        <i class="far fa-plus"></i>
        {{ "APP.CAMPAIGNS.ADD_CAMPAIGN" | translate }}
      </span>
    </button>
  </div>
</div>
