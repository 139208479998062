export enum AffiliateProductType {
  PRESENT = 'PRESENT',
  DECORATION = 'DECORATION',
}

export namespace AffiliateProductType {
  export function getAllAffiliateProductTypes(): AffiliateProductType[] {
    return [AffiliateProductType.PRESENT, AffiliateProductType.DECORATION];
  }

  export function getDisplayName(type?: AffiliateProductType): string {
    switch (type) {
      case AffiliateProductType.PRESENT:
        return 'APP.AFFILIATE_PRODUCT_TYPE.PRESENT';
      case AffiliateProductType.DECORATION:
        return 'APP.AFFILIATE_PRODUCT_TYPE.DECORATION';
      default:
        return '';
    }
  }
}
