import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-affiliate-product-stats',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './affiliate-product-stats.page.html',
  styleUrl: './affiliate-product-stats.page.scss'
})
export class AffiliateProductStatsPage {

}
