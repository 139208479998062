@if (form) {
  <form [formGroup]="form">
    <div class="invitation-card-step-cont">
      <div class="card-data-cont">
        <h2 class="body-header">
          {{ "APP.DESIGN_TEMPLATES.CREATE.INVITATION_TITLE" | translate }}
        </h2>
        <mat-form-field appearance="outline" class="w-100">
          <mat-label>{{ "APP.HEADLINE" | translate }}</mat-label>
          <input
            #invitationCardTitle
            name="invitationCardTitle"
            type="text"
            matInput
            formControlName="invitationCardTitle"
            maxlength="8"
          />
          <span matTextSuffix
            >{{ invitationCardTitle.value.length || "" }} | 8</span
          >
        </mat-form-field>

        <h2 class="body-header">
          {{ "APP.DESIGN_TEMPLATES.CREATE.INVITATION_TEXT" | translate }}
        </h2>
        <mat-form-field appearance="outline" class="w-100">
          <textarea
            #invitationCardText
            matInput
            formControlName="invitationCardText"
            rows="5"
            maxlength="46"
            placeholder="{{
              'APP.DESIGN_TEMPLATES.CREATE.INVITATION_TEXT_PLACEHOLDER'
                | translate
            }}"
          ></textarea>
          <span matTextSuffix
            >{{ invitationCardText.value.length || "" }} | 46</span
          >
        </mat-form-field>

        <div class="invitation-contact-data-cont">
          <h2 class="body-header">
            {{ "APP.DESIGN_TEMPLATES.CREATE.CONTACT_DATA" | translate }}
          </h2>
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>{{ "APP.FULL_NAME" | translate }}</mat-label>
            <input
              type="text"
              matInput
              formControlName="invitationCardContactFullName"
            />
          </mat-form-field>
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>{{ "APP.EMAIL" | translate }}</mat-label>
            <input
              type="text"
              matInput
              formControlName="invitationCardContactEmail"
            />
          </mat-form-field>
          <mat-form-field appearance="outline" class="w-100 tel-input-field">
            <app-tel-input
              formControlName="invitationCardContactPhone"
            ></app-tel-input>
            @if (
              form.controls["invitationCardContactPhone"].hasError("custom")
            ) {
              <mat-error>
                {{
                  form.controls["invitationCardContactPhone"].getError("custom")
                }}
              </mat-error>
            } @else if (
              form.controls["invitationCardContactPhone"].hasError("required")
            ) {
              <mat-error>
                {{ "APP.AUTH.ERRORS.PHONE_REQUIRED" | translate }}
              </mat-error>
            } @else if (
              form.controls["invitationCardContactPhone"].hasError(
                "invalidNumber"
              )
            ) {
              <mat-error>
                {{ "APP.AUTH.ERRORS.PHONE_INVALID" | translate }}
              </mat-error>
            }
          </mat-form-field>
        </div>
      </div>
      <div class="card-preview-cont">
        <app-invitation-card-preview
          [form]="form"
        ></app-invitation-card-preview>
      </div>
    </div>
  </form>
}
