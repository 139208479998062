<div class="overlay-page overlay-theme">
  <div class="header">
    <div class="flex-left-placeholder"></div>
    <h1 mat-dialog-title class="title" *ngIf="!isEdit">
      {{
        "APP.AFFILIATE_PRODUCTS_PAGE_LIST.CREATE_OR_EDIT_PRODUCT.ADD_HEADER_TITLE"
          | translate
      }}
    </h1>
    <h1 mat-dialog-title class="title" *ngIf="isEdit">
      {{
        "APP.AFFILIATE_PRODUCTS_PAGE_LIST.CREATE_OR_EDIT_PRODUCT.EDIT_HEADER_TITLE"
          | translate
      }}
    </h1>
    <i class="fal fa-times-circle" (click)="cancel()"></i>
  </div>

  <div class="body-info">
    <form [formGroup]="form">
      <div>
        <h1 class="body-titles">
          {{
            "APP.AFFILIATE_PRODUCTS_PAGE_LIST.CREATE_OR_EDIT_PRODUCT.BASIC_INFORMATION.TITLE"
              | translate
          }}*
        </h1>

        <div class="flex-inputs-cont basic-info">
          <mat-form-field
            class="flex-half-shrink flex-input"
            appearance="outline"
          >
            <mat-label class="placeholder">{{
              "APP.AFFILIATE_PRODUCTS_PAGE_LIST.CREATE_OR_EDIT_PRODUCT.BASIC_INFORMATION.NAME"
                | translate
            }}</mat-label>
            <input type="text" matInput formControlName="name" placeholder="" />
            @if (form.controls["name"].hasError("required")) {
              <mat-error>
                {{ "APP.AUTH.ERRORS.PRODUCT_NAME_REQUIRED" | translate }}
              </mat-error>
            }
          </mat-form-field>

          <mat-form-field
            class="flex-half-shrink flex-input"
            appearance="outline"
          >
            <mat-label class="placeholder">{{
              "APP.AFFILIATE_PRODUCTS_PAGE_LIST.CREATE_OR_EDIT_PRODUCT.BASIC_INFORMATION.PARTNER"
                | translate
            }}</mat-label>
            <mat-select formControlName="partner">
              <mat-option *ngFor="let option of partners" [value]="option">
                {{ getDisplayPartnerName(option) | translate }}
              </mat-option>
            </mat-select>
            @if (form.controls["partner"].hasError("required")) {
              <mat-error>
                {{ "APP.AUTH.ERRORS.PRODUCT_PARTNER_REQUIRED" | translate }}
              </mat-error>
            }
          </mat-form-field>
        </div>
        <mat-form-field class="w-100 link-input" appearance="outline">
          <mat-label class="placeholder">{{
            "APP.AFFILIATE_PRODUCTS_PAGE_LIST.CREATE_OR_EDIT_PRODUCT.LINK.PLACEHOLDER"
              | translate
          }}</mat-label>
          <input type="text" matInput formControlName="link" placeholder="" />
          @if (form.controls["link"].hasError("required")) {
            <mat-error>
              {{ "APP.AUTH.ERRORS.PRODUCT_LINK_REQUIRED" | translate }}
            </mat-error>
          }
        </mat-form-field>
      </div>

      <h1 class="body-titles">
        {{
          "APP.AFFILIATE_PRODUCTS_PAGE_LIST.CREATE_OR_EDIT_PRODUCT.CHOOSE_CATEGORY"
            | translate
        }}*
      </h1>
      <app-category-select
        [form]="form"
        selectedFormControlName="categories"
        [eventCategories]="categories"
        requiredError="APP.AUTH.ERRORS.PRODUCT_CATEGORY_REQUIRED"
      ></app-category-select>

      <h1 class="body-titles">
        {{
          "APP.AFFILIATE_PRODUCTS_PAGE_LIST.CREATE_OR_EDIT_PRODUCT.CHOOSE_SUBCATEGORY"
            | translate
        }}*
      </h1>
      <app-category-select
        [form]="form"
        selectedFormControlName="subcategories"
        [eventCategories]="subcategories"
        requiredError="APP.AUTH.ERRORS.PRODUCT_SUBCATEGORY_REQUIRED"
      ></app-category-select>

      <h1 class="body-titles">
        {{
          "APP.AFFILIATE_PRODUCTS_PAGE_LIST.CREATE_OR_EDIT_PRODUCT.CHOOSE_TYPE"
            | translate
        }}*
      </h1>
      <app-affiliate-product-type-select
        [form]="form"
        selectedFormControlName="types"
        [items]="affiliateProductTypes"
      >
      </app-affiliate-product-type-select>

      <div>
        <h1 class="body-titles">
          {{
            "APP.AFFILIATE_PRODUCTS_PAGE_LIST.CREATE_OR_EDIT_PRODUCT.DESCRIPTION.TITLE"
              | translate
          }}
        </h1>

        <div class="description-info">
          <mat-form-field class="w-100 description-input" appearance="outline">
            <mat-label class="placeholder">{{
              "APP.AFFILIATE_PRODUCTS_PAGE_LIST.CREATE_OR_EDIT_PRODUCT.DESCRIPTION.PLACEHOLDER"
                | translate
            }}</mat-label>
            <textarea
              matInput
              formControlName="description"
              placeholder=""
              class="textarea-like-input"
            ></textarea>
          </mat-form-field>
        </div>
      </div>

      <div>
        <h1 class="body-titles">
          {{
            "APP.AFFILIATE_PRODUCTS_PAGE_LIST.CREATE_OR_EDIT_PRODUCT.UPLOAD_PICTURE_OR_VIDEO.TITLE"
              | translate
          }}*
        </h1>

        <div class="file-container">
          <app-file-uploader
            [aspectRatio]="1 / 1"
            [fluidWidth]="true"
            [resizeToWidth]="500"
            [uploaderType]="'IMAGE_AND_VIDEO'"
            [selectedFile]="form.controls['file'].value"
            [invalid]="
              form.controls['file'].touched && form.controls['file'].invalid
            "
            (fileChanged)="onImageChanged($event)"
          >
          </app-file-uploader>
        </div>
        @if (
          form.controls["file"].touched &&
          form.controls["file"].hasError("required")
        ) {
          <mat-error>
            {{ "APP.AUTH.ERRORS.PRODUCT_FILE_REQUIRED" | translate }}
          </mat-error>
        }
      </div>
    </form>
  </div>

  <footer>
    <button
      *ngIf="!isEdit"
      (click)="createOrUpdateProduct()"
      type="button"
      mat-raised-button
      class="actionButton btn-outline-secondary addButton"
    >
      {{
        "APP.AFFILIATE_PRODUCTS_PAGE_LIST.CREATE_OR_EDIT_PRODUCT.CREATE"
          | translate
      }}
    </button>

    <button
      *ngIf="isEdit"
      (click)="createOrUpdateProduct()"
      type="button"
      mat-raised-button
      class="actionButton btn-outline-secondary editButton"
    >
      {{
        "APP.AFFILIATE_PRODUCTS_PAGE_LIST.CREATE_OR_EDIT_PRODUCT.EDIT"
          | translate
      }}
    </button>
  </footer>
</div>
