import { Component, Input, Output, EventEmitter } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EventTemplateAsset } from '@models/events/event-template-asset.model';
import { MatDialog } from '@angular/material/dialog';
import { TemplateAssetPickerDialog } from '@modules/shared/dialogs/template-asset-picker/template-asset-picker.dialog';
import { TranslateModule } from '@ngx-translate/core';
import { EventCategory } from '@models/event-categories/event-category.model';
import { EventTemplateAssetType } from '@models/events/event-template-asset-type.enum';
import { TemplateAssetOverlayPageComponent } from '@modules/admin/template-assets/dialogs/template-asset-overlay-page/template-asset-overlay-page.component';
import { ImagePreviewComponent } from '../image-preview/image-preview.component';
import { Asset, getAssetFromURL } from '@models/shared/asset.model';
import { FormsModules } from '@utils/shared-modules';

@Component({
  selector: 'app-template-asset-select',
  standalone: true,
  imports: [CommonModule, FormsModules, TranslateModule, ImagePreviewComponent],
  templateUrl: './template-asset-select.component.html',
  styleUrl: './template-asset-select.component.scss',
})
export class TemplateAssetSelectComponent {
  @Input() type?: EventTemplateAssetType;
  @Input() mainCategories?: EventCategory[];
  @Input() selectedAsset?: EventTemplateAsset;
  @Input() invalid = false;

  @Output() assetChanged: EventEmitter<EventTemplateAsset> = new EventEmitter();

  constructor(private dialog: MatDialog) {}

  onEditAsset() {
    const dialogRef = this.dialog.open(TemplateAssetPickerDialog, {
      maxWidth: '513px',
      width: '100%',
      height: 'auto',
      maxHeight: '100vh',
      autoFocus: false,
      data: {
        type: this.type,
        selectedAsset: this.selectedAsset,
        mainCategories: this.mainCategories,
      },
      panelClass: ['normal-dialog', 'overlay-theme'],
    });

    dialogRef.afterClosed().subscribe((res) => {
      if (res?.asset) {
        this.selectedAsset = res.asset;
        this.assetChanged.emit(this.selectedAsset);
      }
    });
  }

  onDeleteAsset() {
    this.selectedAsset = undefined;
    this.assetChanged.emit(this.selectedAsset);
  }

  addAsset(e: Event) {
    e.stopPropagation();
    const dialogRef = this.dialog.open(TemplateAssetOverlayPageComponent, {
      width: '100%',
      height: '100%',
      maxWidth: '100%',
      panelClass: 'overlay-page-dialog',
      autoFocus: false,
    });

    dialogRef.afterClosed().subscribe((res) => {
      if (res?.templateAsset) {
        this.selectedAsset = res.templateAsset;
        this.assetChanged.emit(this.selectedAsset);
      }
    });
  }

  get mainAsset(): Asset | undefined {
    if (!this.selectedAsset) {
      return undefined;
    }
    return getAssetFromURL(this.selectedAsset.url);
  }
}
