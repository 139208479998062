<mat-form-field
  appearance="outline"
  class="half-field"
  [ngClass]="{
    'ng-invalid mdc-text-field--invalid':
      (inputControl.touched || selectedFormControl.touched) &&
      selectedFormControl.invalid
  }"
>
  <input
    type="text"
    placeholder="{{ 'APP.TYPE_IN_OR_SELECT' | translate }}"
    matInput
    [matAutocomplete]="auto"
    [formControl]="inputControl"
  />
  <i matSuffix class="fas fa-caret-down autocomplete-dropdown-icon"></i>
</mat-form-field>

<mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
  <mat-option
    *ngFor="let affiliateProduct of filteredAffiliateProducts | async"
    [value]="affiliateProduct"
    (click)="optionClicked($event, affiliateProduct)"
    [disabled]="selectedAffiliateProducts.length == limit"
  >
    <div>
      <mat-checkbox
        [checked]="isSelected(affiliateProduct.id)"
        (change)="toggleSelection(affiliateProduct)"
        (click)="$event.stopPropagation()"
      >
<!--        [disabled]="selectedAffiliateProducts.length >= limit && !isSelected(affiliateProduct.id)"-->
<!--      >-->
        {{ affiliateProduct.name }}
      </mat-checkbox>
    </div>
  </mat-option>
</mat-autocomplete>

<div
  cdkDropList
  cdkDropListOrientation="horizontal"
  class="affiliate-products-list"
  (cdkDropListDropped)="drop($event)"
  [class.display-none]="!selectedAffiliateProducts.length"
>
  @for (
    affiliateProduct of selectedAffiliateProducts;
    track affiliateProduct.id
  ) {
    <app-affiliate-product-card
      cdkDrag
      [cdkDragData]="affiliateProduct"
      [affiliateProduct]="affiliateProduct"
      [editView]="true"
      (remove)="remove(affiliateProduct.id)"
    >
      <div cdkDragHandle class="drag-handle">
        <i class="far fa-grip-vertical"></i>
      </div>
    </app-affiliate-product-card>
  }
</div>
