<div class="list-page-container">
  <div>
    <h1 class="page-header">
      {{ "APP.AFFILIATE_PRODUCTS_PAGE_LIST.PRODUCTS_HEADER" | translate }}
    </h1>

    <hr class="hr" />
  </div>

  <div>
    <div>
      <app-filter
        [isAffiliateProductsListPage]="true"
        (filterChange)="onFilterChange($event)"
      >
      </app-filter>
    </div>

    <div class="table-scrollable" [class.loading]="dataSource.loading$ | async">
      <mat-table matSort [dataSource]="dataSource" class="mat-elevation-z8">
        <!-- Add this checkbox column definition inside the table -->
        <ng-container matColumnDef="select">
          <mat-header-cell *matHeaderCellDef>
            <mat-checkbox (change)="masterToggle()"></mat-checkbox>
          </mat-header-cell>
          <mat-cell *matCellDef="let row">
            <mat-checkbox (change)="toggleSelection(row)"></mat-checkbox>
          </mat-cell>
        </ng-container>

        <!-- Name Column -->
        <ng-container matColumnDef="name">
          <mat-header-cell mat-sort-header *matHeaderCellDef>{{
            "APP.AFFILIATE_PRODUCTS_PAGE_LIST.TABLE_HEADERS.NAME" | translate
          }}</mat-header-cell>
          <mat-cell *matCellDef="let product">
            <div class="name-and-picture-col">
              <img
                *ngIf="product?.pictureUrl"
                [src]="product.pictureUrl"
                alt="Product Image"
              />
              <div class="ellipsis-field">{{ product?.name }}</div>
            </div>
          </mat-cell>
        </ng-container>

        <!-- ID Column -->
        <ng-container matColumnDef="id">
          <mat-header-cell mat-sort-header *matHeaderCellDef>{{
            "APP.AFFILIATE_PRODUCTS_PAGE_LIST.TABLE_HEADERS.ID" | translate
          }}</mat-header-cell>
          <mat-cell *matCellDef="let product">{{ product?.id }}</mat-cell>
        </ng-container>

        <!-- Creation Date Column -->
        <ng-container matColumnDef="creationDate">
          <mat-header-cell mat-sort-header *matHeaderCellDef>{{
            "APP.AFFILIATE_PRODUCTS_PAGE_LIST.TABLE_HEADERS.CREATION_DATE"
              | translate
          }}</mat-header-cell>
          <mat-cell *matCellDef="let product">{{
            product?.createdAt.getTime() > 0
              ? (product?.createdAt | date: "dd.MM.yyy")
              : "n/a"
          }}</mat-cell>
        </ng-container>

        <!-- Partner Column -->
        <ng-container matColumnDef="partner">
          <mat-header-cell mat-sort-header *matHeaderCellDef>{{
            "APP.AFFILIATE_PRODUCTS_PAGE_LIST.TABLE_HEADERS.PARTNER" | translate
          }}</mat-header-cell>
          <mat-cell *matCellDef="let product">{{
            getDisplayPartnerName(product?.network) | translate
          }}</mat-cell>
        </ng-container>

        <!-- Affiliate Link Column -->
        <ng-container matColumnDef="link">
          <mat-header-cell mat-sort-header *matHeaderCellDef>{{
            "APP.AFFILIATE_PRODUCTS_PAGE_LIST.TABLE_HEADERS.AFFILIATE_LINK"
              | translate
          }}</mat-header-cell>
          <mat-cell *matCellDef="let product"
            ><div class="ellipsis-field">{{ product?.link }}</div></mat-cell
          >
        </ng-container>

        <!-- Category Column -->
        <ng-container matColumnDef="category">
          <mat-header-cell mat-sort-header *matHeaderCellDef>{{
            "APP.AFFILIATE_PRODUCTS_PAGE_LIST.TABLE_HEADERS.CATEGORY"
              | translate
          }}</mat-header-cell>
          <mat-cell *matCellDef="let product">{{
            product?.getEventCategoryNames()
          }}</mat-cell>
        </ng-container>

        <!-- Subcategory Column -->
        <ng-container matColumnDef="subcategory">
          <mat-header-cell mat-sort-header *matHeaderCellDef>{{
            "APP.AFFILIATE_PRODUCTS_PAGE_LIST.TABLE_HEADERS.SUBCATEGORY"
              | translate
          }}</mat-header-cell>
          <mat-cell *matCellDef="let product">{{
            product?.getEventSubcategoryNames()
          }}</mat-cell>
        </ng-container>

        <!-- Views Column -->
        <ng-container matColumnDef="views">
          <mat-header-cell mat-sort-header *matHeaderCellDef>{{
            "APP.AFFILIATE_PRODUCTS_PAGE_LIST.TABLE_HEADERS.VIEWS" | translate
          }}</mat-header-cell>
          <mat-cell *matCellDef="let product">{{
            product?.affiliateProductStats?.totalAffiliateViews
          }}</mat-cell>
        </ng-container>

        <!-- Clicks Column -->
        <ng-container matColumnDef="clicks">
          <mat-header-cell mat-sort-header *matHeaderCellDef>{{
            "APP.AFFILIATE_PRODUCTS_PAGE_LIST.TABLE_HEADERS.CLICKS" | translate
          }}</mat-header-cell>
          <mat-cell *matCellDef="let product">{{
            product?.affiliateProductStats?.totalAffiliateClicks
          }}</mat-cell>
        </ng-container>

        <!-- CTR Column -->
        <ng-container matColumnDef="ctr">
          <mat-header-cell mat-sort-header *matHeaderCellDef>{{
            "APP.AFFILIATE_PRODUCTS_PAGE_LIST.TABLE_HEADERS.CTR" | translate
          }}</mat-header-cell>
          <mat-cell *matCellDef="let product"
            >{{ product?.affiliateProductStats?.totalAffiliateCtr }}%</mat-cell
          >
        </ng-container>

        <!-- Actions Column -->
        <ng-container matColumnDef="actions" stickyEnd>
          <mat-header-cell *matHeaderCellDef>{{
            "APP.AFFILIATE_PRODUCTS_PAGE_LIST.TABLE_HEADERS.ACTIONS" | translate
          }}</mat-header-cell>
          <mat-cell *matCellDef="let product">
            <button
              mat-icon-button
              [matMenuTriggerFor]="menu"
              class="more-vert-rotate-90"
              (click)="$event.stopPropagation()"
            >
              <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #menu="matMenu" class="table-actions-container">
              <!--          <button mat-menu-item (click)="editProduct(product)">Edit</button>-->
              <button mat-menu-item (click)="copyProduct(product)">
                <span class="menu-item-content">
                  <i class="far fa-copy"></i>
                  <span class="text">{{
                    "APP.AFFILIATE_PRODUCTS_PAGE_LIST.ACTIONS.COPY_PRODUCT"
                      | translate
                  }}</span>
                </span>
              </button>

              <button mat-menu-item (click)="copyProductLink(product.link)">
                <span class="menu-item-content">
                  <i class="far fa-link"></i>
                  <span class="text">{{
                    "APP.AFFILIATE_PRODUCTS_PAGE_LIST.ACTIONS.COPY_LINK"
                      | translate
                  }}</span>
                </span>
              </button>

              <button
                mat-menu-item
                (click)="pauseProduct(product)"
                *ngIf="product?.status === 'ACTIVE'"
              >
                <span class="menu-item-content">
                  <i class="far fa-pause"></i>
                  <span class="text">{{
                    "APP.AFFILIATE_PRODUCTS_PAGE_LIST.ACTIONS.PAUSE" | translate
                  }}</span>
                </span>
              </button>

              <button
                mat-menu-item
                (click)="continueProduct(product)"
                *ngIf="product?.status === 'INACTIVE'"
              >
                <span class="menu-item-content">
                  <i class="far fa-play"></i>
                  <span class="text">{{
                    "APP.AFFILIATE_PRODUCTS_PAGE_LIST.ACTIONS.CONTINUE"
                      | translate
                  }}</span>
                </span>
              </button>

              <button
                mat-menu-item
                (click)="archiveProduct(product)"
                *ngIf="product?.status !== 'ARCHIVED'"
              >
                <span class="menu-item-content">
                  <i class="far fa-archive"></i>
                  <span class="text">{{
                    "APP.AFFILIATE_PRODUCTS_PAGE_LIST.ACTIONS.ARCHIVE"
                      | translate
                  }}</span>
                </span>
              </button>

              <button
                mat-menu-item
                (click)="restoreProduct(product)"
                *ngIf="product?.status === 'ARCHIVED'"
              >
                <span class="menu-item-content">
                  <i class="far fa-box-open"></i>
                  <span class="text">{{
                    "APP.AFFILIATE_PRODUCTS_PAGE_LIST.ACTIONS.RESTORE"
                      | translate
                  }}</span>
                </span>
              </button>

              <button mat-menu-item (click)="deleteProduct(product)">
                <span class="menu-item-content">
                  <i class="far fa-trash"></i>
                  <span class="text">{{
                    "APP.AFFILIATE_PRODUCTS_PAGE_LIST.ACTIONS.DELETE"
                      | translate
                  }}</span>
                </span>
              </button>
            </mat-menu>
          </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row
          [hidden]="dataSource.loading$ | async"
          *matRowDef="let row; columns: displayedColumns"
          (click)="onRowClick(row, $event)"
        ></mat-row>

        <div
          class="spinner-container d-flex justify-content-center"
          *ngIf="dataSource.loading$ | async"
        >
          <mat-spinner></mat-spinner>
        </div>
      </mat-table>
    </div>

    <app-paginator
      [totalItems]="dataSource.numRecords$ | async"
      [pageSizeOptions]="pageSizeOptions"
      (changePage)="onPageChange($event)"
    >
    </app-paginator>
  </div>

  <div class="add-button-container">
    <button
      (click)="addProduct()"
      type="button"
      mat-raised-button
      class="actionButton btn-outline-secondary addButton"
    >
      <span class="actions">
        <i class="far fa-plus"></i>
        {{
          "APP.AFFILIATE_PRODUCTS_PAGE_LIST.ACTION_BUTTONS.ADD_PRODUCT"
            | translate
        }}
      </span>
    </button>
  </div>
</div>
