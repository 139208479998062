@if (form) {
  <form [formGroup]="form">
    <h2 class="body-header">
      {{ "APP.DESIGN_TEMPLATES.CREATE.ASSIGN_PARTNER" | translate }}
    </h2>
    <app-autocomplete-select
      [form]="form"
      selectedFormControlName="partner"
      [items]="partners"
      labelProperty="name"
      valueProperty="id"
    >
    </app-autocomplete-select>

    <div class="body-header-cont">
      <h2 class="body-header">
        {{
          "APP.DESIGN_TEMPLATES.CREATE.ASSIGN_AFTER_CREATION_AFFILIATE_PRODUCTS"
            | translate
        }}
      </h2>
      <div class="body-header-info">
        {{
          "APP.DESIGN_TEMPLATES.CREATE.AFFILIATE_AFTER_CREATION_INFO"
            | translate
        }}
      </div>
    </div>
    <app-affiliate-products-select
      [form]="form"
      selectedFormControlName="afterCreationAffiliateProducts"
      [affiliateProducts]="affiliateProducts"
    ></app-affiliate-products-select>

    <div class="body-header-cont">
      <h2 class="body-header">
        {{
          "APP.DESIGN_TEMPLATES.CREATE.ASSIGN_AFTER_ATTENDING_GIFT_AFFILIATE_PRODUCTS"
            | translate
        }}
      </h2>
      <div class="body-header-info">
        {{
          "APP.DESIGN_TEMPLATES.CREATE.AFFILIATE_AFTER_ATTENDING_GIFT_INFO"
            | translate
        }}
      </div>
    </div>
    <app-affiliate-products-select
      [form]="form"
      selectedFormControlName="afterAttendGiftAffiliateProducts"
      [affiliateProducts]="affiliateProducts"
    ></app-affiliate-products-select>

    <div class="body-header-cont">
      <h2 class="body-header">
        {{
          "APP.DESIGN_TEMPLATES.CREATE.ASSIGN_AFTER_ATTENDING_AFFILIATE_PRODUCTS"
            | translate
        }}
      </h2>
      <div class="body-header-info">
        {{
          "APP.DESIGN_TEMPLATES.CREATE.AFFILIATE_AFTER_ATTENDING_INFO"
            | translate
        }}
      </div>
    </div>
    <app-affiliate-products-select
      [form]="form"
      selectedFormControlName="afterAttendAffiliateProducts"
      [affiliateProducts]="affiliateProducts"
    ></app-affiliate-products-select>

    <div class="body-header-cont">
      <h2 class="body-header">
        {{
          "APP.DESIGN_TEMPLATES.CREATE.ASSIGN_AFTER_ATTENDING_SHOPPING_LIST_AFFILIATE_PRODUCTS"
            | translate
        }}
      </h2>
      <div class="body-header-info">
        {{
          "APP.DESIGN_TEMPLATES.CREATE.AFFILIATE_AFTER_ATTENDING_SHOPPING_LIST_INFO"
            | translate
        }}
      </div>
    </div>
    <app-affiliate-products-select
      [limit]="1"
      [form]="form"
      selectedFormControlName="afterAttendShoppingListAffiliateProducts"
      [affiliateProducts]="affiliateProducts"
    ></app-affiliate-products-select>
  </form>
}
