import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModules } from '@utils/shared-modules';
import { TranslateModule } from '@ngx-translate/core';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { AbstractControl, FormControl, FormGroup } from '@angular/forms';
import { AffiliateProduct } from '@models/affiliate-products/affiliate-product.model';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { AffiliateProductCardComponent } from '@modules/admin/affiliates-shared/components/affiliate-product-card/affiliate-product-card.component';
import {
  CdkDragDrop,
  CdkDrag,
  CdkDropList,
  moveItemInArray,
  CdkDragHandle,
} from '@angular/cdk/drag-drop';

@Component({
  selector: 'app-affiliate-products-select',
  standalone: true,
  imports: [
    CommonModule,
    FormsModules,
    TranslateModule,
    MatCheckboxModule,
    CdkDropList,
    CdkDrag,
    CdkDragHandle,
    AffiliateProductCardComponent,
  ],
  templateUrl: './affiliate-products-select.component.html',
  styleUrl: './affiliate-products-select.component.scss',
})
export class AffiliateProductsSelectComponent {
  private _affiliateProducts: AffiliateProduct[] = [];
  private valueChangesSet = false;

  @Input() limit: number = 5;
  @Input() form!: FormGroup;
  @Input() selectedFormControlName: string = '';
  @Input() set affiliateProducts(affiliateProducts: AffiliateProduct[]) {
    if (affiliateProducts.length > 0) {
      this._affiliateProducts = affiliateProducts;
      if (!this.valueChangesSet) {
        this.filteredAffiliateProducts = this.inputControl.valueChanges.pipe(
          startWith<string | null>(''),
          map((value) => (typeof value === 'string' ? value : this.lastFilter)),
          map((filter) => this.filter(filter)),
        );
      }
    }
  }

  get affiliateProducts(): AffiliateProduct[] {
    return this._affiliateProducts;
  }

  inputControl = new FormControl<string>('');

  selectedAffiliateProducts: AffiliateProduct[] = new Array<AffiliateProduct>();

  filteredAffiliateProducts: Observable<AffiliateProduct[]> = new Observable();
  lastFilter: string = '';

  filter(filter: string): AffiliateProduct[] {
    this.lastFilter = filter;
    if (filter) {
      return this.affiliateProducts.filter((option) => {
        return option.name.toLowerCase().indexOf(filter.toLowerCase()) >= 0;
      });
    } else {
      this.selectedAffiliateProducts =
        this.form.controls[this.selectedFormControlName].value;
      return this.affiliateProducts.slice();
    }
  }

  optionClicked(event: Event, affiliateProduct: AffiliateProduct) {
    event.stopPropagation();
    this.toggleSelection(affiliateProduct);
  }

  toggleSelection(affiliateProduct: AffiliateProduct) {
    if (!this.isSelected(affiliateProduct.id)) {
      this.selectedAffiliateProducts.push(affiliateProduct);

      this.inputControl.setValue('');
      this.form.controls[this.selectedFormControlName].setValue(
        this.selectedAffiliateProducts,
      );
    } else {
      this.remove(affiliateProduct.id, true);
    }
  }

  remove(affiliateProductId: number, updateFormValue = true): void {
    const index = this.selectedAffiliateProducts.findIndex(
      (product) => product.id === affiliateProductId,
    );

    if (index >= 0) {
      this.selectedAffiliateProducts.splice(index, 1);

      if (updateFormValue) {
        this.form.controls[this.selectedFormControlName].setValue(
          this.selectedAffiliateProducts,
        );
      }
    }
  }

  isSelected(affiliateProductId: number): boolean {
    return !!this.selectedAffiliateProducts.find(
      (product) => product.id === affiliateProductId,
    );
  }

  displayFn(_: string): string {
    return '';
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(
      this.selectedAffiliateProducts,
      event.previousIndex,
      event.currentIndex,
    );
    this.form.controls[this.selectedFormControlName].setValue(
      this.selectedAffiliateProducts,
    );
  }

  get selectedFormControl(): AbstractControl {
    return this.form.controls[this.selectedFormControlName];
  }
}
