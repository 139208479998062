import { Component, SimpleChanges, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { MatDialog } from '@angular/material/dialog';
import { FilterComponent } from '@modules/shared/components/filter/filter.component';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSort, MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { PaginatorComponent } from '@modules/shared/components/paginator/paginator.component';
import { SelectionModel } from '@angular/cdk/collections';
import { PageEvent } from '@angular/material/paginator';
import { CampaignDataSource } from '@ds/campaign.ds';
import { CampaignService } from '@services/campaign.service';
import { Campaign } from '@models/campaigns/campaign.model';
import { AppRoutes } from 'app/routes';
import { CampaignOverlayPageComponent } from '../../dialogs/campaign-overlay-page/campaign-overlay-page.component';
import { Router } from '@angular/router';
import { CampaignStatus } from '@models/campaigns/campaign-status.enum';
import { CustomDialogData } from '@models/overlay-dialogs/custom-dialog-data.model';
import { CustomDialogComponent } from '@modules/shared/dialogs/custom-dialog/custom-dialog.component';
import { Helpers } from '@utils/helpers';

@Component({
  selector: 'app-campaign-list',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    FilterComponent,
    MatButtonModule,
    MatCheckboxModule,
    MatIconModule,
    MatMenuModule,
    MatProgressSpinnerModule,
    MatSortModule,
    MatTableModule,
    PaginatorComponent,
  ],
  providers: [CampaignService],
  templateUrl: './campaign-list.page.html',
  styleUrl: './campaign-list.page.scss',
})
export class CampaignListPage {
  @ViewChild(FilterComponent) filter!: FilterComponent;
  @ViewChild(PaginatorComponent) paginator!: PaginatorComponent;
  @ViewChild(MatSort, { static: true }) sort!: MatSort;

  dataSource: CampaignDataSource;

  displayedColumns: string[] = [
    'select',
    'name',
    'id',
    'creationDate',
    'uri',
    'actions',
  ];

  selection = new SelectionModel<any>(true, []);

  pageSizeOptions: number[] = [5, 10, 15, 20, 50, 100]; // Options for the user to change the page size

  constructor(
    private dialog: MatDialog,
    private campaignService: CampaignService,
    private router: Router,
  ) {
    this.dataSource = new CampaignDataSource(this.campaignService);
  }

  ngOnInit() {
    // Set the initial page
  }

  ngOnChanges(changes: SimpleChanges): void {
    // Check if the 'currentPage' or 'products' have changed
    this.refresh();
  }

  ngAfterViewInit() {
    // reset the paginator after sorting
    this.sort.sortChange.subscribe(() => {
      this.resetPageIndex();
      this.refresh();
    });

    this.refresh();
  }

  onFilterChange(event: any) {
    this.resetPageIndex();
    this.refresh();
  }

  onPageChange(event: PageEvent) {
    this.refresh();
  }

  private resetPageIndex() {
    this.paginator.pager.currentPage = 1;
  }

  refresh() {
    const filters = new Map<string, any>();

    if (this.filter?.searchText) {
      filters.set('searchTerm', this.filter.searchText);
    }

    if (!!this.sort.direction) {
      filters.set('sortDirection', this.sort.direction);
      filters.set('sortProperty', this.sort.active);
    }

    this.dataSource.loadCampaigns(
      filters,
      this.paginator.pager.currentPage !== 0
        ? this.paginator.pager.currentPage
        : 1,
      this.paginator.pageSize || 10,
    );
  }

  masterToggle() {
    this.isAllSelected()
      ? this.selection.clear()
      : this.dataSource?.data?.forEach((row) => this.selection.select(row));
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource?.data?.length;
    return numSelected === numRows;
  }

  toggleSelection(row: any) {
    this.selection.toggle(row);
  }

  onEdit(campaign: Campaign) {
    if (campaign) {
      const dialogRef = this.dialog.open(CampaignOverlayPageComponent, {
        width: '100%',
        height: '100%',
        maxWidth: '100%',
        panelClass: 'overlay-page-dialog',
        autoFocus: false,
        data: {
          campaign: campaign,
          isEdit: true,
        },
      });
      dialogRef.afterClosed().subscribe((result) => {
        if (result?.refresh) {
          this.refresh();
        }
      });
    }
  }

  onRowClick(campaign: Campaign, event: MouseEvent) {
    if (campaign) {
      const selectedText = window.getSelection()?.toString();

      if (
        !selectedText ||
        !Helpers.isSelectionInsideRow(event.currentTarget as HTMLElement)
      ) {
        this.router.navigate([
          '/',
          AppRoutes.Admin.root,
          AppRoutes.Admin.Campaigns.root,
          campaign.uri,
        ]);
      }
    }
  }

  addCampaign() {
    const dialogRef = this.dialog.open(CampaignOverlayPageComponent, {
      width: '100%',
      height: '100%',
      maxWidth: '100%',
      panelClass: 'overlay-page-dialog',
      data: {},
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.filter?.onCreateNewProduct(true);
        this.resetPageIndex();
        this.refresh();
      }
    });
  }

  setStatus(campaignId: number, status: CampaignStatus) {
    this.campaignService.setStatus(campaignId, status).subscribe(() => {
      this.resetPageIndex();
      this.refresh();
    });
  }

  deleteCampaign(campaign: Campaign) {
    const question = 'APP.CAMPAIGNS.DELETE.MESSAGE';
    const cancelButtonMessage = 'APP.CAMPAIGNS.DELETE.CANCEL';
    const deleteButtonMessage = 'APP.CAMPAIGNS.DELETE.CONFIRM';

    const data: CustomDialogData = {
      isDeleteDialog: true,
      question: question,
      id: campaign.id,
      title: campaign.name.toUpperCase(),
      pictureUrl: campaign.logoUrl,
      cancelButtonMessage: cancelButtonMessage,
      deleteButtonMessage: deleteButtonMessage,
    };

    const dialogRef = this.dialog.open(CustomDialogComponent, {
      maxWidth: '456px',
      width: '100%',
      height: 'auto',
      data: data,
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result === true) {
        this.campaignService.delete(campaign.id).subscribe(() => {
          this.resetPageIndex();
          this.refresh();
        });
      }
    });
  }

  protected readonly CampaignStatus = CampaignStatus;
}
