import { Component, Inject } from '@angular/core';
import { EventTemplate } from '@models/design-templates/event-template.model';
import {
  MAT_DIALOG_DATA,
  MatDialogActions,
  MatDialogRef,
  MatDialogTitle,
} from '@angular/material/dialog';
import { AffiliateProductCardComponent } from '@modules/admin/affiliates-shared/components/affiliate-product-card/affiliate-product-card.component';
import { FooterComponent } from '../../../../components/footer/footer.component';
import { MostVotesComponent } from '@modules/events/dialogs/event-after-creation-overlay-dialog/most-votes/most-votes.component';
import { ShareButtonsComponent } from '@modules/shared/components/share-buttons/share-buttons.component';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatOption } from '@angular/material/autocomplete';
import { MatSelect } from '@angular/material/select';
import { NgForOf, NgIf } from '@angular/common';
import { MatButton } from '@angular/material/button';
import { MatMenuItem } from '@angular/material/menu';
import { QRCodeModule } from 'angularx-qrcode';
import { SafeUrl } from '@angular/platform-browser';
import { Clipboard } from '@angular/cdk/clipboard';

@Component({
  selector: 'app-copy-template-public-url-or-create-qr-code-choose-language',
  standalone: true,
  imports: [
    AffiliateProductCardComponent,
    FooterComponent,
    MostVotesComponent,
    ShareButtonsComponent,
    TranslateModule,
    FormsModule,
    MatFormField,
    MatLabel,
    MatOption,
    MatSelect,
    NgForOf,
    ReactiveFormsModule,
    MatDialogTitle,
    MatButton,
    MatDialogActions,
    NgIf,
    MatMenuItem,
    QRCodeModule,
  ],
  templateUrl:
    './copy-template-public-url-or-create-qr-code-choose-language.dialog.html',
  styleUrl:
    './copy-template-public-url-or-create-qr-code-choose-language.dialog.scss',
})
export class CopyTemplatePublicUrlOrCreateQrCodeChooseLanguageDialog {
  eventTemplate!: EventTemplate;
  isCopyTemplatePublicUrl: boolean = false;
  isCreateQrCode: boolean = false;

  templatesQRCodes = new Map<number, SafeUrl>();

  selectedLanguage = 'de';
  form!: FormGroup;

  constructor(
    public dialogRef: MatDialogRef<CopyTemplatePublicUrlOrCreateQrCodeChooseLanguageDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private translateService: TranslateService,
    private clipboard: Clipboard,
    private fb: FormBuilder,
  ) {
    if (data) {
      this.eventTemplate = data.eventTemplate;
      this.isCopyTemplatePublicUrl = data.isCopyTemplatePublicUrl;
      this.isCreateQrCode = data.isCreateQrCode;
    }

    this.selectedLanguage = this.eventTemplate.getLanguages()[0];

    this.form = this.fb.group({
      language: [this.selectedLanguage || '', Validators.required],
    });
  }

  close() {
    this.dialogRef.close();
  }

  getTranslationLanguageNames(languageCode: string) {
    if (languageCode === 'de') {
      return this.translateService.instant('APP.LANGUAGES.DE');
    }
    if (languageCode === 'en') {
      return this.translateService.instant('APP.LANGUAGES.EN');
    }
  }

  copyTemplatePublicUrl() {
    const selectedLanguage = this.form.value.language;
    this.dialogRef.close(this.eventTemplate.getLink(selectedLanguage));
  }

  getEventTemplateQRCodeUrl() {
    return this.templatesQRCodes.get(this.eventTemplate.id);
  }

  onChangeQRCodeURL(url: SafeUrl, templateId: number) {
    this.templatesQRCodes.set(templateId, url);
  }

  getTranslatedUri() {
    const selectedLanguage = this.form.value.language;
    if (selectedLanguage === this.eventTemplate.languageCode) {
      return this.eventTemplate.uri;
    }

    const translation = this.eventTemplate.getTranslation();

    if (translation && translation.languageCode === selectedLanguage) {
      return translation.uri;
    }
    return undefined;
  }

  getTranslatedLink() {
    const selectedLanguage = this.form.value.language;
    return this.eventTemplate.getLink(selectedLanguage);
  }
}
