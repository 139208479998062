import { Component, Inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
  MatDialogTitle,
} from '@angular/material/dialog';
import { TranslateModule } from '@ngx-translate/core';
import { AffiliateProductsService } from '@services/affiliate-products.service';
import { MatButtonModule } from '@angular/material/button';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { AffiliateProductPartner } from '@models/affiliate-products/affiliate-product-partner.enum';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { MatChipsModule } from '@angular/material/chips';
import { FormsModules } from '@utils/shared-modules';
import { FileUploaderComponent } from '@modules/shared/components/file-uploader/file-uploader.component';
import { Asset, getAssetFromURL } from '@models/shared/asset.model';
import { EventCategory } from '@models/event-categories/event-category.model';
import { AffiliateProductCreationRequest } from '@models/affiliate-products/dto/affiliate-product-creation.request';
import { AffiliateProductStatus } from '@models/affiliate-products/affiliate-product-status.enum';
import { CustomDialogData } from '@models/overlay-dialogs/custom-dialog-data.model';
import { CustomDialogComponent } from '@modules/shared/dialogs/custom-dialog/custom-dialog.component';
import { AffiliateProduct } from '@models/affiliate-products/affiliate-product.model';
import { EventCategoriesService } from '@services/event-categories.service';
import { AuthService } from '@services/auth/auth.service';
import { CategorySelectComponent } from '@modules/shared/components/category-select/category-select.component';
import { AffiliateProductTypeSelectComponent } from '@modules/shared/components/affiliate-product-type-select/affiliate-product-type-select.component';
import { AffiliateProductType } from '@models/affiliate-products/affilaite-product-type.enum';

@Component({
  selector: 'app-overlay-page',
  standalone: true,
  imports: [
    CommonModule,
    MatDialogTitle,
    TranslateModule,
    MatButtonModule,
    MatListModule,
    MatMenuModule,
    MatChipsModule,
    FormsModules,
    FileUploaderComponent,
    CategorySelectComponent,
    AffiliateProductTypeSelectComponent,
  ],
  providers: [AffiliateProductsService, EventCategoriesService],
  templateUrl: './affiliate-product-overlay-page.component.html',
  styleUrl: './affiliate-product-overlay-page.component.scss',
  animations: [
    trigger('partnerMenuAnimation', [
      state('visible', style({ opacity: 1, height: '*' })),
      state('hidden', style({ opacity: 0, height: '0' })),
      transition('visible <=> hidden', animate('150ms ease-in-out')),
    ]),
  ],
})
export class AffiliateProductOverlayPageComponent {
  isEdit: boolean = false;

  partners: AffiliateProductPartner[] = [];
  allCategories: EventCategory[] = [];
  categories: EventCategory[] = [];
  subcategories: EventCategory[] = [];
  affiliateProductTypes = AffiliateProductType.getAllAffiliateProductTypes();

  affiliateProduct?: AffiliateProduct;

  form: FormGroup;

  constructor(
    private affiliateProductService: AffiliateProductsService,
    private eventCategoriesService: EventCategoriesService,
    private authService: AuthService,
    public dialogRef: MatDialogRef<AffiliateProductOverlayPageComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    private dialog: MatDialog,
  ) {
    this.partners = AffiliateProductPartner.getAllAffiliateProductPartners();

    if (data) {
      this.isEdit = data?.isEdit;

      if (data?.allCategories !== undefined) {
        this.allCategories = data?.allCategories;

        this.filterAndSortCategoriesAndSubcategories();
      } else {
        this.eventCategoriesService
          .getEventCategories()
          .subscribe((eventCategories) => {
            this.allCategories = eventCategories;

            this.filterAndSortCategoriesAndSubcategories();
          });
      }

      this.affiliateProduct = data?.affiliateProduct;
    }

    let fileAsset = undefined;
    if (
      this.affiliateProduct?.pictureUrl &&
      this.affiliateProduct.pictureUrl !== ''
    ) {
      fileAsset = getAssetFromURL(this.affiliateProduct?.pictureUrl);
    }

    this.form = this.fb.group({
      name: [this.affiliateProduct?.name || '', Validators.required],
      partner: [this.affiliateProduct?.network || '', Validators.required],
      categories: [
        this.affiliateProduct?.getEventCategoriesIds() || [],
        Validators.required,
      ],
      subcategories: [
        this.affiliateProduct?.getEventSubcategoriesIds() || [],
        Validators.required,
      ],
      link: [this.affiliateProduct?.link || '', Validators.required],
      description: [this.affiliateProduct?.description || ''],
      types: [this.affiliateProduct?.types || [], Validators.required],
      file: new FormControl(fileAsset, [Validators.required]),
    });

    this.form.controls['categories'].valueChanges.subscribe((val) => {
      this.onCategoryChange(val);
    });
  }

  filterAndSortCategoriesAndSubcategories() {
    this.categories = this.allCategories
      ?.filter((item: EventCategory) => !item.parentCategoryId)
      .sort((a, b) => a.name.localeCompare(b.name));
    this.subcategories = this.allCategories
      ?.filter((item: EventCategory) => item.parentCategoryId)
      .sort((a, b) => a.name.localeCompare(b.name));

    this.onCategoryChange(this.affiliateProduct?.getEventCategoriesIds());
  }

  onCategoryChange(val?: number[]) {
    if (val !== undefined && val.length > 0) {
      this.subcategories = this.allCategories
        .filter(
          (item: EventCategory) =>
            item.parentCategoryId && val.includes(item.parentCategoryId),
        )
        .sort((a, b) => a.name.localeCompare(b.name));
      let selectedSubcategories: number[] = this.form.value.subcategories;

      if (selectedSubcategories) {
        selectedSubcategories = selectedSubcategories.filter(
          (selectedCategoryId) =>
            this.subcategories
              .map((category) => category.id)
              .includes(selectedCategoryId),
        );
        this.form.controls['subcategories'].patchValue(selectedSubcategories);
      }
    } else {
      this.subcategories = this.allCategories
        .filter((item: EventCategory) => item.parentCategoryId)
        .sort((a, b) => a.name.localeCompare(b.name));
    }
  }

  getDisplayPartnerName(partner: AffiliateProductPartner) {
    return AffiliateProductPartner.getDisplayName(partner);
  }

  cancel(): void {
    this.dialogRef.close(false);
  }

  createOrUpdateProduct() {
    if (this.isFormValid()) {
      const value = this.form.value;

      const createAffiliateProduct: AffiliateProductCreationRequest = {
        categoryIds: value.categories,
        subcategoryIds: value.subcategories,
        name: value.name,
        description: value.description,
        link: value.link,
        network: value.partner,
        status: AffiliateProductStatus.ACTIVE,
        types: value.types,
        asset: value.file,
      };

      if (this.isEdit) {
        this.affiliateProductService
          .updateProduct(this.affiliateProduct!.id, createAffiliateProduct)
          .subscribe((affiliateProduct) => {
            const confirmation =
              'APP.AFFILIATE_PRODUCTS_PAGE_LIST.CUSTOM_DIALOG.PRODUCT_CONFIRMATION_MESSAGE';
            const okButtonMessage =
              'APP.AFFILIATE_PRODUCTS_PAGE_LIST.ACTION_BUTTONS.OK';
            const title =
              'APP.AFFILIATE_PRODUCTS_PAGE_LIST.CUSTOM_DIALOG.CONFIRMATION_TITLE';

            const data: CustomDialogData = {
              confirmation: confirmation,
              title: title,
              cancelButtonMessage: okButtonMessage,
              id: affiliateProduct.id,
              pictureUrl: affiliateProduct.pictureUrl,
              partner: affiliateProduct.network,
            };

            this.dialog.open(CustomDialogComponent, {
              maxWidth: '455px',
              width: '100%',
              height: 'auto',
              data: data,
            });

            this.dialogRef.close({
              affiliateProduct: affiliateProduct,
              refresh: true,
            });
          });
      } else {
        createAffiliateProduct.ownerId = this.authService.getUser().id;

        this.affiliateProductService
          .create(createAffiliateProduct)
          .subscribe((affiliateProduct) => {
            const confirmation =
              'APP.AFFILIATE_PRODUCTS_PAGE_LIST.CUSTOM_DIALOG.PRODUCT_CONFIRMATION_MESSAGE';
            const okButtonMessage =
              'APP.AFFILIATE_PRODUCTS_PAGE_LIST.ACTION_BUTTONS.OK';
            const title =
              'APP.AFFILIATE_PRODUCTS_PAGE_LIST.CUSTOM_DIALOG.CONFIRMATION_TITLE';

            const data: CustomDialogData = {
              confirmation: confirmation,
              title: title,
              cancelButtonMessage: okButtonMessage,
              id: affiliateProduct.id,
              pictureUrl: affiliateProduct.pictureUrl,
              partner: affiliateProduct.network,
            };

            this.dialog.open(CustomDialogComponent, {
              maxWidth: '455px',
              width: '100%',
              height: 'auto',
              data: data,
            });

            this.dialogRef.close(true);
          });
      }
    }
  }

  isFormValid(): boolean {
    this.form.markAllAsTouched();

    return this.form.valid;
  }

  onImageChanged(selectedFile: Asset) {
    this.form.controls['file'].patchValue(selectedFile);
  }
}
