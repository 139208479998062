import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatMenuModule } from '@angular/material/menu';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { AppRoutes } from '../../../../../routes';
import { AffiliateProduct } from '@models/affiliate-products/affiliate-product.model';
import { AffiliateProductCreationRequest } from '@models/affiliate-products/dto/affiliate-product-creation.request';
import { AffiliateProductStatus } from '@models/affiliate-products/affiliate-product-status.enum';
import { CustomDialogData } from '@models/overlay-dialogs/custom-dialog-data.model';
import { CustomDialogComponent } from '@modules/shared/dialogs/custom-dialog/custom-dialog.component';
import { Router } from '@angular/router';
import { AffiliateProductsService } from '@services/affiliate-products.service';
import { MatDialog } from '@angular/material/dialog';
import { Clipboard } from '@angular/cdk/clipboard';
import { NotificationService } from '@services/shared/notification.service';

@Component({
  selector: 'app-affiliate-product-details-header',
  standalone: true,
  imports: [CommonModule, MatMenuModule, TranslateModule],
  providers: [NotificationService],
  templateUrl: './affiliate-product-details-header.component.html',
  styleUrl: './affiliate-product-details-header.component.scss',
})
export class AffiliateProductDetailsHeaderComponent {
  @Input() product?: AffiliateProduct;

  @Output() onPause: EventEmitter<AffiliateProduct> =
    new EventEmitter<AffiliateProduct>();
  @Output() onContinue: EventEmitter<AffiliateProduct> =
    new EventEmitter<AffiliateProduct>();
  @Output() onArchive: EventEmitter<AffiliateProduct> =
    new EventEmitter<AffiliateProduct>();
  @Output() onRestore: EventEmitter<AffiliateProduct> =
    new EventEmitter<AffiliateProduct>();

  constructor(
    private router: Router,
    private affiliateProductService: AffiliateProductsService,
    private dialog: MatDialog,
    private clipboard: Clipboard,
    private notificationService: NotificationService,
    private translateService: TranslateService,
  ) {}

  returnToAffiliateProductsListPage() {
    this.router.navigate([
      '/',
      AppRoutes.Admin.root,
      AppRoutes.Admin.Affiliates.affiliates,
    ]);
  }

  copyProduct(product: AffiliateProduct) {
    this.affiliateProductService.copy(product.id).subscribe(() => {
      this.notificationService.success(
        this.translateService.instant(
          'APP.AFFILIATE_PRODUCTS_PAGE_LIST.SUCCESS.PRODUCT_COPIED',
        ),
      );
    });
  }

  copyProductLink(productLink: string) {
    this.clipboard.copy(productLink);

    this.notificationService.success(
      this.translateService.instant(
        'APP.AFFILIATE_PRODUCTS_PAGE_LIST.SUCCESS.LINK_COPIED',
      ),
    );
  }

  pauseProduct(product: AffiliateProduct) {
    this.affiliateProductService
      .setActive(product.id, false)
      .subscribe((affiliateProduct) => {
        this.onPause.emit(affiliateProduct);
      });
  }

  continueProduct(product: AffiliateProduct) {
    this.affiliateProductService
      .setActive(product.id, true)
      .subscribe((affiliateProduct) => {
        this.onContinue.emit(affiliateProduct);
      });
  }

  archiveProduct(product: AffiliateProduct) {
    const dto: AffiliateProductCreationRequest = {
      status: AffiliateProductStatus.ARCHIVED,
    };

    this.affiliateProductService
      .updateProduct(product?.id, dto)
      .subscribe((affiliateProduct) => {
        this.onArchive.emit(affiliateProduct);
      });
  }

  restoreProduct(product: AffiliateProduct) {
    const dto: AffiliateProductCreationRequest = {
      status: AffiliateProductStatus.ACTIVE,
    };

    this.affiliateProductService
      .updateProduct(product.id, dto)
      .subscribe((affiliateProduct) => {
        this.onRestore.emit(affiliateProduct);
      });
  }

  deleteProduct(product: AffiliateProduct) {
    const question =
      'APP.AFFILIATE_PRODUCTS_PAGE_LIST.CUSTOM_DIALOG.DELETE_PRODUCT_MESSAGE';
    const cancelButtonMessage =
      'APP.AFFILIATE_PRODUCTS_PAGE_LIST.ACTION_BUTTONS.CANCEL_DELETE_PRODUCT';
    const deleteButtonMessage =
      'APP.AFFILIATE_PRODUCTS_PAGE_LIST.ACTION_BUTTONS.CONFIRM_DELETE_PRODUCT';

    const data: CustomDialogData = {
      isDeleteDialog: true,
      question: question,
      id: product.id,
      title: product.name.toUpperCase(),
      pictureUrl: product.pictureUrl,
      partner: product?.network,
      placements: product?.affiliateProductPlacements,
      performance: {
        clicks: product.affiliateProductStats.totalAffiliateClicks,
        ctr: product.affiliateProductStats.totalAffiliateCtr,
      },
      cancelButtonMessage: cancelButtonMessage,
      deleteButtonMessage: deleteButtonMessage,
    };

    const dialogRef = this.dialog.open(CustomDialogComponent, {
      maxWidth: '456px',
      width: '100%',
      height: 'auto',
      data: data,
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result === true) {
        this.affiliateProductService.deleteProduct(product.id).subscribe(() => {
          this.returnToAffiliateProductsListPage();
        });
      }
    });
  }
}
