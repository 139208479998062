import { Component } from '@angular/core';
import { EventListComponent } from '../../components/event-list/event-list.component';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  standalone: true,
  imports: [CommonModule, TranslateModule, EventListComponent],
  templateUrl: './event-list.page.html',
  styleUrl: './event-list.page.scss',
})
export class EventListPage {}
