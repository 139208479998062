<div class="event-after-dialog">
  <div (click)="close()" class="close-button">
    <i class="fal fa-times-circle"></i>
  </div>

  <div class="after-dialog-header">
    <h1 class="title">
      {{ "APP.CHOOSE_EVENT_TEMPLATE_LANGUAGE.TITLE" | translate }}
    </h1>
    <div class="title-desc">
      {{ "APP.CHOOSE_EVENT_TEMPLATE_LANGUAGE.DESCRIPTION" | translate }}
    </div>

    <form [formGroup]="form">
      <mat-form-field class="flex-input" appearance="outline">
        <mat-label>{{ "APP.LANGUAGES.CHOOSE_LANGUAGE" | translate }}</mat-label>
        <mat-select formControlName="language">
          <mat-option
            *ngFor="let language of eventTemplate.getLanguages()"
            [value]="language"
          >
            {{ getTranslationLanguageNames(language) }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </form>

    <div mat-dialog-actions class="actions-container">
      <button
        *ngIf="isCopyTemplatePublicUrl"
        (click)="copyTemplatePublicUrl()"
        type="button"
        mat-raised-button
        class="actionButton btn btn-primary addButton"
      >
        <span class="actions">
          {{ "APP.DESIGN_TEMPLATES.ACTIONS.PUBLIC_URL" | translate }}
        </span>
      </button>

      <div class="a">
        <a
          mat-menu-item
          [href]="getEventTemplateQRCodeUrl()"
          [download]="getTranslatedUri()"
          *ngIf="isCreateQrCode"
          class="actionButton btn btn-primary addButton"
        >
          <qrcode
            [qrdata]="getTranslatedLink()"
            [allowEmptyString]="true"
            [cssClass]="'display-none'"
            [elementType]="'svg'"
            [errorCorrectionLevel]="'M'"
            [margin]="0"
            [scale]="1"
            [width]="300"
            (qrCodeURL)="onChangeQRCodeURL($event, eventTemplate.id)"
          ></qrcode>
          <span class="menu-item-content">
            <i class="far fa-qrcode"></i>
            <span class="actions">{{
              "APP.DOWNLOAD_QR_CODE" | translate
            }}</span>
          </span>
        </a>
      </div>
    </div>
  </div>
</div>
