<nav
  class="navbar"
  [class.shadow]="lastTopPosition > 0"
  [class.hidden]="isDesktop || (!isDesktop && !navbarDisplayed)"
>
  <div class="container header-container">
    <div class="flex-placeholder"></div>
    <a class="navbar-brand" routerLink="/">
      <img
        class="media-object header-logo"
        [src]="env.iconAsset"
        alt="eventpage.ai - Logo"
      />
    </a>
    <div class="drawer-button" (click)="toggleSidenav()">
      <i class="fal fa-bars"></i>
    </div>
  </div>
</nav>
