import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModules } from '@utils/shared-modules';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { MatChipsModule } from '@angular/material/chips';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { AbstractControl, FormControl, FormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { AffiliateProductType } from '@models/affiliate-products/affilaite-product-type.enum';

@Component({
  selector: 'app-affiliate-product-type-select',
  standalone: true,
  imports: [
    CommonModule,
    FormsModules,
    TranslateModule,
    MatChipsModule,
    MatCheckboxModule,
  ],
  templateUrl: './affiliate-product-type-select.component.html',
  styleUrl: './affiliate-product-type-select.component.scss',
})
export class AffiliateProductTypeSelectComponent {
  private _items: AffiliateProductType[] = [];
  private valueChangesSet = false;

  @Input() form!: FormGroup;
  @Input() selectedFormControlName: string = '';
  @Input() set items(items: AffiliateProductType[]) {
    if (items.length > 0) {
      this._items = items;
      if (!this.valueChangesSet) {
        this.filteredItems = this.inputControl.valueChanges.pipe(
          startWith<string | null>(''),
          map((value) => (typeof value === 'string' ? value : this.lastFilter)),
          map((filter) => this.filter(filter)),
        );
      }
    }
  }

  get items(): AffiliateProductType[] {
    return this._items;
  }

  inputControl = new FormControl<string>('');

  selectedItems: AffiliateProductType[] = new Array<AffiliateProductType>();

  filteredItems: Observable<AffiliateProductType[]> = new Observable();
  lastFilter: string = '';

  constructor(private translateService: TranslateService) {}

  ngOnInit(): void {
    this.selectedItems = this.form.controls[this.selectedFormControlName].value;

    this.form.controls[this.selectedFormControlName].valueChanges.subscribe(
      (val) => (this.selectedItems = val),
    );
  }

  filter(filter: string): AffiliateProductType[] {
    this.lastFilter = filter;
    if (filter) {
      return this.items.filter((option) => {
        const label = this.translateService.instant(
          AffiliateProductType.getDisplayName(option),
        );
        return label.toLowerCase().indexOf(filter.toLowerCase()) >= 0;
      });
    } else {
      return this.items.slice();
    }
  }

  optionClicked(event: Event, item: AffiliateProductType) {
    event.stopPropagation();
    this.toggleSelection(item);
  }

  toggleSelection(item: AffiliateProductType) {
    if (!this.isSelected(item)) {
      this.selectedItems.push(item);
    } else {
      this.remove(item, false);
    }

    this.inputControl.setValue('');
    this.form.controls[this.selectedFormControlName].setValue(
      this.selectedItems,
    );
  }

  remove(item: AffiliateProductType, updateFormValue = true): void {
    const index = this.selectedItems.findIndex(
      (selectedItem) => selectedItem === item,
    );

    if (index >= 0) {
      this.selectedItems.splice(index, 1);

      if (updateFormValue) {
        this.form.controls[this.selectedFormControlName].setValue(
          this.selectedItems,
        );
      }
    }
  }

  isSelected(item: AffiliateProductType): boolean {
    return !!this.selectedItems.find((selectedItem) => selectedItem === item);
  }

  displayFn(_: string): string {
    return '';
  }

  getDisplayName(item: AffiliateProductType) {
    return AffiliateProductType.getDisplayName(item);
  }

  get selectedFormControl(): AbstractControl {
    return this.form.controls[this.selectedFormControlName];
  }
}
