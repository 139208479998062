import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormGroup } from '@angular/forms';
import { FormsModules } from '@utils/shared-modules';
import { TranslateModule } from '@ngx-translate/core';
import { FontSelectorComponent } from '@modules/shared/components/font-selector/font-selector.component';
import { CopyableTextComponent } from '@modules/shared/components/copyable-text/copyable-text.component';
import { FontService } from '@services/shared/font.service';

@Component({
  selector: 'app-template-text-step',
  standalone: true,
  imports: [
    CommonModule,
    FormsModules,
    TranslateModule,
    FontSelectorComponent,
    CopyableTextComponent,
  ],
  templateUrl: './template-text-step.component.html',
  styleUrl: './template-text-step.component.scss',
})
export class TemplateTextStepComponent {
  @Input() form?: FormGroup;

  constructor(private fontService: FontService) {
    this.fontService.fetchGoogleFonts();
  }
}
