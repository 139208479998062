import { NgcCookieConsentConfig } from 'ngx-cookieconsent';

export const cookieConfig: NgcCookieConsentConfig = {
  cookie: {
    domain:
      typeof window !== 'undefined' && window && window.location
        ? window.location.hostname
        : 'app.eventpage.ai',
  },
  position: 'bottom-left',
  theme: 'classic',
  palette: {
    popup: {
      background: 'var(--body-bg-color)',
      text: 'var(--body-color)',
      link: 'var(--body-color)',
    },
    button: {
      background: 'var(--brand-primary)',
      text: 'var(--text-light)',
      border: 'transparent',
    },
  },
  type: 'opt-in',
};
