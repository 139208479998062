@if (form && filteredTranslationTemplates) {
  <form [formGroup]="form">
    <h2 class="body-header">
      {{ "APP.DESIGN_TEMPLATES.CREATE.LANGUAGE" | translate }}
    </h2>
    <mat-form-field class="half-field" appearance="outline">
      <mat-label
        >{{
          "APP.DESIGN_TEMPLATES.CREATE.TEMPLATE_LANGUAGE" | translate
        }}*</mat-label
      >
      <mat-select formControlName="languageCode">
        <mat-option *ngFor="let language of languages" [value]="language">
          {{ "APP.LANGUAGES." + language.toUpperCase() | translate }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <div class="flex-inputs-cont">
      <mat-form-field class="flex-input" appearance="outline">
        <mat-label>{{
          "APP.DESIGN_TEMPLATES.CREATE.TRANSLATION_LANGUAGE" | translate
        }}</mat-label>
        <mat-select formControlName="translationTemplateLanguageCode">
          <mat-option
            *ngFor="let language of translationLanguages"
            [value]="language"
          >
            {{ "APP.LANGUAGES." + language.toUpperCase() | translate }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <app-autocomplete-select
        [form]="form"
        selectedFormControlName="translationTemplate"
        [items]="filteredTranslationTemplates"
        labelProperty="name"
        valueProperty="id"
        [useValueProperty]="true"
        [formFieldClass]="'flex-input w-100'"
        [translationAutocomplete]="true"
        class="translation-template-autocomplete"
      >
      </app-autocomplete-select>
      @if (form.controls["translationTemplate"].value) {
        <button
          mat-mini-fab
          class="pen-btn"
          (click)="editTranslationTemplate()"
        >
          <i class="far fa-pen pen-icon"></i>
        </button>
      }
      <!-- <mat-form-field class="flex-input" appearance="outline">
        <mat-label>{{ "APP.RELEVANCE" | translate }}</mat-label>
        <mat-select formControlName="translationTemplate">
          <mat-option
            *ngFor="let translationTemplate of translationTemplates"
            [value]="translationTemplate.id"
          >
            {{ translationTemplate.name }}
          </mat-option>
        </mat-select>
      </mat-form-field> -->
    </div>
    @if (isTranslationTarget) {
      <div class="translation-target">
        <small>{{
          "APP.DESIGN_TEMPLATES.CREATE.TRANSLATION_TARGET_INFO" | translate
        }}</small>
      </div>
    }

    <h2 class="body-header">
      {{ "APP.DESIGN_TEMPLATES.CREATE.NAME_AND_STATUS" | translate }}*
    </h2>
    <div class="flex-inputs-cont">
      <mat-form-field appearance="outline" class="flex-half flex-input">
        <mat-label>{{
          "APP.DESIGN_TEMPLATES.CREATE.NAME" | translate
        }}</mat-label>
        <input
          name="name"
          type="text"
          matInput
          formControlName="name"
          placeholder=""
        />
        @if (form.controls["name"].hasError("required")) {
          <mat-error>
            {{ "APP.DESIGN_TEMPLATES.CREATE.ERRORS.NAME_REQUIRED" | translate }}
          </mat-error>
        }
      </mat-form-field>
      <mat-form-field class="flex-input" appearance="outline">
        <mat-label>{{ "APP.STATUS" | translate }}</mat-label>
        <mat-select formControlName="status">
          <mat-option *ngFor="let access of accesses" [value]="access">
            {{ getAccessDisplayName(access) | translate }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field class="flex-input" appearance="outline">
        <mat-label>{{ "APP.RELEVANCE" | translate }}</mat-label>
        <mat-select formControlName="relevance">
          <mat-option *ngFor="let relevance of relevances" [value]="relevance">
            {{ getRelevanceDisplayName(relevance) | translate }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <!--    <mat-form-field appearance="outline" class="w-100">-->
    <!--      <mat-label>{{ "APP.DESIGN_TEMPLATES.CREATE.URI" | translate }}</mat-label>-->
    <!--      <input-->
    <!--        name="uri"-->
    <!--        type="text"-->
    <!--        matInput-->
    <!--        formControlName="uri"-->
    <!--        placeholder=""-->
    <!--      />-->
    <!--    </mat-form-field>-->

    <h2 class="body-header">
      {{ "APP.DESIGN_TEMPLATES.CREATE.EVENT_DATA" | translate }}*
    </h2>
    <mat-form-field appearance="outline" class="w-100">
      <mat-label>{{
        "APP.DESIGN_TEMPLATES.CREATE.EVENT_NAME" | translate
      }}</mat-label>
      <input
        #eventName
        name="eventName"
        type="text"
        matInput
        formControlName="eventName"
        maxlength="30"
      />
      <span matTextSuffix>{{ eventName.value.length || "" }} | 30</span>
    </mat-form-field>
    <mat-form-field appearance="outline" class="w-100">
      <mat-label>{{
        "APP.DESIGN_TEMPLATES.CREATE.EVENT_CTA" | translate
      }}</mat-label>
      <input
        #eventCta
        name="eventCta"
        type="text"
        matInput
        formControlName="eventCta"
        maxlength="25"
      />
      <span matTextSuffix>{{ eventCta.value.length || "" }} | 25</span>
    </mat-form-field>
    <mat-form-field appearance="outline" class="w-100">
      <mat-label>{{
        "APP.DESIGN_TEMPLATES.CREATE.EVENT_DESCRIPTION" | translate
      }}</mat-label>
      <textarea matInput formControlName="description" rows="5"></textarea>
    </mat-form-field>

    <h2 class="body-header">
      {{ "APP.LOCATION" | translate }}
    </h2>
    <div class="flex-inputs-cont">
      <mat-form-field appearance="outline" class="flex-half-shrink flex-input">
        <mat-label>{{ "APP.LOCATION_NAME" | translate }}</mat-label>
        <input
          type="text"
          matInput
          formControlName="locationName"
          placeholder=""
        />
      </mat-form-field>
      <app-address-picker
        (addressChange)="onAddressChange($event)"
        [address]="form.controls['locationAddress'].value"
        class="flex-half-shrink"
      ></app-address-picker>
    </div>

    <h2 class="body-header">
      {{ "APP.DESIGN_TEMPLATES.CREATE.TEMPLATE_CUSTOM_DATES" | translate }}
    </h2>
    <div class="template-dates-cont">
      <div class="template-date-content">
        <mat-form-field appearance="outline">
          <mat-label>{{
            "APP.DESIGN_TEMPLATES.CREATE.PRE_SET_DATE" | translate
          }}</mat-label>
          <mat-date-range-input [rangePicker]="presetPicker">
            <input
              matStartDate
              placeholder="DD / MM / YYYY "
              formControlName="presetStartDate"
            />
            <input
              matEndDate
              placeholder="End date"
              formControlName="presetEndDate"
            />
          </mat-date-range-input>
          <mat-datepicker-toggle
            matIconSuffix
            [for]="presetPicker"
          ></mat-datepicker-toggle>
          <mat-date-range-picker #presetPicker></mat-date-range-picker>
        </mat-form-field>

        <div class="time-range-cont">
          <app-time-picker
            formControlName="presetStartTime"
            [interval]="15"
            [format24Hours]="true"
            [isList]="false"
            label="APP.START_TIME"
          ></app-time-picker>
          <div>{{ "APP.TO" | translate }}</div>
          <app-time-picker
            formControlName="presetEndTime"
            [interval]="15"
            [format24Hours]="true"
            [isList]="false"
            label="APP.END_TIME"
          ></app-time-picker>
        </div>

        <app-timezone-select
          [form]="form"
          selectedFormControlName="presetTimeZone"
        >
        </app-timezone-select>
      </div>
      <div class="template-date-content">
        <mat-form-field appearance="outline">
          <mat-label>{{
            "APP.DESIGN_TEMPLATES.CREATE.PROMOTION_PERIOD" | translate
          }}</mat-label>
          <mat-date-range-input [rangePicker]="promotionPeriodPicker">
            <input
              matStartDate
              placeholder="DD / MM / YYYY "
              formControlName="promotionPeriodStartDate"
            />
            <input
              matEndDate
              placeholder="End date"
              formControlName="promotionPeriodEndDate"
            />
          </mat-date-range-input>
          <mat-datepicker-toggle
            matIconSuffix
            [for]="promotionPeriodPicker"
          ></mat-datepicker-toggle>
          <mat-date-range-picker #promotionPeriodPicker></mat-date-range-picker>
        </mat-form-field>

        <div class="time-range-cont">
          <app-time-picker
            formControlName="promotionPeriodStartTime"
            [interval]="15"
            [format24Hours]="true"
            [isList]="false"
            label="APP.START_TIME"
          ></app-time-picker>
          <div>{{ "APP.TO" | translate }}</div>
          <app-time-picker
            formControlName="promotionPeriodEndTime"
            [interval]="15"
            [format24Hours]="true"
            [isList]="false"
            label="APP.END_TIME"
          ></app-time-picker>
        </div>

        <app-timezone-select
          [form]="form"
          selectedFormControlName="promotionPeriodTimeZone"
        >
        </app-timezone-select>
      </div>
    </div>

    <h2 class="body-header">{{ "APP.ASSIGN_KEYWORDS" | translate }}*</h2>
    <app-keywords-select
      [form]="form"
      selectedFormControlName="keywords"
      [items]="keywords"
    ></app-keywords-select>

    <h2 class="body-header">{{ "APP.CHOOSE_CATEGORY" | translate }}*</h2>
    <app-category-select
      [form]="form"
      selectedFormControlName="categories"
      [eventCategories]="categories"
    ></app-category-select>

    <h2 class="body-header">{{ "APP.CHOOSE_SUBCATEGORY" | translate }}*</h2>
    <app-category-select
      [form]="form"
      selectedFormControlName="subCategories"
      [eventCategories]="subCategories"
      [addNew]="true"
      (refreshCategories)="onRefreshCategories()"
    ></app-category-select>

    <h2 class="body-header">{{ "APP.ASSIGN_TARGET_GROUP" | translate }}*</h2>
    <app-target-groups-select
      [form]="form"
      selectedFormControlName="targetGroups"
      [items]="targetGroups"
    ></app-target-groups-select>
  </form>
}
