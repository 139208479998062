export enum Kit {
  EVENT_KIT = 'EVENT_KIT',
  PROMOTION_KIT = 'PROMOTION_KIT',
  ORGANIZATION_KIT = 'ORGANIZATION_KIT',
  COMMUNICATION_KIT = 'COMMUNICATION_KIT',
}

export namespace Kit {
  export function getAllKits(): Kit[] {
    return [
      Kit.EVENT_KIT,
      Kit.PROMOTION_KIT,
      Kit.ORGANIZATION_KIT,
      Kit.COMMUNICATION_KIT,
    ];
  }

  export function getDisplayName(kit?: Kit): string {
    switch (kit) {
      case Kit.EVENT_KIT:
        return 'APP.KITS.EVENT_KIT';
      case Kit.PROMOTION_KIT:
        return 'APP.KITS.PROMOTION_KIT';
      case Kit.ORGANIZATION_KIT:
        return 'APP.KITS.ORGANIZATION_KIT';
      case Kit.COMMUNICATION_KIT:
        return 'APP.KITS.COMMUNICATION_KIT';
      default:
        return '';
    }
  }
}
