@if (form) {
  <form [formGroup]="form">
    <h2 class="body-header">
      {{ "APP.DESIGN_TEMPLATES.CREATE.THUMBNAIL" | translate }}*
    </h2>
    <div class="file-container">
      <app-file-uploader
        [aspectRatio]="1 / 1"
        [fluidWidth]="true"
        [resizeToWidth]="500"
        [useImagePreviewComponent]="true"
        [uploaderType]="'IMAGE_AND_VIDEO'"
        [selectedFile]="form.controls['thumbnailAsset'].value"
        [invalid]="
          form.controls['thumbnailAsset'].touched &&
          form.controls['thumbnailAsset'].invalid
        "
        (fileChanged)="onImageChanged($event, 'thumbnail')"
      >
      </app-file-uploader>
    </div>

    <h2 class="body-header">
      {{ "APP.DESIGN_TEMPLATES.CREATE.LOGO" | translate }}
    </h2>
    <div class="file-container">
      <app-file-uploader
        [aspectRatio]="1 / 1"
        [fluidWidth]="true"
        [resizeToWidth]="500"
        [useImagePreviewComponent]="true"
        [uploaderType]="'IMAGE_AND_VIDEO'"
        [selectedFile]="form.controls['logoAsset'].value"
        (fileChanged)="onImageChanged($event, 'logo')"
      >
      </app-file-uploader>
    </div>

    <h2 class="body-header">
      {{ "APP.DESIGN_TEMPLATES.CREATE.KEY_VISUAL_1" | translate }}*
    </h2>
    <app-template-asset-select
      [type]="EventTemplateAssetType.KEY_VISUAL"
      [mainCategories]="mainCategories"
      [selectedAsset]="form.controls['keyVisual1'].value"
      [invalid]="
        form.controls['keyVisual1'].touched &&
        form.controls['keyVisual1'].invalid
      "
      (assetChanged)="onTemplateAssetChanged($event, 'keyVisual1')"
    >
    </app-template-asset-select>

    <h2 class="body-header">
      {{ "APP.DESIGN_TEMPLATES.CREATE.KEY_VISUAL_2" | translate }}
    </h2>
    <div class="file-container">
      <app-file-uploader
        [aspectRatio]="1 / 1"
        [fluidWidth]="true"
        [resizeToWidth]="500"
        [useImagePreviewComponent]="true"
        [uploaderType]="'IMAGE_AND_VIDEO'"
        [selectedFile]="form.controls['keyVisual2Asset'].value"
        (fileChanged)="onImageChanged($event, 'keyVisual2')"
      >
      </app-file-uploader>
    </div>

    <h2 class="body-header">
      {{ "APP.DESIGN_TEMPLATES.CREATE.PATTERN" | translate }}
    </h2>
    <div class="file-container">
      <app-file-uploader
        [fluidWidth]="true"
        [useImagePreviewComponent]="true"
        [uploaderType]="'IMAGE_AND_VIDEO'"
        [selectedFile]="form.controls['patternAsset'].value"
        (fileChanged)="onImageChanged($event, 'pattern')"
      >
      </app-file-uploader>
    </div>

    <h2 class="body-header">
      {{ "APP.DESIGN_TEMPLATES.CREATE.BACKGROUND" | translate }}*
    </h2>
    <app-template-asset-select
      [type]="EventTemplateAssetType.BACKGROUND"
      [mainCategories]="mainCategories"
      [selectedAsset]="form.controls['background'].value"
      [invalid]="
        form.controls['background'].touched &&
        form.controls['background'].invalid
      "
      (assetChanged)="onTemplateAssetChanged($event, 'background')"
    >
    </app-template-asset-select>

    <app-color-picker
      [form]="form"
      colorFormControlName="backgroundColor"
      fieldClass="half-field"
    ></app-color-picker>

    <h2 class="body-header">
      {{ "APP.DESIGN_TEMPLATES.CREATE.FOOTER" | translate }}
    </h2>
    <app-color-picker
      [form]="form"
      colorFormControlName="footerColor"
      fieldClass="half-field"
    ></app-color-picker>

    <h2 class="body-header">
      {{ "APP.DESIGN_TEMPLATES.CREATE.EFFECT" | translate }}
    </h2>
    <app-template-asset-select
      [type]="EventTemplateAssetType.EFFECT"
      [mainCategories]="mainCategories"
      [selectedAsset]="form.controls['effect'].value"
      [invalid]="
        form.controls['effect'].touched && form.controls['effect'].invalid
      "
      (assetChanged)="onTemplateAssetChanged($event, 'effect')"
    >
    </app-template-asset-select>
  </form>
}
