import { CollectionViewer, DataSource } from '@angular/cdk/collections';
import {
  asapScheduler,
  BehaviorSubject,
  finalize,
  Observable,
  scheduled,
} from 'rxjs';
import { catchError } from 'rxjs/operators';
import { PageListingResponse } from '@models/api/page-listing-response.model';
import { EventTemplate } from '@models/design-templates/event-template.model';
import { EventTemplateService } from '@services/events/event-template.service';

export class EventTemplateDataSource implements DataSource<EventTemplate> {
  private eventTemplatesSubject = new BehaviorSubject<EventTemplate[]>([]);
  private loadingSubject = new BehaviorSubject<boolean>(false);
  private numRecordsSubject = new BehaviorSubject<number>(0);

  numRecords$ = this.numRecordsSubject.asObservable();
  loading$ = this.loadingSubject.asObservable();

  data!: EventTemplate[];

  constructor(private eventTemplateService: EventTemplateService) {}
  connect(_: CollectionViewer): Observable<EventTemplate[]> {
    return this.eventTemplatesSubject.asObservable();
  }

  disconnect(_: CollectionViewer): void {
    this.eventTemplatesSubject.complete();
    this.numRecordsSubject.complete();
    this.loadingSubject.complete();
  }

  loadEventTemplates(filters: Map<string, any>, pageNumber = 1, pageSize = 10) {
    this.loadingSubject.next(true);

    this.eventTemplateService
      .getPaged(filters, pageNumber, pageSize)
      .pipe(
        catchError(() => {
          this.data = [];
          return scheduled([], asapScheduler);
        }),
        finalize(() => this.loadingSubject.next(false)),
      )
      .subscribe((res: PageListingResponse<EventTemplate>) => {
        this.eventTemplatesSubject.next(res.records);
        this.numRecordsSubject.next(res.total);
        this.data = res.records;
      });
  }
}
