import { mergeApplicationConfig, ApplicationConfig } from '@angular/core';
import { appConfig } from './app.config';
import { provideNgcCookieConsent } from 'ngx-cookieconsent';
import { cookieConfig } from './config/cookie.config';

const browserConfig: ApplicationConfig = {
  providers: [provideNgcCookieConsent(cookieConfig)],
};

export const config = mergeApplicationConfig(appConfig, browserConfig);
