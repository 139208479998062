<mat-form-field
  appearance="outline"
  class="half-field"
  [ngClass]="{
    'ng-invalid mdc-text-field--invalid':
      (inputControl.touched || selectedFormControl.touched) &&
      selectedFormControl.invalid
  }"
>
  <input
    type="text"
    placeholder="{{ 'APP.TYPE_IN_OR_SELECT' | translate }}"
    matInput
    [matAutocomplete]="auto"
    [formControl]="inputControl"
  />
  <i matSuffix class="fas fa-caret-down autocomplete-dropdown-icon"></i>
</mat-form-field>

<mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
  <mat-option
    *ngFor="let item of filteredItems | async"
    [value]="item"
    (click)="optionClicked($event, item)"
  >
    <div>
      <mat-checkbox
        [checked]="isSelected(item)"
        (change)="toggleSelection(item)"
        (click)="$event.stopPropagation()"
      >
        {{ getDisplayName(item) | translate }}
      </mat-checkbox>
    </div>
  </mat-option>
</mat-autocomplete>

<mat-chip-set class="autocomplete-select-chip-set">
  <mat-chip
    *ngFor="let item of selectedItems"
    selectable
    removable
    (removed)="remove(item)"
    class="autocomplete-list-chip"
  >
    {{ getDisplayName(item) | translate }}
    <span matChipRemove><i class="far fa-times"></i></span>
  </mat-chip>
</mat-chip-set>
