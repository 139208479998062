import { CommonModule } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CampaignStatus } from '@models/campaigns/campaign-status.enum';
import { Campaign } from '@models/campaigns/campaign.model';
import { SetEventCampaignRequest } from '@models/campaigns/dto/set-event-campaign.request';
import { Event } from '@models/events/event.model';
import { categoryData } from '@models/events/wg-event-category.enum';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { CampaignService } from '@services/campaign.service';
import { EventService } from '@services/events/event.service';
import { NotificationService } from '@services/shared/notification.service';
import { FormsModules } from '@utils/shared-modules';

@Component({
  selector: 'app-set-event-campaign',
  standalone: true,
  imports: [CommonModule, FormsModules, TranslateModule],
  providers: [CampaignService, EventService, NotificationService],
  templateUrl: './set-event-campaign.dialog.html',
  styleUrl: './set-event-campaign.dialog.scss',
})
export class SetEventCampaignDialog {
  event?: Event;
  campaigns?: Campaign[];
  categoryData = categoryData.filter((category) =>
    category.title.includes('WG'),
  );
  categoryCampaignIds = [4, 6];

  form!: FormGroup;

  constructor(
    public dialogRef: MatDialogRef<SetEventCampaignDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private campaignService: CampaignService,
    private eventService: EventService,
    private notificationService: NotificationService,
    private translateService: TranslateService,
    private fb: FormBuilder,
  ) {
    const filters = new Map<string, any>();
    filters.set('status', CampaignStatus.ACTIVE);

    this.campaignService.getAll(filters).subscribe((campaigns) => {
      this.campaigns = campaigns;
    });

    if (data) {
      this.event = data.event;
    }

    this.form = this.fb.group({
      campaignId: ['', Validators.required],
      category: [''],
    });
  }

  setEventCampaign() {
    if (!this.event) {
      return;
    }

    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return;
    }

    const values = this.form.value;

    const request: SetEventCampaignRequest = {
      campaignId: values.campaignId,
    };

    if (
      this.categoryCampaignIds.includes(values.campaignId) &&
      values.category !== ''
    ) {
      request.category = values.category;
    }

    this.eventService.setEventCampaign(this.event.id, request).subscribe({
      next: () => {
        this.dialogRef.close({ refresh: true });
      },
      error: (err) => {
        this.notificationService.error(
          this.translateService.instant('APP.ERRORS.COULD_NOT_SAVE'),
        );
      },
    });
  }

  close() {
    this.dialogRef.close();
  }
}
