import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormGroup } from '@angular/forms';
import { FormsModules } from '@utils/shared-modules';
import { TranslateModule } from '@ngx-translate/core';
import { Asset } from '@models/shared/asset.model';
import { FileUploaderComponent } from '@modules/shared/components/file-uploader/file-uploader.component';
import { ColorPickerComponent } from '@modules/shared/components/color-picker/color-picker.component';
import { EventTemplateAsset } from '@models/events/event-template-asset.model';
import { EventTemplateAssetType } from '@models/events/event-template-asset-type.enum';
import { TemplateAssetSelectComponent } from '@modules/shared/components/template-asset-select/template-asset-select.component';
import { EventCategory } from '@models/event-categories/event-category.model';
import { EventCategoriesService } from '@services/event-categories.service';

@Component({
  selector: 'app-template-visual-step',
  standalone: true,
  imports: [
    CommonModule,
    FormsModules,
    TranslateModule,
    FileUploaderComponent,
    ColorPickerComponent,
    TemplateAssetSelectComponent,
  ],
  providers: [EventCategoriesService],
  templateUrl: './template-visual-step.component.html',
  styleUrl: './template-visual-step.component.scss',
})
export class TemplateVisualStepComponent {
  EventTemplateAssetType = EventTemplateAssetType;

  @Input() form!: FormGroup;

  mainCategories: EventCategory[] = [];

  constructor(private eventCategoriesService: EventCategoriesService) {
    this.eventCategoriesService.getEventCategories(true).subscribe({
      next: (categories) => {
        this.mainCategories = categories.sort((a, b) =>
          a.name.localeCompare(b.name),
        );
      },
    });
  }

  onImageChanged(
    asset: Asset,
    assetName: 'thumbnail' | 'logo' | 'keyVisual2' | 'pattern',
  ) {
    this.form.controls[`${assetName}Asset`].patchValue(asset);
  }

  onTemplateAssetChanged(
    asset: EventTemplateAsset,
    assetName: 'keyVisual1' | 'background' | 'effect',
  ) {
    this.form.controls[assetName].patchValue(asset);
  }
}
