import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { Clipboard } from '@angular/cdk/clipboard';
import { NotificationService } from '@services/shared/notification.service';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { AppRoutes } from '../../../../../routes';
import { CustomDialogData } from '@models/overlay-dialogs/custom-dialog-data.model';
import { CustomDialogComponent } from '@modules/shared/dialogs/custom-dialog/custom-dialog.component';
import { EventTemplate } from '@models/design-templates/event-template.model';
import { EventTemplateService } from '@services/events/event-template.service';
import { EventTemplateAccess } from '@models/design-templates/event-template-access.enum';
import { MatMenuModule } from '@angular/material/menu';
import { environment as env } from '@environments/environment';
import { EventTemplateAccessRequest } from '@models/design-templates/dto/event-template-access.request';

@Component({
  selector: 'app-design-template-details-header',
  standalone: true,
  imports: [CommonModule, MatMenuModule, TranslateModule],
  providers: [NotificationService],
  templateUrl: './design-template-details-header.component.html',
  styleUrl: './design-template-details-header.component.scss',
})
export class DesignTemplateDetailsHeaderComponent {
  @Input() eventTemplate?: EventTemplate;

  @Output() onPause: EventEmitter<EventTemplate> =
    new EventEmitter<EventTemplate>();
  @Output() onContinue: EventEmitter<EventTemplate> =
    new EventEmitter<EventTemplate>();
  @Output() onArchive: EventEmitter<EventTemplate> =
    new EventEmitter<EventTemplate>();
  @Output() onRestore: EventEmitter<EventTemplate> =
    new EventEmitter<EventTemplate>();

  constructor(
    private router: Router,
    private eventTemplateService: EventTemplateService,
    private dialog: MatDialog,
    private clipboard: Clipboard,
    private notificationService: NotificationService,
    private translateService: TranslateService,
  ) {}

  returnToEventTemplateListPage() {
    this.router.navigate([
      '/',
      AppRoutes.Admin.root,
      AppRoutes.Admin.DesignTemplates.designTemplates,
    ]);
  }

  copyTemplateId(eventTemplateId: number) {
    this.clipboard.copy(eventTemplateId.toString());

    this.notificationService.success(
      this.translateService.instant('APP.DESIGN_TEMPLATES.SUCCESS.ID_COPIED'),
    );
  }

  copyLink() {
    this.clipboard.copy(env.website + this.router.url);

    this.notificationService.success(
      this.translateService.instant('APP.DESIGN_TEMPLATES.SUCCESS.LINK_COPIED'),
    );
  }

  pauseTemplate(eventTemplate: EventTemplate) {
    const dto: EventTemplateAccessRequest = {
      access: EventTemplateAccess.INACTIVE,
      restore: false,
    };

    this.eventTemplateService
      .setAccess(eventTemplate.id, dto)
      .subscribe((eventTemplate) => {
        this.onPause.emit(eventTemplate);
      });
  }

  continueTemplate(eventTemplate: EventTemplate) {
    const dto: EventTemplateAccessRequest = {
      restore: true,
    };

    this.eventTemplateService
      .setAccess(eventTemplate.id, dto)
      .subscribe((eventTemplate) => {
        this.onPause.emit(eventTemplate);
      });
  }

  archiveTemplate(eventTemplate: EventTemplate) {
    const dto: EventTemplateAccessRequest = {
      access: EventTemplateAccess.ARCHIVED,
      restore: false,
    };

    this.eventTemplateService
      .setAccess(eventTemplate.id, dto)
      .subscribe((eventTemplate) => {
        this.onPause.emit(eventTemplate);
      });
  }

  restoreTemplate(eventTemplate: EventTemplate) {
    const dto: EventTemplateAccessRequest = {
      restore: true,
    };

    this.eventTemplateService
      .setAccess(eventTemplate.id, dto)
      .subscribe((eventTemplate) => {
        this.onPause.emit(eventTemplate);
      });
  }

  deleteTemplate(eventTemplate: EventTemplate) {
    const question = 'APP.DESIGN_TEMPLATES.DELETE_DIALOG.DELETE_MESSAGE';
    const warning = 'APP.DESIGN_TEMPLATES.DELETE_DIALOG.DELETE_WARNING';
    const cancelButtonMessage =
      'APP.DESIGN_TEMPLATES.DELETE_DIALOG.ACTION_BUTTONS.CANCEL';
    const deleteButtonMessage =
      'APP.DESIGN_TEMPLATES.DELETE_DIALOG.ACTION_BUTTONS.CONFIRM';

    const data: CustomDialogData = {
      isDeleteDialog: true,
      question: question,
      warning: warning,
      id: eventTemplate.id,
      title: eventTemplate.name.toUpperCase(),
      access: eventTemplate?.access,
      relevance: eventTemplate?.relevance,
      performance: {
        views: eventTemplate?.eventTemplateStats.totalViews,
        uses: eventTemplate?.eventTemplateStats.totalUses,
        utr: eventTemplate?.eventTemplateStats.totalUtr,
      },
      cancelButtonMessage: cancelButtonMessage,
      deleteButtonMessage: deleteButtonMessage,
    };

    const dialogRef = this.dialog.open(CustomDialogComponent, {
      maxWidth: '456px',
      width: '100%',
      height: 'auto',
      data: data,
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result === true) {
        this.eventTemplateService.delete(eventTemplate.id).subscribe(() => {
          this.returnToEventTemplateListPage();
        });
      }
    });
  }
}
