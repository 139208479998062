@if (campaign) {
<div class="list-page-container">
  <app-campaign-details-header
    [campaign]="campaign"
    (edited)="onEdited($event)"
  >
  </app-campaign-details-header>

  <div>
    <div class="body-header-info">
      <img
        class="image"
        *ngIf="campaign.logoUrl"
        [src]="campaign.logoUrl"
        alt="Campaign Logo"
      />
      <div class="name">{{ campaign.name }}</div>
    </div>

    <div class="body-subheader-info">
      <div class="subheader">
        {{ "APP.AFFILIATE_PRODUCTS_PAGE_LIST.CREATION_DATE" | translate }} {{
        campaign.createdAt !== undefined && campaign.createdAt.getTime() > 0 ?
        (campaign.createdAt | date: 'dd.MM.yyy') : 'n/a' }}
      </div>
    </div>

    <hr class="hr" />

    <div class="body-chips-info-container">
      <div class="body-chips-info-segments">
        <div class="chips-title">{{ "APP.CAMPAIGNS.URI" | translate }}</div>
        <mat-chip class="chips-value">{{ campaign.uri }}</mat-chip>
      </div>

      <hr class="vr" />

      @if (campaign.preselectedEventCategory) {
      <div class="body-chips-info-segments">
        <div class="chips-title">
          {{ "APP.CAMPAIGNS.PRESELECTED_CATEGORY" | translate }}
        </div>
        <div class="chips-div">
          <mat-chip class="chips-value"
            >{{ campaign.preselectedEventCategory.name }}</mat-chip
          >
        </div>
      </div>

      <hr class="vr" />
      } @if (campaign.preselectedTargetGroup) {
      <div class="body-chips-info-segments">
        <div class="chips-title">
          {{ "APP.CAMPAIGNS.SAVE.PRESELECTED_TARGET_GROUP" | translate }}
        </div>
        <div class="chips-div">
          <mat-chip class="chips-value"
            >{{ campaign.preselectedTargetGroup.name }}</mat-chip
          >
        </div>
      </div>

      <hr class="vr" />
      }

      <div class="body-chips-info-segments">
        <div class="chips-title">
          {{ "APP.AFFILIATE_PRODUCTS_PAGE_LIST.STATUS.TITLE" | translate }}
        </div>
        <mat-chip class="chips-value"
          >{{ getDisplayStatusName(campaign.status) | translate }}</mat-chip
        >
      </div>

      <hr class="vr" />

      <div class="body-chips-info-segments">
        <div class="chips-title">
          {{ "APP.AFFILIATE_PRODUCTS_PAGE_LIST.TABLE_HEADERS.ID" | translate }}
        </div>
        <mat-chip class="chips-value">{{ campaign.id }}</mat-chip>
      </div>
    </div>

    <div>
      <h2 class="details-subheader">Events</h2>
      <app-event-list [campaignId]="campaign.id"></app-event-list>
    </div>
  </div>

  <div class="edit-button-container">
    <button
      (click)="edit()"
      type="button"
      mat-raised-button
      class="actionButton btn-outline-secondary editButton"
    >
      <span class="actions">
        <i class="far fa-pencil"></i>
        {{ 'APP.CAMPAIGNS.EDIT' | translate }}
      </span>
    </button>
  </div>
</div>
}
