<div
  class="select-or-add-cont flex-inputs-cont"
  [class.display-none]="selectedAsset"
>
  <mat-form-field
    appearance="outline"
    class="flex-half flex-input"
    [ngClass]="{
      'ng-invalid mdc-text-field--invalid': invalid
    }"
    (click)="onEditAsset()"
  >
    <input
      type="text"
      placeholder="{{ 'APP.SELECT' | translate }}"
      matInput
      readonly
    />
    <i matSuffix class="fas fa-caret-down autocomplete-dropdown-icon"></i>
  </mat-form-field>
  <div>{{ "APP.OR" | translate }}</div>
  <div class="btn btn-primary" (click)="addAsset($event)">
    {{ "APP.ADD_NEW" | translate }}
  </div>
</div>

@if (selectedAsset) {
  <app-image-preview
    [selectedFile]="mainAsset"
    (deleteFile)="onDeleteAsset()"
    (editFile)="onEditAsset()"
  ></app-image-preview>
}
