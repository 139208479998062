import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { AffiliateProduct } from '@models/affiliate-products/affiliate-product.model';
import { AffiliateProductStatus } from '@models/affiliate-products/affiliate-product-status.enum';
import { AffiliateProductsService } from '@services/affiliate-products.service';
import { MatDialog } from '@angular/material/dialog';
import { TranslateModule } from '@ngx-translate/core';
import { MatChipsModule } from '@angular/material/chips';
import { AffiliateProductDetailsHeaderComponent } from '@modules/admin/affiliates/components/affiliate-product-details-header/affiliate-product-details-header.component';
import { AffiliateProductPartner } from '@models/affiliate-products/affiliate-product-partner.enum';
import { MatButtonModule } from '@angular/material/button';
import { AffiliateProductOverlayPageComponent } from '@modules/admin/affiliates/dialogs/affiliate-product-overlay-page/affiliate-product-overlay-page.component';

@Component({
  selector: 'app-affiliate-product-details',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    MatChipsModule,
    AffiliateProductDetailsHeaderComponent,
    MatButtonModule,
  ],
  providers: [AffiliateProductsService],
  templateUrl: './affiliate-product-details.page.html',
  styleUrl: './affiliate-product-details.page.scss',
})
export class AffiliateProductDetailsPage implements OnInit {
  product?: AffiliateProduct;
  createdAt: Date | undefined;

  constructor(
    private route: ActivatedRoute,
    private affiliateProductsService: AffiliateProductsService,
    private dialog: MatDialog,
  ) {}

  ngOnInit() {
    const productId = this.route.snapshot.params['id'];

    this.affiliateProductsService
      .getProduct(productId)
      .subscribe((affiliateProduct) => {
        this.product = affiliateProduct;
        this.createdAt = this.product.createdAt;
      });
  }

  getDisplayStatusName(status?: AffiliateProductStatus): string {
    return AffiliateProductStatus.getDisplayName(status);
  }

  getDisplayNetworkName(network?: AffiliateProductPartner): string {
    return AffiliateProductPartner.getDisplayName(network);
  }

  onPause(event: AffiliateProduct) {
    this.product = event;
  }

  onContinue(event: AffiliateProduct) {
    this.product = event;
  }

  onArchive(event: AffiliateProduct) {
    this.product = event;
  }

  onRestore(event: AffiliateProduct) {
    this.product = event;
  }

  editProduct() {
    const dialogRef = this.dialog.open(AffiliateProductOverlayPageComponent, {
      width: '100%',
      height: '100%',
      maxWidth: '100%',
      panelClass: 'overlay-page-dialog',
      data: {
        affiliateProduct: this.product,
        isEdit: true,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result.refresh) {
        this.product = result.affiliateProduct;
        this.createdAt = result.affiliateProduct?.createdAt;
      }
    });
  }
}
