import { CollectionViewer, DataSource } from '@angular/cdk/collections';
import { AffiliateProduct } from '@models/affiliate-products/affiliate-product.model';
import {
  asapScheduler,
  BehaviorSubject,
  finalize,
  Observable,
  scheduled,
} from 'rxjs';
import { AffiliateProductsService } from '@services/affiliate-products.service';
import { catchError } from 'rxjs/operators';
import { PageListingResponse } from '@models/api/page-listing-response.model';

export class AffiliateProductDataSource
  implements DataSource<AffiliateProduct>
{
  private affiliateProductSubjects = new BehaviorSubject<AffiliateProduct[]>(
    [],
  );
  private loadingSubject = new BehaviorSubject<boolean>(false);
  private numRecordsSubject = new BehaviorSubject<number>(0);

  numRecords$ = this.numRecordsSubject.asObservable();
  loading$ = this.loadingSubject.asObservable();

  data!: AffiliateProduct[];

  constructor(private affiliateProductService: AffiliateProductsService) {}
  connect(collectionViewer: CollectionViewer): Observable<AffiliateProduct[]> {
    return this.affiliateProductSubjects.asObservable();
  }

  disconnect(collectionViewer: CollectionViewer): void {
    this.affiliateProductSubjects.complete();
    this.numRecordsSubject.complete();
    this.loadingSubject.complete();
  }

  loadAffiliateProducts(
    filters: Map<string, any>,
    pageNumber = 1,
    pageSize = 10,
  ) {
    this.loadingSubject.next(true);

    this.affiliateProductService
      .getProducts(filters, pageNumber, pageSize)
      .pipe(
        catchError(() => {
          this.data = [];
          return scheduled([], asapScheduler);
        }),
        finalize(() => this.loadingSubject.next(false)),
      )
      .subscribe((res: PageListingResponse<AffiliateProduct>) => {
        this.affiliateProductSubjects.next(res.records);
        this.numRecordsSubject.next(res.total);
        this.data = res.records;
      });
  }
}
