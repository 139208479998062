<mat-sidenav-container
  #container
  [ngClass]="
    isPublicRoute && !isTemplatesOverviewPage
      ? 'white-background'
      : 'internal-background'
  "
  [class.create-event-page]="isCreateEventPage"
>
  @if (!isPageWithoutSideItems) {
    <mat-sidenav
      #sidenav
      class="internal-mat-sidenav"
      [class.customer-view]="isCustomer"
      [class.closed]="!sidenav.opened"
      [mode]="isSideMode ? 'side' : 'over'"
      position="start"
    >
      <app-internal-sidenav
        [routes]="internalRoutes"
        [isOverMode]="!isSideMode"
        [isCustomerView]="isCustomer"
        (openReferralInviteDialog)="openReferralInviteDialog()"
      >
      </app-internal-sidenav>
    </mat-sidenav>
  }

  <mat-sidenav-content #sidenavContent>
    @if (isPageWithGenericHeader && !isCreateEventPage) {
      <app-header></app-header>
    }
    @if (
      isAdmin &&
      !isPublicRoute &&
      !isEventPage &&
      !isCampaignEventVotingPage &&
      !isLandingPage
    ) {
      <app-internal-header></app-internal-header>
    }
    @if (
      isCustomer &&
      !isPublicRoute &&
      !isEventPage &&
      !isCampaignEventVotingPage &&
      !isLandingPage &&
      !isTermsOfUsePage &&
      !isDesignTemplatePreviewPage
    ) {
      <app-internal-customer-header
        [routes]="internalRoutes"
        [isLowerZIndexPage]="isLinkBioPage"
        (openReferralInviteDialog)="openReferralInviteDialog()"
      ></app-internal-customer-header>
    }
    <div
      class="sticky-footer theme-background"
      [class.internal]="isAdminOrCustomer && !isPublicRoute"
      [class.internal-no-header]="
        (isDesktop && isAdminOrCustomer && !isPublicRoute) ||
        isEventPage ||
        isCampaignEventVotingPage ||
        isDesignTemplatePreviewPage ||
        isLinkBioPage ||
        isLandingPage ||
        isTermsOfUsePage ||
        isEventPayment
      "
      [class.public]="!isCustomer && !isAdmin"
      [class.pt-0]="isPublicDesignTemplatesRoute"
      [class.h-100]="isTemplatesOverviewPage"
      style="flex: 1 0 auto !important"
    >
      <router-outlet></router-outlet>
    </div>

    @if (isPageWithGenericFooter) {
      <app-footer class="app-footer"></app-footer>
    }
  </mat-sidenav-content>
</mat-sidenav-container>
