import { Component, Inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TemplateAssetPickerComponent } from '@modules/shared/components/template-asset-picker/template-asset-picker.component';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateModule } from '@ngx-translate/core';
import { EventCategory } from '@models/event-categories/event-category.model';
import { EventTemplateAsset } from '@models/events/event-template-asset.model';
import { EventTemplateAssetType } from '@models/events/event-template-asset-type.enum';

@Component({
  standalone: true,
  imports: [CommonModule, TranslateModule, TemplateAssetPickerComponent],
  templateUrl: './template-asset-picker.dialog.html',
  styleUrl: './template-asset-picker.dialog.scss',
})
export class TemplateAssetPickerDialog {
  type: EventTemplateAssetType;
  mainCategories: EventCategory[] = [];
  selectedAsset?: EventTemplateAsset;

  constructor(
    public dialogRef: MatDialogRef<TemplateAssetPickerDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.type = data.type;
    this.mainCategories = data.mainCategories;
    this.selectedAsset = data.selectedAsset;
  }

  onAssetChosen(asset?: EventTemplateAsset) {
    if (asset) {
      this.dialogRef.close({ asset: asset });
    }
  }

  onClose() {
    this.dialogRef.close();
  }
}
