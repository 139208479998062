import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatMenuModule } from '@angular/material/menu';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { AppRoutes } from '../../../../../routes';
import { CustomDialogData } from '@models/overlay-dialogs/custom-dialog-data.model';
import { CustomDialogComponent } from '@modules/shared/dialogs/custom-dialog/custom-dialog.component';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { CampaignService } from '@services/campaign.service';
import { Campaign } from '@models/campaigns/campaign.model';
import { CampaignStatus } from '@models/campaigns/campaign-status.enum';
import { NotificationService } from '@services/shared/notification.service';
import { GhostVotesRequest } from '@models/campaigns/dto/ghost-votes.request';

@Component({
  selector: 'app-campaign-details-header',
  standalone: true,
  imports: [CommonModule, MatMenuModule, TranslateModule],
  providers: [NotificationService, CampaignService],
  templateUrl: './campaign-details-header.component.html',
  styleUrl: './campaign-details-header.component.scss',
})
export class CampaignDetailsHeaderComponent {
  @Input() campaign?: Campaign;

  @Output() edited: EventEmitter<Campaign> = new EventEmitter<Campaign>();

  constructor(
    private router: Router,
    private campaignService: CampaignService,
    private dialog: MatDialog,
    private notificationService: NotificationService,
    private translateService: TranslateService,
  ) {}

  backToList() {
    this.router.navigate([
      '/',
      AppRoutes.Admin.root,
      AppRoutes.Admin.Campaigns.root,
    ]);
  }

  sendGhostVotes(campaignId: number) {
    const req: GhostVotesRequest = {
      campaignId: campaignId,
    };
    this.campaignService.sendGhostVotes(req).subscribe({
      next: () => {
        this.notificationService.success(
          this.translateService.instant('APP.VOTING.YOU_HAVE_GHOST_VOTED'),
        );
      },
    });
  }

  setStatus(campaignId: number, status: CampaignStatus) {
    this.campaignService.setStatus(campaignId, status).subscribe((campaign) => {
      this.edited.emit(campaign);
    });
  }

  deleteCampaign(campaign: Campaign) {
    const question = 'APP.CAMPAIGNS.DELETE.MESSAGE';
    const cancelButtonMessage = 'APP.CAMPAIGNS.DELETE.CANCEL';
    const deleteButtonMessage = 'APP.CAMPAIGNS.DELETE.CONFIRM';

    const data: CustomDialogData = {
      isDeleteDialog: true,
      question: question,
      id: campaign.id,
      title: campaign.name.toUpperCase(),
      pictureUrl: campaign.logoUrl,
      cancelButtonMessage: cancelButtonMessage,
      deleteButtonMessage: deleteButtonMessage,
    };

    const dialogRef = this.dialog.open(CustomDialogComponent, {
      maxWidth: '456px',
      width: '100%',
      height: 'auto',
      data: data,
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result === true) {
        this.campaignService.delete(campaign.id).subscribe(() => {
          this.backToList();
        });
      }
    });
  }

  protected readonly CampaignStatus = CampaignStatus;
}
