<div class="overlay-page overlay-theme">
  <div class="header">
    <div class="flex-left-placeholder"></div>
    <h1 mat-dialog-title class="title" *ngIf="!isEdit">
      {{ "APP.CAMPAIGNS.SAVE.CREATE_TITLE" | translate }}
    </h1>
    <h1 mat-dialog-title class="title" *ngIf="isEdit">
      {{ "APP.CAMPAIGNS.SAVE.EDIT_TITLE" | translate }}
    </h1>
    <i class="fal fa-times-circle" (click)="cancel()"></i>
  </div>

  <div class="body-info">
    @if (form) {
      <form [formGroup]="form">
        <div>
          <h2 class="body-header">
            {{
              "APP.AFFILIATE_PRODUCTS_PAGE_LIST.CREATE_OR_EDIT_PRODUCT.BASIC_INFORMATION.TITLE"
                | translate
            }}*
          </h2>
          <div class="flex-inputs-cont basic-info">
            <mat-form-field
              class="flex-half-shrink flex-input"
              appearance="outline"
            >
              <mat-label class="placeholder">{{
                "APP.CAMPAIGNS.SAVE.NAME" | translate
              }}</mat-label>
              <input
                type="text"
                matInput
                formControlName="name"
                placeholder=""
              />
              @if (form.controls["name"].hasError("required")) {
                <mat-error>
                  {{ "APP.CAMPAIGNS.ERRORS.NAME_REQUIRED" | translate }}
                </mat-error>
              }
            </mat-form-field>

            <mat-form-field
              class="flex-half-shrink flex-input"
              appearance="outline"
            >
              <mat-label class="placeholder">{{
                "APP.CAMPAIGNS.SAVE.STATUS" | translate
              }}</mat-label>
              <mat-select formControlName="status">
                <mat-option
                  *ngFor="let option of campaignStatuses"
                  [value]="option"
                >
                  {{ getDisplayStatus(option) | translate }}
                </mat-option>
              </mat-select>
              @if (form.controls["status"].hasError("required")) {
                <mat-error>
                  {{ "APP.CAMPAIGNS.ERRORS.STATUS_REQUIRED" | translate }}
                </mat-error>
              }
            </mat-form-field>
          </div>

          <mat-form-field class="w-100" appearance="outline">
            <mat-label class="placeholder">{{
              "APP.CAMPAIGNS.URI" | translate
            }}</mat-label>
            <input type="text" matInput formControlName="uri" placeholder="" />
            @if (form.controls["uri"].hasError("required")) {
              <mat-error>
                {{ "APP.CAMPAIGNS.ERRORS.URI_REQUIRED" | translate }}
              </mat-error>
            }
          </mat-form-field>

          <div class="flex-inputs-cont basic-info">
            <mat-form-field
              class="flex-half-shrink flex-input"
              appearance="outline"
            >
              <mat-label>{{
                "APP.CAMPAIGNS.SAVE.DATES" | translate
              }}</mat-label>
              <mat-date-range-input [rangePicker]="presetPicker">
                <input
                  matStartDate
                  placeholder="DD / MM / YYYY "
                  formControlName="startDate"
                />
                <input
                  matEndDate
                  placeholder="End date"
                  formControlName="endDate"
                />
              </mat-date-range-input>
              <mat-datepicker-toggle
                matIconSuffix
                [for]="presetPicker"
              ></mat-datepicker-toggle>
              <mat-date-range-picker #presetPicker></mat-date-range-picker>
            </mat-form-field>

            <mat-form-field
              class="flex-half-shrink flex-input"
              appearance="outline"
            >
              <mat-label class="placeholder">{{
                "APP.CAMPAIGNS.SAVE.DAYS_IN_FUTURE" | translate
              }}</mat-label>
              <input
                type="number"
                min="0"
                matInput
                formControlName="daysInFuture"
                placeholder=""
              />
            </mat-form-field>
          </div>

          <mat-form-field class="half-field" appearance="outline">
            <mat-label class="placeholder">{{
              "APP.CAMPAIGNS.SAVE.MIN_GUESTS" | translate
            }}</mat-label>
            <input
              type="number"
              min="0"
              matInput
              formControlName="minGuests"
              placeholder=""
            />
          </mat-form-field>
        </div>

        <h2 class="body-header">
          {{ "APP.CAMPAIGNS.SAVE.LOGO" | translate }}*
        </h2>
        <div class="file-container">
          <app-file-uploader
            [fluidWidth]="true"
            [useImagePreviewComponent]="true"
            [uploaderType]="'IMAGE'"
            [selectedFile]="form.controls['logoAsset'].value"
            [invalid]="
              form.controls['logoAsset'].touched &&
              form.controls['logoAsset'].invalid
            "
            (fileChanged)="onAssetChanged($event, 'logoAsset')"
          >
          </app-file-uploader>
        </div>

        <div class="body-header-cont">
          <h2 class="body-header">
            {{ "APP.CAMPAIGNS.SAVE.DARK_MODE_LOGO" | translate }}
          </h2>
          <div class="body-header-info">
            ({{ "APP.CAMPAIGNS.SAVE.LOGO_USED_IF_NOT_SET" | translate }})
          </div>
        </div>

        <div class="file-container">
          <app-file-uploader
            [fluidWidth]="true"
            [useImagePreviewComponent]="true"
            [uploaderType]="'IMAGE'"
            [selectedFile]="form.controls['darkModeLogoAsset'].value"
            [invalid]="
              form.controls['darkModeLogoAsset'].touched &&
              form.controls['darkModeLogoAsset'].invalid
            "
            (fileChanged)="onAssetChanged($event, 'darkModeLogoAsset')"
          >
          </app-file-uploader>
        </div>

        <div>
          <h2 class="body-header">
            {{ "APP.CAMPAIGNS.SAVE.CREATION_PROCESS" | translate }}*
          </h2>

          <div class="body-header-cont">
            <h3 class="body-subheader">
              {{ "APP.CAMPAIGNS.SAVE.WELCOME_SCREEN_IMAGE" | translate }}
            </h3>
            <div class="body-header-info">
              ({{ "APP.CAMPAIGNS.SAVE.LOGO_USED_IF_NOT_SET" | translate }})
            </div>
          </div>
          <div class="file-container">
            <app-file-uploader
              [fluidWidth]="true"
              [useImagePreviewComponent]="true"
              [uploaderType]="'IMAGE'"
              [selectedFile]="form.controls['welcomeScreenImageAsset'].value"
              [invalid]="
                form.controls['welcomeScreenImageAsset'].touched &&
                form.controls['welcomeScreenImageAsset'].invalid
              "
              (fileChanged)="onAssetChanged($event, 'welcomeScreenImageAsset')"
            >
            </app-file-uploader>
          </div>

          <mat-form-field class="w-100" appearance="outline">
            <mat-label class="placeholder">{{
              "APP.CAMPAIGNS.SAVE.WELCOME_SCREEN_TITLE" | translate
            }}</mat-label>
            <input
              type="text"
              matInput
              formControlName="welcomeScreenTitle"
              placeholder=""
            />
          </mat-form-field>
          <mat-form-field class="w-100" appearance="outline">
            <mat-label class="placeholder">{{
              "APP.CAMPAIGNS.SAVE.WELCOME_SCREEN_SUBTITLE" | translate
            }}</mat-label>
            <input
              type="text"
              matInput
              formControlName="welcomeScreenSubtitle"
              placeholder=""
            />
          </mat-form-field>
          <mat-form-field appearance="outline" class="w-100">
            <mat-label class="placeholder">{{
              "APP.CAMPAIGNS.SAVE.WELCOME_SCREEN_TEXT" | translate
            }}</mat-label>
            <textarea
              cdkTextareaAutosize
              matInput
              formControlName="welcomeScreenText"
              rows="12"
            ></textarea>
          </mat-form-field>

          <!-- <mat-form-field class="w-100" appearance="outline">
            <mat-label class="placeholder">{{
              "APP.CAMPAIGNS.SAVE.ENTERING_SCREEN_TITLE" | translate
            }}</mat-label>
            <input
              type="text"
              matInput
              formControlName="enteringScreenTitle"
              placeholder=""
            />
          </mat-form-field> -->

          <!-- <mat-form-field appearance="outline" class="w-100">
            <mat-label class="placeholder">{{
              "APP.CAMPAIGNS.SAVE.WELCOME_SCREEN_HTML" | translate
            }}</mat-label>
            <textarea
              cdkTextareaAutosize
              matInput
              formControlName="welcomeScreenHtml"
              rows="12"
            ></textarea>
          </mat-form-field> -->
        </div>

        <h2 class="body-header">
          {{ "APP.CAMPAIGNS.SAVE.CHOOSE_PRESELECTED_CATEGORY" | translate }}
        </h2>
        <app-autocomplete-select
          [form]="form"
          selectedFormControlName="category"
          [items]="categories"
          labelProperty="title"
          valueProperty="id"
          [useValueProperty]="true"
        >
        </app-autocomplete-select>

        <h2 class="body-header">
          {{ "APP.CAMPAIGNS.SAVE.CHOOSE_PRESELECTED_SUBCATEGORY" | translate }}
        </h2>
        <app-autocomplete-select
          [form]="form"
          selectedFormControlName="subcategory"
          [items]="subcategories"
          labelProperty="title"
          valueProperty="id"
          [useValueProperty]="true"
        >
        </app-autocomplete-select>

        <h2 class="body-header">
          {{ "APP.CAMPAIGNS.SAVE.PRESELECTED_TARGET_GROUP" | translate }}
        </h2>
        <app-autocomplete-select
          [form]="form"
          selectedFormControlName="targetGroup"
          [items]="targetGroups"
          labelProperty="name"
          valueProperty="id"
          [useValueProperty]="true"
        >
        </app-autocomplete-select>

        <h2 class="body-header">
          {{ "APP.CAMPAIGNS.SAVE.BREVO_LIST_IDS" | translate }}
        </h2>
        <div class="flex-inputs-cont">
          <mat-form-field
            appearance="outline"
            class="flex-half-shrink flex-input"
          >
            <mat-label>{{
              "APP.CAMPAIGNS.SAVE.ALL_HOSTS" | translate
            }}</mat-label>
            <input
              matInput
              type="number"
              formControlName="allCampaignUsersListId"
            />
          </mat-form-field>
          <mat-form-field
            appearance="outline"
            class="flex-half-shrink flex-input"
          >
            <mat-label>{{
              "APP.CAMPAIGNS.SAVE.ALL_GUESTS" | translate
            }}</mat-label>
            <input
              matInput
              type="number"
              formControlName="campaignAdsUsersListId"
            />
          </mat-form-field>
        </div>

        <div class="toggle-option-same-line">
          <h2 class="body-header">
            {{
              "APP.CAMPAIGNS.SAVE.SETTINGS.ACCOUNT_CREATION_FOR_VOTING"
                | translate
            }}
          </h2>
          <div>
            <label class="toggle-check">
              <input
                type="checkbox"
                class="toggle-check-input"
                formControlName="accountCreationForVoting"
              />
              <span class="toggle-check-text"></span>
            </label>
          </div>
        </div>
      </form>
    }
  </div>

  <div class="floating-button-container">
    <button
      *ngIf="!isEdit"
      (click)="createOrUpdate()"
      type="button"
      mat-raised-button
      class="actionButton btn btn-primary addButton"
    >
      {{ "APP.CAMPAIGNS.SAVE.CREATE_ACTION" | translate }}
    </button>

    <button
      *ngIf="isEdit"
      (click)="createOrUpdate()"
      type="button"
      mat-raised-button
      class="actionButton btn btn-primary editButton"
    >
      {{ "APP.CAMPAIGNS.SAVE.UPDATE_ACTION" | translate }}
    </button>
  </div>
</div>
