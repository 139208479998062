import { CollectionViewer, DataSource } from '@angular/cdk/collections';
import {
  asapScheduler,
  BehaviorSubject,
  finalize,
  Observable,
  scheduled,
} from 'rxjs';
import { catchError } from 'rxjs/operators';
import { PageListingResponse } from '@models/api/page-listing-response.model';
import { Campaign } from '@models/campaigns/campaign.model';
import { CampaignService } from '@services/campaign.service';

export class CampaignDataSource implements DataSource<Campaign> {
  private campaignsSubject = new BehaviorSubject<Campaign[]>([]);
  private loadingSubject = new BehaviorSubject<boolean>(false);
  private numRecordsSubject = new BehaviorSubject<number>(0);

  numRecords$ = this.numRecordsSubject.asObservable();
  loading$ = this.loadingSubject.asObservable();

  data!: Campaign[];

  constructor(private campaignService: CampaignService) {}
  connect(_: CollectionViewer): Observable<Campaign[]> {
    return this.campaignsSubject.asObservable();
  }

  disconnect(_: CollectionViewer): void {
    this.campaignsSubject.complete();
    this.numRecordsSubject.complete();
    this.loadingSubject.complete();
  }

  loadCampaigns(filters: Map<string, any>, pageNumber = 1, pageSize = 10) {
    this.loadingSubject.next(true);

    this.campaignService
      .getPaged(filters, pageNumber, pageSize)
      .pipe(
        catchError(() => {
          this.data = [];
          return scheduled([], asapScheduler);
        }),
        finalize(() => this.loadingSubject.next(false)),
      )
      .subscribe((res: PageListingResponse<Campaign>) => {
        this.campaignsSubject.next(res.records);
        this.numRecordsSubject.next(res.total);
        this.data = res.records;
      });
  }
}
