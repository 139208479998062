import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { TranslateModule } from '@ngx-translate/core';
import { MatChipsModule } from '@angular/material/chips';
import { MatButtonModule } from '@angular/material/button';
import { CampaignService } from '@services/campaign.service';
import { Campaign } from '@models/campaigns/campaign.model';
import { CampaignOverlayPageComponent } from '../../dialogs/campaign-overlay-page/campaign-overlay-page.component';
import { CampaignStatus } from '@models/campaigns/campaign-status.enum';
import { CampaignDetailsHeaderComponent } from '../../components/campaign-details-header/campaign-details-header.component';
import { EventListComponent } from '@modules/admin/events/components/event-list/event-list.component';

@Component({
  selector: 'app-campaign-details',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    MatChipsModule,
    CampaignDetailsHeaderComponent,
    MatButtonModule,
    EventListComponent,
  ],
  providers: [CampaignService],
  templateUrl: './campaign-details.page.html',
  styleUrl: './campaign-details.page.scss',
})
export class CampaignDetailsPage {
  campaign?: Campaign;

  constructor(
    private route: ActivatedRoute,
    private campaignService: CampaignService,
    private dialog: MatDialog,
  ) {}

  ngOnInit() {
    const campaignUri = this.route.snapshot.params['uri'];

    this.campaignService.getByUri(campaignUri).subscribe((campaign) => {
      this.campaign = campaign;
    });
  }

  getDisplayStatusName(status: CampaignStatus): string {
    return CampaignStatus.getDisplayName(status);
  }

  onEdited(campaign: Campaign) {
    this.campaign = campaign;
  }

  edit() {
    const dialogRef = this.dialog.open(CampaignOverlayPageComponent, {
      width: '100%',
      height: '100%',
      maxWidth: '100%',
      panelClass: 'overlay-page-dialog',
      data: {
        campaign: this.campaign,
        isEdit: true,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result.refresh) {
        this.campaign = result.campaign;
      }
    });
  }
}
