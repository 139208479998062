<div class="asset-picker-dialog event-dialog-content">
  <div class="dialog-body">
    <div class="dialog-title">
      {{ "APP.TEMPLATE_ASSETS.PICKER.TITLE" | translate }}
    </div>
    <div (click)="onClose()" class="close-button">
      <i class="fal fa-times-circle"></i>
    </div>

    <app-template-asset-picker
      [type]="type"
      [categories]="mainCategories"
      [selectedAssetId]="selectedAsset?.id"
      (assetChosen)="onAssetChosen($event)"
    ></app-template-asset-picker>
  </div>
</div>
