import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormGroup } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModules } from '@utils/shared-modules';
import { WidgetsSelectComponent } from '@modules/shared/components/widgets-select/widgets-select.component';
import { EventWidget } from '@models/events/event-widget.enum';

@Component({
  selector: 'app-template-widgets-and-kits-step',
  standalone: true,
  imports: [
    CommonModule,
    FormsModules,
    TranslateModule,
    WidgetsSelectComponent,
  ],
  templateUrl: './template-widgets-and-kits-step.component.html',
  styleUrl: './template-widgets-and-kits-step.component.scss',
})
export class TemplateWidgetsAndKitsStepComponent {
  @Input() form!: FormGroup;

  widgets = EventWidget.getAllEventWidgets();
}
