<div class="list-page-container">
  <div>
    <h1 class="page-header">
      {{ "APP.DESIGN_TEMPLATES.LIST_HEADER" | translate }}
    </h1>

    <hr class="hr" />
  </div>

  <div>
    <div>
      <app-filter
        [isEventTemplatesListPage]="true"
        (filterChange)="onFilterChange($event)"
      ></app-filter>
    </div>

    <div class="table-scrollable" [class.loading]="dataSource.loading$ | async">
      <mat-table matSort [dataSource]="dataSource" class="mat-elevation-z8">
        <!-- Add this checkbox column definition inside the table -->
        <ng-container matColumnDef="select">
          <mat-header-cell *matHeaderCellDef>
            <mat-checkbox (change)="masterToggle()"></mat-checkbox>
          </mat-header-cell>
          <mat-cell *matCellDef="let row">
            <mat-checkbox (change)="toggleSelection(row)"></mat-checkbox>
          </mat-cell>
        </ng-container>

        <!-- Name Column -->
        <ng-container matColumnDef="name">
          <mat-header-cell mat-sort-header *matHeaderCellDef>{{
            "APP.DESIGN_TEMPLATES.TABLE_HEADERS.NAME" | translate
          }}</mat-header-cell>
          <mat-cell *matCellDef="let eventTemplate">
            <div class="name-and-picture-col">
              <img
                *ngIf="eventTemplate?.visual?.thumbnailUrl"
                [src]="eventTemplate?.visual?.thumbnailUrl"
                alt="Product Image"
              />
              <div class="ellipsis-field">
                {{ eventTemplate?.getNameWFallback() }}
              </div>
            </div>
          </mat-cell>
        </ng-container>

        <!-- ID Column -->
        <ng-container matColumnDef="id">
          <mat-header-cell mat-sort-header *matHeaderCellDef>{{
            "APP.DESIGN_TEMPLATES.TABLE_HEADERS.ID" | translate
          }}</mat-header-cell>
          <mat-cell *matCellDef="let eventTemplate">{{
            eventTemplate?.id
          }}</mat-cell>
        </ng-container>

        <!-- Access Column -->
        <ng-container matColumnDef="access">
          <mat-header-cell mat-sort-header *matHeaderCellDef>{{
            "APP.DESIGN_TEMPLATES.TABLE_HEADERS.STATUS" | translate
          }}</mat-header-cell>
          <mat-cell *matCellDef="let eventTemplate">{{
            getDisplayStatus(eventTemplate?.access) | translate
          }}</mat-cell>
        </ng-container>

        <!-- Relevance Column -->
        <ng-container matColumnDef="relevance">
          <mat-header-cell mat-sort-header *matHeaderCellDef>{{
            "APP.DESIGN_TEMPLATES.TABLE_HEADERS.RELEVANCE" | translate
          }}</mat-header-cell>
          <mat-cell *matCellDef="let eventTemplate"
            ><div class="ellipsis-field">
              {{ getDisplayRelevance(eventTemplate?.relevance) | translate }}
            </div></mat-cell
          >
        </ng-container>

        <!-- Creation Date Column -->
        <ng-container matColumnDef="creationDate">
          <mat-header-cell mat-sort-header *matHeaderCellDef>{{
            "APP.DESIGN_TEMPLATES.TABLE_HEADERS.CREATION_DATE" | translate
          }}</mat-header-cell>
          <mat-cell *matCellDef="let eventTemplate">{{
            eventTemplate?.createdAt.getTime() > 0
              ? (eventTemplate?.createdAt | date: "dd.MM.yyy")
              : "n/a"
          }}</mat-cell>
        </ng-container>

        <!-- Category Column -->
        <ng-container matColumnDef="category">
          <mat-header-cell mat-sort-header *matHeaderCellDef>{{
            "APP.DESIGN_TEMPLATES.TABLE_HEADERS.CATEGORY" | translate
          }}</mat-header-cell>
          <mat-cell *matCellDef="let eventTemplate">{{
            getEventCategoryNames(eventTemplate?.eventCategories)
          }}</mat-cell>
        </ng-container>

        <!-- Subcategory Column -->
        <ng-container matColumnDef="subcategory">
          <mat-header-cell mat-sort-header *matHeaderCellDef>{{
            "APP.DESIGN_TEMPLATES.TABLE_HEADERS.SUBCATEGORY" | translate
          }}</mat-header-cell>
          <mat-cell *matCellDef="let eventTemplate">{{
            getEventSubcategoryNames(eventTemplate?.eventSubcategories)
          }}</mat-cell>
        </ng-container>

        <!-- Language Support Column -->
        <ng-container matColumnDef="language">
          <mat-header-cell mat-sort-header *matHeaderCellDef>{{
            "APP.DESIGN_TEMPLATES.TABLE_HEADERS.LANGUAGE" | translate
          }}</mat-header-cell>
          <mat-cell *matCellDef="let eventTemplate">
            <ng-container
              *ngFor="let language of eventTemplate?.getLanguages()"
            >
              <span class="language-span" (click)="$event.stopPropagation()">
                <span
                  (click)="
                    editEventTemplateForLanguage(language, eventTemplate)
                  "
                >
                  {{ getTranslationLanguageNames(language) }}
                  <i class="far fa-pencil mirror-icon"></i>
                </span>
              </span>
            </ng-container>
          </mat-cell>
        </ng-container>

        <!-- Views Column -->
        <ng-container matColumnDef="views">
          <mat-header-cell mat-sort-header *matHeaderCellDef>{{
            "APP.DESIGN_TEMPLATES.TABLE_HEADERS.VIEWS" | translate
          }}</mat-header-cell>
          <mat-cell *matCellDef="let eventTemplate">{{
            eventTemplate?.eventTemplateStats?.totalViews
          }}</mat-cell>
        </ng-container>

        <!-- Clicks Column -->
        <ng-container matColumnDef="clicks">
          <mat-header-cell mat-sort-header *matHeaderCellDef>{{
            "APP.DESIGN_TEMPLATES.TABLE_HEADERS.USED" | translate
          }}</mat-header-cell>
          <mat-cell *matCellDef="let eventTemplate">{{
            eventTemplate?.eventTemplateStats?.totalUses
          }}</mat-cell>
        </ng-container>

        <!-- CTR Column -->
        <ng-container matColumnDef="ctr">
          <mat-header-cell mat-sort-header *matHeaderCellDef>{{
            "APP.DESIGN_TEMPLATES.TABLE_HEADERS.CR" | translate
          }}</mat-header-cell>
          <mat-cell *matCellDef="let eventTemplate"
            >{{ eventTemplate?.eventTemplateStats?.totalUtr }}%</mat-cell
          >
        </ng-container>

        <!-- Actions Column -->
        <ng-container matColumnDef="actions" stickyEnd>
          <mat-header-cell *matHeaderCellDef>{{
            "APP.DESIGN_TEMPLATES.TABLE_HEADERS.ACTIONS" | translate
          }}</mat-header-cell>
          <mat-cell *matCellDef="let eventTemplate">
            <button
              mat-icon-button
              [matMenuTriggerFor]="menu"
              class="more-vert-rotate-90"
              (click)="$event.stopPropagation()"
            >
              <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #menu="matMenu" class="table-actions-container">
              <!--          <button mat-menu-item (click)="editProduct(product)">Edit</button>-->
              <button mat-menu-item (click)="copyTemplateId(eventTemplate.id)">
                <span class="menu-item-content">
                  <i class="far fa-copy"></i>
                  <span class="text">{{
                    "APP.DESIGN_TEMPLATES.ACTIONS.COPY_EVENT_TEMPLATE"
                      | translate
                  }}</span>
                </span>
              </button>

              <button
                mat-menu-item
                (click)="duplicateTemplate(eventTemplate.id)"
              >
                <span class="menu-item-content">
                  <i class="far fa-copy"></i>
                  <span class="text">{{ "APP.DUPLICATE" | translate }}</span>
                </span>
              </button>

              <button
                mat-menu-item
                (click)="copyTemplateLink(eventTemplate.id)"
              >
                <span class="menu-item-content">
                  <i class="far fa-link"></i>
                  <span class="text">{{
                    "APP.DESIGN_TEMPLATES.ACTIONS.COPY_LINK" | translate
                  }}</span>
                </span>
              </button>

              @if (eventTemplate.isPublic()) {
                <button
                  mat-menu-item
                  (click)="copyTemplatePublicUrl(eventTemplate)"
                >
                  <span class="menu-item-content">
                    <i class="far fa-link"></i>
                    <span class="text">{{
                      "APP.DESIGN_TEMPLATES.ACTIONS.PUBLIC_URL" | translate
                    }}</span>
                  </span>
                </button>

                <button
                  mat-menu-item
                  (click)="getEventTemplateQRCodeUrl(eventTemplate)"
                >
                  <span class="menu-item-content">
                    <i class="far fa-qrcode"></i>
                    <span class="text">{{
                      "APP.DOWNLOAD_QR_CODE" | translate
                    }}</span>
                  </span>
                </button>
              }

              <button
                mat-menu-item
                (click)="pauseTemplate(eventTemplate)"
                *ngIf="
                  eventTemplate?.access !== 'INACTIVE' &&
                  eventTemplate?.access !== 'ARCHIVED'
                "
              >
                <span class="menu-item-content">
                  <i class="far fa-pause"></i>
                  <span class="text">{{
                    "APP.DESIGN_TEMPLATES.ACTIONS.PAUSE" | translate
                  }}</span>
                </span>
              </button>

              <button
                mat-menu-item
                (click)="continueTemplate(eventTemplate)"
                *ngIf="eventTemplate?.access === 'INACTIVE'"
              >
                <span class="menu-item-content">
                  <i class="far fa-play"></i>
                  <span class="text">{{
                    "APP.DESIGN_TEMPLATES.ACTIONS.CONTINUE" | translate
                  }}</span>
                </span>
              </button>

              <button
                mat-menu-item
                (click)="archiveTemplate(eventTemplate)"
                *ngIf="
                  eventTemplate?.access !== 'INACTIVE' &&
                  eventTemplate?.access !== 'ARCHIVED'
                "
              >
                <span class="menu-item-content">
                  <i class="far fa-archive"></i>
                  <span class="text">{{
                    "APP.DESIGN_TEMPLATES.ACTIONS.ARCHIVE" | translate
                  }}</span>
                </span>
              </button>

              <button
                mat-menu-item
                (click)="restoreTemplate(eventTemplate)"
                *ngIf="eventTemplate?.access === 'ARCHIVED'"
              >
                <span class="menu-item-content">
                  <i class="far fa-box-open"></i>
                  <span class="text">{{
                    "APP.DESIGN_TEMPLATES.ACTIONS.RESTORE" | translate
                  }}</span>
                </span>
              </button>

              <button
                mat-menu-item
                (click)="deleteDesignTemplate(eventTemplate)"
              >
                <span class="menu-item-content">
                  <i class="far fa-trash"></i>
                  <span class="text">{{
                    "APP.DESIGN_TEMPLATES.ACTIONS.DELETE" | translate
                  }}</span>
                </span>
              </button>
            </mat-menu>
          </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row
          [hidden]="dataSource.loading$ | async"
          *matRowDef="let row; columns: displayedColumns"
          (click)="onRowClick(row, $event)"
        ></mat-row>

        <div
          class="spinner-container d-flex justify-content-center"
          *ngIf="dataSource.loading$ | async"
        >
          <mat-spinner></mat-spinner>
        </div>
      </mat-table>
    </div>

    <app-paginator
      [totalItems]="dataSource.numRecords$ | async"
      [pageSizeOptions]="pageSizeOptions"
      (changePage)="onPageChange($event)"
    >
    </app-paginator>
  </div>

  <div class="add-button-container">
    <button
      (click)="addDesignTemplate()"
      type="button"
      mat-raised-button
      class="actionButton btn-outline-secondary addButton"
    >
      <span class="actions">
        <i class="far fa-plus"></i>
        {{ "APP.DESIGN_TEMPLATES.ADD_TEMPLATE" | translate }}
      </span>
    </button>
  </div>
</div>
