@if (form) {
  <div class="overlay-page overlay-theme">
    <app-template-overlay-header
      [isEdit]="isEdit"
      [isDraft]="isDraft"
      [form]="form"
      [dialogRef]="dialogRef"
      [currentStep]="currentStep"
      [numberOfSteps]="numberOfSteps"
      (nextStep)="nextStep()"
    ></app-template-overlay-header>

    <!-- <div class="language-buttons">
      <div class="buttons-container">
        <button
          mat-menu-item
          (click)="selectLanguage('de')"
          [ngClass]="{ active: currentLang === 'de' }"
        >
          <span class="menu-item-content">
            <span
              class="text"
              [ngClass]="{
                'selected-language': currentLang === 'de',
                'not-selected-language': currentLang !== 'de'
              }"
              >{{ "APP.LANGUAGES.DE" | translate }}</span
            >
          </span>
        </button>
        <button
          mat-menu-item
          (click)="selectLanguage('en')"
          [ngClass]="{ active: currentLang === 'en' }"
        >
          <span class="menu-item-content">
            <span
              class="text"
              [ngClass]="{
                'selected-language': currentLang === 'en',
                'not-selected-language': currentLang !== 'en'
              }"
              >{{ "APP.LANGUAGES.EN" | translate }}</span
            >
          </span>
        </button>
      </div>
    </div> -->

    <div class="body-info">
      <div class="form" [class.form-invitation-card-step]="currentStep === 6">
        <app-template-basic-infos-step
          [class.display-none]="currentStep !== 1"
          [form]="basicInfosForm"
          [eventTemplate]="eventTemplate"
        ></app-template-basic-infos-step>
        <app-template-visual-step
          [class.display-none]="currentStep !== 2"
          [form]="visualForm"
        ></app-template-visual-step>
        <app-template-text-step
          [class.display-none]="currentStep !== 3"
          [form]="form"
        ></app-template-text-step>
        <app-template-widgets-and-kits-step
          [class.display-none]="currentStep !== 4"
          [form]="form"
        ></app-template-widgets-and-kits-step>
        <app-template-partner-and-affiliates-step
          [class.display-none]="currentStep !== 5"
          [form]="form"
        ></app-template-partner-and-affiliates-step>
        <app-template-invitation-card-step
          [class.display-none]="currentStep !== 6"
          [form]="form"
        ></app-template-invitation-card-step>
        <app-template-seo-step
          [class.display-none]="currentStep !== 7"
          [form]="seoForm"
          [access]="basicInfosForm.controls['status'].value"
          [isEdit]="isEdit"
        ></app-template-seo-step>
      </div>
    </div>

    <div class="floating-button-container">
      @if (visualForm.valid) {
        <button
          (click)="previewTemplate()"
          type="button"
          class="btn floating-btn"
        >
          <i class="far fa-eye"></i>
          {{ "APP.DESIGN_TEMPLATES.CREATE.PREVIEW" | translate }}
        </button>
      }

      @if (isDraft) {
        <button
          (click)="saveAsDraftDesignTemplateShortcut()"
          type="button"
          class="btn floating-btn"
        >
          {{ "APP.DESIGN_TEMPLATES.CREATE.SAVE_AS_DRAFT" | translate }}
        </button>
      }

      @if (isEdit) {
        <button
          (click)="publishDesignTemplateShortcut()"
          type="button"
          class="btn floating-btn"
        >
          {{ "APP.DESIGN_TEMPLATES.CREATE.PUBLISH" | translate }}
        </button>
      }
    </div>

    @if (loading) {
      <div class="full-page-loader-cont">
        <div class="loader"></div>
      </div>
    }
  </div>
}
