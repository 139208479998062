<div class="set-event-campaign-dialog event-dialog-content" mat-dialog-content>
  <div (click)="close()" class="close-button">
    <i class="fal fa-times-circle"></i>
  </div>
  <div class="dialog-body">
    <div class="dialog-title">
      {{ "APP.SET_EVENT_CAMPAIGN.TITLE" | translate }}
    </div>
    <div>
      <form [formGroup]="form">
        <mat-form-field class="flex-input" appearance="outline">
          <mat-label>{{
            "APP.SET_EVENT_CAMPAIGN.CHOOSE_CAMPAIGN" | translate
          }}</mat-label>
          <mat-select formControlName="campaignId">
            @for (campaign of campaigns; track campaign.id) {
              <mat-option [value]="campaign.id">
                {{ campaign.uri }}
              </mat-option>
            }
          </mat-select>
        </mat-form-field>
        @if (categoryCampaignIds.includes(form.controls["campaignId"].value)) {
          <mat-form-field class="flex-input" appearance="outline">
            <mat-label>{{
              "APP.SET_EVENT_CAMPAIGN.CHOOSE_CATEGORY" | translate
            }}</mat-label>
            <mat-select formControlName="category">
              @for (category of categoryData; track category.type) {
                <mat-option [value]="category.type">
                  {{ category.title | translate }}
                </mat-option>
              }
            </mat-select>
          </mat-form-field>
        }
      </form>
    </div>
  </div>
  <div mat-dialog-actions class="dialog-actions">
    <div class="btn btn-primary" (click)="setEventCampaign()">
      {{ "APP.SET_EVENT_CAMPAIGN.SET" | translate }}
    </div>
  </div>
</div>
