@if (form) {
  <form [formGroup]="form">
    <h2 class="body-header">
      {{ "APP.DESIGN_TEMPLATES.CREATE.STANDARD_INVITATION_TEXT" | translate }}
    </h2>
    <mat-form-field appearance="outline" class="w-100 mb-0">
      <textarea
        matInput
        formControlName="standardInvitationText"
        rows="5"
        placeholder="{{
          'APP.DESIGN_TEMPLATES.CREATE.STANDARD_INVITATION_TEXT_PLACEHOLDER'
            | translate
        }}"
      ></textarea>
    </mat-form-field>
    <div class="invitation-dynamic-tags">
      <app-copyable-text text="{{'{{EventName}}'}}"></app-copyable-text>
      <app-copyable-text text="{{'{{EventDate}}'}}"></app-copyable-text>
      <app-copyable-text text="{{'{{EventTime}}'}}"></app-copyable-text>
      <app-copyable-text text="{{'{{EventLocation}}'}}"></app-copyable-text>
    </div>

    <h2 class="body-header">
      {{ "APP.DESIGN_TEMPLATES.CREATE.FONT_SELECTION" | translate }}
    </h2>
    <app-font-selector
      [form]="form"
      fontLabel="{{
        'APP.DESIGN_TEMPLATES.CREATE.PRIMARY_LOGO_FONT' | translate
      }}"
      fontFormControlName="primaryLogoFont"
      fontColorFormControlName="primaryLogoFontColor"
      fontWeightFormControlName="primaryFontWeight"
      [fontSize]="53"
    ></app-font-selector>
    <app-font-selector
      [form]="form"
      fontLabel="{{ 'APP.DESIGN_TEMPLATES.CREATE.BODY_FONT' | translate }}"
      fontFormControlName="bodyFont"
      fontColorFormControlName="bodyFontColor"
      fontWeightFormControlName="bodyFontWeight"
      [fontSize]="20"
    ></app-font-selector>
    <!-- <app-font-selector
      [form]="form"
      fontLabel="{{ 'APP.DESIGN_TEMPLATES.CREATE.SIGNAL_FONT' | translate }}"
      fontFormControlName="signalFont"
      fontColorFormControlName="signalFontColor"
      fontWeightFormControlName="signalFontWeight"
      [fontSize]="27"
    ></app-font-selector> -->
  </form>
}
