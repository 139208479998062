import { inject } from '@angular/core';
import { Router } from '@angular/router';

import { AuthService } from '@services/auth/auth.service';

export const unauthenticatedOnlyGuard = () => {
  const authService = inject(AuthService);
  const router = inject(Router);

  const user = authService.getUser();

  if (user && user.id) {
    router.navigateByUrl(authService.homeUrl || '');
    return false;
  }

  return true;
};
