import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormGroup } from '@angular/forms';
import { FormsModules } from '@utils/shared-modules';
import { TranslateModule } from '@ngx-translate/core';
import { AffiliateProductsSelectComponent } from '@modules/shared/components/affiliate-products-select/affiliate-products-select.component';
import { AffiliateProductsService } from '@services/affiliate-products.service';
import { AffiliateProduct } from '@models/affiliate-products/affiliate-product.model';
import { AutocompleteSelectComponent } from '@modules/shared/components/autocomplete-select/autocomplete-select.component';
import { User } from '@models/users/user.model';
import { UserService } from '@services/auth/user.service';

@Component({
  selector: 'app-template-partner-and-affiliates-step',
  standalone: true,
  imports: [
    CommonModule,
    FormsModules,
    TranslateModule,
    AffiliateProductsSelectComponent,
    AutocompleteSelectComponent,
  ],
  providers: [AffiliateProductsService, UserService],
  templateUrl: './template-partner-and-affiliates-step.component.html',
  styleUrl: './template-partner-and-affiliates-step.component.scss',
})
export class TemplatePartnerAndAffiliatesStepComponent implements OnInit {
  @Input() form!: FormGroup;

  affiliateProducts: AffiliateProduct[] = [];

  partners: User[] = [];

  constructor(
    private affiliateProductsService: AffiliateProductsService,
    private userService: UserService,
  ) {}

  ngOnInit() {
    const filters = new Map<string, any>();
    filters.set('forFiltering', false);

    this.affiliateProductsService
      .getAllAffiliateProducts(filters)
      .subscribe(
        (affiliateProducts) => (this.affiliateProducts = affiliateProducts),
      );

    this.userService
      .getPartners()
      .subscribe((partners) => (this.partners = partners));
  }
}
