<div class="product-details-header-container">
  <div class="left-side" (click)="returnToEventTemplateListPage()">
    <i class="far fa-chevron-left"></i>

    <h1 class="page-header">
      {{ 'APP.DESIGN_TEMPLATES.DETAILS_HEADER' | translate }}
    </h1>
  </div>

  <div></div>

  <div class="right-side" *ngIf="eventTemplate">
    <button mat-menu-item (click)="copyTemplateId(eventTemplate.id)">
            <span class="menu-item-content">
              <i class="far fa-copy"></i>
              <span class="text">{{ 'APP.DESIGN_TEMPLATES.ACTIONS.COPY_ID' | translate }}</span>
            </span>
    </button>

    <button mat-menu-item (click)="copyLink()">
            <span class="menu-item-content">
              <i class="far fa-link"></i>
              <span class="text">{{ 'APP.DESIGN_TEMPLATES.ACTIONS.COPY_LINK' | translate }}</span>
            </span>
    </button>

    <button mat-menu-item (click)="pauseTemplate(eventTemplate)" *ngIf="eventTemplate.access !== 'INACTIVE' && eventTemplate.access !== 'ARCHIVED'">
            <span class="menu-item-content">
              <i class="far fa-pause"></i>
              <span class="text">{{ 'APP.AFFILIATE_PRODUCTS_PAGE_LIST.ACTIONS.PAUSE' | translate }}</span>
            </span>
    </button>

    <button mat-menu-item (click)="continueTemplate(eventTemplate)" *ngIf="eventTemplate.access === 'INACTIVE'">
            <span class="menu-item-content">
              <i class="far fa-play"></i>
              <span class="text">{{ 'APP.AFFILIATE_PRODUCTS_PAGE_LIST.ACTIONS.CONTINUE' | translate }}</span>
            </span>
    </button>

    <button mat-menu-item (click)="archiveTemplate(eventTemplate)" *ngIf="eventTemplate.access !== 'INACTIVE' && eventTemplate.access !== 'ARCHIVED'">
            <span class="menu-item-content">
              <i class="far fa-archive"></i>
              <span class="text">{{ 'APP.AFFILIATE_PRODUCTS_PAGE_LIST.ACTIONS.ARCHIVE' | translate }}</span>
            </span>
    </button>

    <button mat-menu-item (click)="restoreTemplate(eventTemplate)" *ngIf="eventTemplate.access === 'ARCHIVED'">
            <span class="menu-item-content">
              <i class="far fa-box-open"></i>
              <span class="text">{{ 'APP.AFFILIATE_PRODUCTS_PAGE_LIST.ACTIONS.RESTORE' | translate }}</span>
            </span>
    </button>

    <button mat-menu-item (click)="deleteTemplate(eventTemplate)">
            <span class="menu-item-content">
              <i class="far fa-trash"></i>
              <span class="text">{{ 'APP.AFFILIATE_PRODUCTS_PAGE_LIST.ACTIONS.DELETE' | translate }}</span>
            </span>
    </button>
  </div>
</div>

<hr class="hr"/>
