import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-design-template-stats',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './design-template-stats.page.html',
  styleUrl: './design-template-stats.page.scss'
})
export class DesignTemplateStatsPage {

}
