import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormGroup } from '@angular/forms';
import { FormsModules } from '@utils/shared-modules';
import { TranslateModule } from '@ngx-translate/core';
import { TelInputComponent } from '@modules/shared/components/tel-input/tel-input.component';
import { InvitationCardPreviewComponent } from '@modules/admin/events/components/invitation-card-preview/invitation-card-preview.component';

@Component({
  selector: 'app-template-invitation-card-step',
  standalone: true,
  imports: [
    CommonModule,
    FormsModules,
    TranslateModule,
    TelInputComponent,
    InvitationCardPreviewComponent,
  ],
  templateUrl: './template-invitation-card-step.component.html',
  styleUrl: './template-invitation-card-step.component.scss',
})
export class TemplateInvitationCardStepComponent {
  @Input() form?: FormGroup;
}
