<div class="invitation-card-preview-cont">
  <!-- Tab Bar -->
  <div class="license-tab-bar">
    <div class="pricings-licenses">
      <div
        [class.active-tab]="selectedTab === InvitationCardPreviewTab.FRONT_SIDE"
        class="tab"
        (click)="changeTab(InvitationCardPreviewTab.FRONT_SIDE)"
      >
        <div class="tab-license-type-text">
          {{ "APP.FRONT_SIDE" | translate }}
        </div>
      </div>
      <div
        [class.active-tab]="selectedTab === InvitationCardPreviewTab.BACK_SIDE"
        class="tab"
        (click)="changeTab(InvitationCardPreviewTab.BACK_SIDE)"
      >
        <div class="tab-license-type-text">
          {{ "APP.BACK_SIDE" | translate }}
        </div>
      </div>
    </div>
  </div>

  @if (visualForm) {
    <div
      class="invitation-card-preview"
      [style.background-image]="
        getTemplateAsset('background')
          ? 'url(' + getTemplateAsset('background').url + ')'
          : ''
      "
      [style.backgroundColor]="
        visualFormValue.backgroundColor ? visualFormValue.backgroundColor : ''
      "
    >
      @if (getAsset("patternAsset") && isAssetEmpty(getAsset("patternAsset"))) {
        <img class="pattern" [src]="createDataUrl(getAsset('patternAsset'))" />
      }

      @if (getTemplateAsset("keyVisual1")) {
        <img class="key-visual-1" [src]="getTemplateAsset('keyVisual1').url" />
      }

      @if (
        getAsset("keyVisual2Asset") && isAssetEmpty(getAsset("keyVisual2Asset"))
      ) {
        <img
          class="key-visual-2"
          [src]="createDataUrl(getAsset('keyVisual2Asset'))"
        />
      }

      <div
        class="invitation-text"
        [ngStyle]="{
          'font-family': formValue.bodyFont ? formValue.bodyFont : ''
        }"
        [style.color]="formValue.bodyFontColor"
      >
        {{
          formValue.invitationCardText ||
            ("APP.DESIGN_TEMPLATES.CREATE.INVITATION_TEXT" | translate)
        }}
      </div>

      <div
        class="event-name"
        [ngStyle]="{
          'font-family': formValue.primaryLogoFont
            ? formValue.primaryLogoFont
            : ''
        }"
        [style.color]="formValue.primaryLogoFontColor"
      >
        {{ formValue.invitationCardTitle || ("APP.HEADLINE" | translate) }}
      </div>
      <div
        class="body-text"
        [ngStyle]="{
          'font-family': formValue.primaryLogoFont
            ? formValue.primaryLogoFont
            : ''
        }"
        [style.color]="formValue.primaryLogoFontColor"
      >
        <div class="event-date-cont">
          <div class="event-date event-info-with-icon">
            <i class="far fa-calendar-day"></i>25.12.2023
          </div>
          <div class="event-time event-info-with-icon">
            <i class="far fa-clock"></i>22:00
          </div>
        </div>
        <div class="event-address event-info-with-icon">
          <i class="far fa-location-arrow"></i>Club Lido, Curvystrasse 7, Berlin
        </div>
      </div>

      @if (getAsset("logoAsset") && isAssetEmpty(getAsset("logoAsset"))) {
        <img class="logo" [src]="createDataUrl(getAsset('logoAsset'))" />
      }
    </div>
  }
  <div class="invitation-card-preview-info">
    {{ "APP.DESIGN_TEMPLATES.CREATE.INVITATION_CARD_INFO" | translate }}
  </div>
</div>
