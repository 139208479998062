<div class="list-page-container">
  <app-affiliate-product-details-header
    [product]="product"
    (onPause)="onPause($event)"
    (onContinue)="onContinue($event)"
    (onArchive)="onArchive($event)"
    (onRestore)="onRestore($event)"
  >
  </app-affiliate-product-details-header>

  <div>
    <div class="body-header-info">
      <img
        class="image"
        *ngIf="product?.pictureUrl"
        [src]="product?.pictureUrl"
        alt="Product Image"
      />
      <div class="name">{{ product?.name }}</div>
    </div>

    <div class="body-subheader-info">
      <div class="subheader">
        {{ "APP.AFFILIATE_PRODUCTS_PAGE_LIST.AFFILIATE_OWNER" | translate }}
        <img
          class="user-image"
          *ngIf="product?.owner?.avatarUrl"
          [src]="product?.owner?.avatarUrl"
          alt="User Image"
        />
        {{ product?.owner?.name }}
      </div>

      <hr class="vr-sm" />

      <div class="subheader">
        {{ "APP.AFFILIATE_PRODUCTS_PAGE_LIST.CREATION_DATE" | translate }} {{
        createdAt !== undefined && createdAt!.getTime() > 0 ? (createdAt | date:
        'dd.MM.yyy') : 'n/a' }}
      </div>
    </div>

    <hr class="hr" />

    <div class="body-chips-info-container">
      <div class="body-chips-info-segments">
        <div class="chips-title">
          {{ "APP.AFFILIATE_PRODUCTS_PAGE_LIST.CATEGORY" | translate }}
        </div>
        <div class="chips-div">
          <mat-chip
            class="chips-value"
            *ngFor="let eventCategory of product?.eventCategories"
            >{{ eventCategory?.name }}</mat-chip
          >
        </div>
      </div>

      <hr class="vr" />

      <div class="body-chips-info-segments pr-95">
        <div class="chips-title">
          {{ "APP.AFFILIATE_PRODUCTS_PAGE_LIST.SUBCATEGORY" | translate }}
        </div>
        <div class="chips-div">
          <mat-chip
            class="chips-value"
            *ngFor="let eventSubcategory of product?.eventSubcategories"
            >{{ eventSubcategory?.name }}</mat-chip
          >
        </div>
      </div>

      <hr class="vr" />

      <div class="body-chips-info-segments">
        <div class="chips-title">
          {{ "APP.AFFILIATE_PRODUCTS_PAGE_LIST.AFFILIATE_NETWORK" | translate }}
        </div>
        <mat-chip class="chips-value"
          >{{ getDisplayNetworkName(product?.network) | translate }}</mat-chip
        >
      </div>

      <hr class="vr" />

      <div class="body-chips-info-segments">
        <div class="chips-title">
          {{ "APP.AFFILIATE_PRODUCTS_PAGE_LIST.STATUS.TITLE" | translate }}
        </div>
        <mat-chip class="chips-value"
          >{{ getDisplayStatusName(product?.status) | translate }}</mat-chip
        >
      </div>

      <hr class="vr" />

      <div class="body-chips-info-segments">
        <div class="chips-title">
          {{ "APP.AFFILIATE_PRODUCTS_PAGE_LIST.TABLE_HEADERS.ID" | translate }}
        </div>
        <mat-chip class="chips-value">{{ product?.id }}</mat-chip>
      </div>
    </div>
  </div>

  <div class="edit-button-container">
    <button
      (click)="editProduct()"
      type="button"
      mat-raised-button
      class="actionButton btn-outline-secondary editButton"
    >
      <span class="actions">
        <i class="far fa-pencil"></i>
        {{ 'APP.AFFILIATE_PRODUCTS_PAGE_LIST.ACTION_BUTTONS.EDIT_PRODUCT' |
        translate }}
      </span>
    </button>
  </div>
</div>
