import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormGroup } from '@angular/forms';
import { FormsModules } from '@utils/shared-modules';
import { TranslateModule } from '@ngx-translate/core';
import { BackButtonComponent } from '@modules/shared/components/back-button/back-button.component';
import { ProgressIndicatorComponent } from '@modules/shared/components/progress-indicator/progress-indicator.component';
import { MatDialogRef } from '@angular/material/dialog';
import { AffiliateProductOverlayPageComponent } from '@modules/admin/affiliates/dialogs/affiliate-product-overlay-page/affiliate-product-overlay-page.component';
import { MatMenuItem } from '@angular/material/menu';

@Component({
  selector: 'app-template-overlay-header',
  standalone: true,
  imports: [
    CommonModule,
    FormsModules,
    TranslateModule,
    BackButtonComponent,
    ProgressIndicatorComponent,
    MatMenuItem,
  ],
  templateUrl: './template-overlay-header.component.html',
  styleUrl: './template-overlay-header.component.scss',
})
export class TemplateOverlayHeaderComponent {
  @Input() isEdit: boolean = false;
  @Input() isDraft: boolean = false;
  @Input() dialogRef!: MatDialogRef<AffiliateProductOverlayPageComponent>;
  @Input() form?: FormGroup;
  @Input() currentStep: number = 0;
  @Input() numberOfSteps: number = 0;

  @Output() nextStep: EventEmitter<void> = new EventEmitter<void>();

  ngOnInit(): void {}

  goToNextStep() {
    if (this.isStepValid()) {
      this.nextStep.emit();
    }
  }

  isStepValid(): boolean {
    if (!this.form) {
      return false;
    }

    if (this.isDraft) {
      return true;
    }

    if (this.currentStep === 1) {
      const basicInfosForm = this.form?.get('basicInfosForm') as FormGroup;
      basicInfosForm.markAllAsTouched();
      return basicInfosForm.valid;
    } else if (this.currentStep === 2) {
      const visualForm = this.form?.get('visualForm') as FormGroup;
      visualForm.markAllAsTouched();
      return visualForm.valid;
    } else if (this.currentStep === 3) {
      const controlsToCheck = [
        this.form.controls['primaryLogoFont'],
        this.form.controls['primaryFontWeight'],
        this.form.controls['bodyFont'],
        this.form.controls['bodyFontColor'],
        this.form.controls['bodyFontWeight'],
        // this.form.controls['signalFont'],
        // this.form.controls['signalFontWeight'],
      ];
      controlsToCheck.forEach((control) => control.markAsTouched());
      for (const control of controlsToCheck) {
        if (control.invalid) {
          return false;
        }
      }
      return true;
    } else if (this.currentStep === 7) {
      this.form.markAllAsTouched();
      return this.form.valid;
    }

    return true;
  }

  cancel() {
    this.dialogRef.close();
  }

  get stepTitle(): string {
    switch (this.currentStep) {
      case 1:
        return 'APP.DESIGN_TEMPLATES.CREATE.BASIC_INFOS_TITLE';
      case 2:
        return 'APP.DESIGN_TEMPLATES.CREATE.VISUAL_ELEMENTS_TITLE';
      case 3:
        return 'APP.DESIGN_TEMPLATES.CREATE.TEXT_ELEMENTS_TITLE';
      case 4:
        return 'APP.DESIGN_TEMPLATES.CREATE.WIDGETS_AND_KITS_TITLE';
      case 5:
        return 'APP.DESIGN_TEMPLATES.CREATE.PARTNER_AND_AFFILIATES_TITLE';
      case 6:
        return 'APP.DESIGN_TEMPLATES.CREATE.INVITATION_CARD_TITLE';
      case 7:
        return 'APP.DESIGN_TEMPLATES.CREATE.SEO_STEP_TITLE';
      default:
        return '';
    }
  }

  get nextLabel(): string {
    if (this.currentStep === 7) {
      return 'APP.DESIGN_TEMPLATES.CREATE.PUBLISH';
    }

    return 'APP.NEXT';
  }
}
