<form [formGroup]="form">
  <h2 class="body-header">
    {{ "APP.DESIGN_TEMPLATES.CREATE.PRESET_WIDGETS" | translate }}
  </h2>
  <app-widgets-select
    [form]="form"
    selectedFormControlName="presetWidgets"
    [items]="widgets"
  >
  </app-widgets-select>

  <h2 class="body-header">
    {{ "APP.DESIGN_TEMPLATES.CREATE.KITS" | translate }}
  </h2>
  <div class="slide-toggle-cont">
    <div class="slide-toggle-label">PromotionKit</div>
    <label class="toggle-check">
      <input
        type="checkbox"
        class="toggle-check-input"
        [formGroup]="form"
        formControlName="promotionKit"
      />
      <span class="toggle-check-text"></span>
    </label>
  </div>
</form>
