<div class="fixated-header">
  <div class="header">
    <app-back-button
      class="mobile-back-btn mobile-only"
      [size]="35"
    ></app-back-button>
    <div class="create-step-header">
      <div class="flex-left-placeholder desktop-only">
        @if (currentStep > 1) {
          <app-back-button [size]="35"></app-back-button>
        }
      </div>
    </div>
    <div class="title-and-step">
      <div class="flex-left-placeholder desktop-only"></div>
      <h1 mat-dialog-title class="title" *ngIf="!isEdit">
        {{ "APP.DESIGN_TEMPLATES.CREATE.TITLE" | translate }}
      </h1>
      <h1 mat-dialog-title class="title" *ngIf="isEdit">
        {{ "APP.DESIGN_TEMPLATES.CREATE.TITLE_EDIT" | translate }}
      </h1>
      <i class="far fa-chevron-right"></i>
      <h1>{{ stepTitle | translate }}</h1>
    </div>
    <div class="create-step-header header-buttons-container">
      <button type="button" class="btn btn-primary" (click)="goToNextStep()">
        {{ nextLabel | translate }}
      </button>
      <i class="fal fa-times-circle" (click)="cancel()"></i>
    </div>

    <!--  <div class="create-step-header">-->
    <!--    <div class="header-title-cont">-->
    <!--      <h1>{{ stepTitle | translate }}</h1>-->
    <!--    </div>-->
    <!--    <button type="button" class="btn btn-primary" (click)="goToNextStep()">-->
    <!--      {{ nextLabel | translate }}-->
    <!--    </button>-->
    <!--  </div>-->
  </div>
  <app-progress-indicator
    [currentStep]="currentStep"
    [numberOfSteps]="numberOfSteps"
  >
  </app-progress-indicator>
</div>
