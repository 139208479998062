import { CollectionViewer, DataSource } from '@angular/cdk/collections';
import {
  asapScheduler,
  BehaviorSubject,
  finalize,
  Observable,
  scheduled,
} from 'rxjs';
import { catchError } from 'rxjs/operators';
import { PageListingResponse } from '@models/api/page-listing-response.model';
import { Event } from '@models/events/event.model';
import { EventService } from '@services/events/event.service';

export class EventDataSource implements DataSource<Event> {
  private eventsSubject = new BehaviorSubject<Event[]>([]);
  private loadingSubject = new BehaviorSubject<boolean>(false);
  private numRecordsSubject = new BehaviorSubject<number>(0);

  numRecords$ = this.numRecordsSubject.asObservable();
  loading$ = this.loadingSubject.asObservable();

  data!: Event[];

  constructor(private eventService: EventService) {}
  connect(_: CollectionViewer): Observable<Event[]> {
    return this.eventsSubject.asObservable();
  }

  disconnect(_: CollectionViewer): void {
    this.eventsSubject.complete();
    this.numRecordsSubject.complete();
    this.loadingSubject.complete();
  }

  loadEvents(filters: Map<string, any>, pageNumber = 1, pageSize = 10) {
    this.loadingSubject.next(true);

    this.eventService
      .getEventsPaged(filters, pageNumber, pageSize)
      .pipe(
        catchError(() => {
          this.data = [];
          return scheduled([], asapScheduler);
        }),
        finalize(() => this.loadingSubject.next(false)),
      )
      .subscribe((res: PageListingResponse<Event>) => {
        this.eventsSubject.next(res.records);
        this.numRecordsSubject.next(res.total);
        this.data = res.records;
      });
  }
}
