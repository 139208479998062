import {
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormGroup } from '@angular/forms';
import { FormsModules } from '@utils/shared-modules';
import { TranslateModule } from '@ngx-translate/core';
import { Asset } from '@models/shared/asset.model';
import { FileUploaderComponent } from '@modules/shared/components/file-uploader/file-uploader.component';
import { KeywordsSelectComponent } from '@modules/shared/components/keywords-select/keywords-select.component';
import { AutocompleteSelectComponent } from '@modules/shared/components/autocomplete-select/autocomplete-select.component';
import { EventCategory } from '@models/event-categories/event-category.model';
import { Keyword } from '@models/shared/keyword.model';
import { EventCategoriesService } from '@services/event-categories.service';
import { SharedService } from '@services/shared/shared.service';
import { RichTextEditorComponent } from '@modules/shared/components/rich-text-editor/rich-text-editor.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { EventTemplateAccess } from '@models/design-templates/event-template-access.enum';

@Component({
  selector: 'app-template-seo-step',
  standalone: true,
  imports: [
    CommonModule,
    FormsModules,
    MatTooltipModule,
    TranslateModule,
    FileUploaderComponent,
    AutocompleteSelectComponent,
    KeywordsSelectComponent,
    RichTextEditorComponent,
  ],
  providers: [EventCategoriesService, SharedService],
  templateUrl: './template-seo-step.component.html',
  styleUrl: './template-seo-step.component.scss',
})
export class TemplateSeoStepComponent {
  @Input() form!: FormGroup;
  @Input() access?: EventTemplateAccess;
  @Input() isEdit: boolean = false;

  categories: EventCategory[] = [];
  keywords: Keyword[] = [];

  constructor(
    private eventCategoriesService: EventCategoriesService,
    private sharedService: SharedService,
  ) {
    this.eventCategoriesService
      .getEventCategories(true)
      .subscribe((eventCategories) => {
        this.categories = eventCategories;
      });

    this.sharedService
      .getKeywords()
      .subscribe((keywords) => (this.keywords = keywords));
  }

  onImageChanged(selectedFile: Asset) {
    this.form.controls['seoSharingPictureAsset'].patchValue(selectedFile);
    if (selectedFile) {
      this.form.controls['seoPictureFileName'].patchValue(selectedFile.name);
    }
  }

  saveEditorContent(updatedValue: any) {
    this.form.controls['seoPageDetail'].patchValue(updatedValue.value);
  }

  onImagesChanged(assets: Asset[]) {
    this.form.controls['marketplaceVisuals'].patchValue(assets);
  }

  protected readonly EventTemplateAccess = EventTemplateAccess;
}
