<div>
  <div>
    <app-filter
      [isCampaignEventList]="true"
      (filterChange)="onFilterChange($event)"
    ></app-filter>
  </div>

  <div class="table-scrollable" [class.loading]="dataSource.loading$ | async">
    <mat-table matSort [dataSource]="dataSource" class="mat-elevation-z8">
      <!-- Add this checkbox column definition inside the table -->
      <ng-container matColumnDef="select">
        <mat-header-cell *matHeaderCellDef>
          <mat-checkbox (change)="masterToggle()"></mat-checkbox>
        </mat-header-cell>
        <mat-cell *matCellDef="let row">
          <mat-checkbox (change)="toggleSelection(row)"></mat-checkbox>
        </mat-cell>
      </ng-container>

      <!-- Name Column -->
      <ng-container matColumnDef="name">
        <mat-header-cell mat-sort-header *matHeaderCellDef>{{
          "APP.DESIGN_TEMPLATES.TABLE_HEADERS.NAME" | translate
        }}</mat-header-cell>
        <mat-cell *matCellDef="let event">
          <div class="name-and-picture-col">
            @if (
              event.mainPictureUrl || event.eventTemplate?.visual?.keyVisual1
            ) {
              <img
                class="key-visual-1"
                [src]="
                  event.mainPictureUrl ||
                  event.eventTemplate?.visual?.keyVisual1?.url
                "
              />
            }

            <div class="ellipsis-field">{{ event.name }}</div>
          </div>
        </mat-cell>
      </ng-container>

      <!-- Date Column -->
      <ng-container matColumnDef="date">
        <mat-header-cell mat-sort-header *matHeaderCellDef>{{
          "APP.CAMPAIGNS.DATE" | translate
        }}</mat-header-cell>
        <mat-cell *matCellDef="let event">{{
          event.getDisplayDate()
        }}</mat-cell>
      </ng-container>

      <!-- Created At Column -->
      <ng-container matColumnDef="createdAt">
        <mat-header-cell mat-sort-header *matHeaderCellDef>{{
          "APP.CREATION_DATE" | translate
        }}</mat-header-cell>
        <mat-cell *matCellDef="let event">{{
          event.createdAt | date: "dd.MM.yyyy HH:mm"
        }}</mat-cell>
      </ng-container>

      <!-- Location Column -->
      <ng-container matColumnDef="location">
        <mat-header-cell mat-sort-header *matHeaderCellDef>{{
          "APP.CAMPAIGNS.LOCATION" | translate
        }}</mat-header-cell>
        <mat-cell *matCellDef="let event">{{
          event.getAddressDisplayName()
        }}</mat-cell>
      </ng-container>

      <!-- Host Column -->
      <ng-container matColumnDef="host">
        <mat-header-cell mat-sort-header *matHeaderCellDef>{{
          "APP.CAMPAIGNS.HOST" | translate
        }}</mat-header-cell>
        <mat-cell *matCellDef="let event"
          >{{ event.hostUserFull?.name || "-" }}
        </mat-cell>
      </ng-container>

      <!-- Host Contact Column -->
      <ng-container matColumnDef="hostContact">
        <mat-header-cell *matHeaderCellDef>{{
          "APP.CAMPAIGNS.HOST_CONTACT" | translate
        }}</mat-header-cell>
        <mat-cell *matCellDef="let event">{{
          event.hostUserFull?.getUserEmailAndPhoneInfo() || "-"
        }}</mat-cell>
      </ng-container>

      <!-- Host Last Login Column -->
      <ng-container matColumnDef="hostLastLogin">
        <mat-header-cell mat-sort-header *matHeaderCellDef>{{
          "APP.CAMPAIGNS.HOST_LAST_LOGIN" | translate
        }}</mat-header-cell>
        <mat-cell *matCellDef="let event">{{
          event.hostUserFull?.getLastLogin()
            ? (event.hostUserFull?.getLastLogin() | date: "dd.MM.yyyy HH:mm")
            : "-"
        }}</mat-cell>
      </ng-container>

      <!-- Status Column -->
      <ng-container matColumnDef="status">
        <mat-header-cell mat-sort-header *matHeaderCellDef>{{
          "APP.CAMPAIGNS.STATUS" | translate
        }}</mat-header-cell>
        <mat-cell *matCellDef="let event"
          >{{ EventStatus.getDisplayName(event.status) | translate }}
        </mat-cell>
      </ng-container>

      <!-- Guests Column -->
      <ng-container matColumnDef="guests">
        <mat-header-cell mat-sort-header *matHeaderCellDef>{{
          "APP.CAMPAIGNS.GUESTS" | translate
        }}</mat-header-cell>
        <mat-cell *matCellDef="let event">{{ event.getNumGuests() }} </mat-cell>
      </ng-container>

      <!-- Campaign Category Column -->
      <ng-container matColumnDef="campaignCategory">
        <mat-header-cell mat-sort-header *matHeaderCellDef>{{
          "APP.CAMPAIGNS.CAMPAIGN_CATEGORY" | translate
        }}</mat-header-cell>
        <mat-cell *matCellDef="let event"
          >{{
            EventCampaignCategory.getDisplayName(
              event.getEventCampaignCategory()
            ) || "-" | translate
          }}
        </mat-cell>
      </ng-container>

      <!-- Campaign Status Column -->
      <ng-container matColumnDef="campaignStatus">
        <mat-header-cell mat-sort-header *matHeaderCellDef>{{
          "APP.CAMPAIGNS.CAMPAIGN_STATUS" | translate
        }}</mat-header-cell>
        <mat-cell *matCellDef="let event"
          >{{
            EventCampaignStatus.getDisplayName(
              event.getEventCampaignStatus()
            ) || "-" | translate
          }}
        </mat-cell>
      </ng-container>

      <!-- Campaign Party Status Column -->
      <ng-container matColumnDef='campaignPartyStatus'>
        <mat-header-cell mat-sort-header *matHeaderCellDef>
          {{ "APP.CAMPAIGNS.CAMPAIGN_PARTY_STATUS" | translate }}
        </mat-header-cell>
        <mat-cell *matCellDef='let event' [ngClass]='event.getEventCampaignPartyStatusTableClass()'>
          <mat-form-field class='table-select w-100' appearance='outline' (click)='$event.stopPropagation()'>
            <mat-select [ngModel]='event.getEventCampaignPartyStatus()' placeholder='-'>
              <mat-option
                *ngFor='let status of EventCampaignPartyStatus.getAllEventCampaignPartyStatuses()'
                [value]='status'
                (click)='onCampaignPartyStatusChange(event.id, status)'
              >
                {{ EventCampaignPartyStatus.getDisplayName(status) || "-"| translate }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </mat-cell>
      </ng-container>

      <!-- Campaign Working Status Column -->
      <ng-container matColumnDef="campaignWorkingStatus">
        <mat-header-cell mat-sort-header *matHeaderCellDef>{{
          "APP.CAMPAIGNS.CAMPAIGN_WORKING_STATUS" | translate
        }}</mat-header-cell>
        <mat-cell
          *matCellDef="let event"
          [ngClass]="event.getEventCampaignWorkingStatusTableClass()"
        >
          <mat-form-field
            class="table-select w-100"
            appearance="outline"
            (click)="$event.stopPropagation()"
          >
            <mat-select
              [ngModel]="event.getEventCampaignWorkingStatus()"
              placeholder="-"
            >
              <mat-option
                *ngFor="
                  let status of EventCampaignWorkingStatus.getAllEventCampaignWorkingStatuses()
                "
                [value]="status"
                (click)="onCampaignWorkingStatusChange(event.id, status)"
              >
                {{
                  EventCampaignWorkingStatus.getDisplayName(status) || "-"
                    | translate
                }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </mat-cell>
      </ng-container>

      <!-- Shipping Contact Column -->
      <ng-container matColumnDef="shippingContact">
        <mat-header-cell *matHeaderCellDef>{{
          "APP.CAMPAIGNS.SHIPPING_CONTACT" | translate
        }}</mat-header-cell>
        <mat-cell *matCellDef="let event"
          >{{ event.hostUserFull?.shippingData?.getContactData() || "-" }}
        </mat-cell>
      </ng-container>

      <!-- Shipping Address Column -->
      <ng-container matColumnDef="shippingAddress">
        <mat-header-cell *matHeaderCellDef>{{
          "APP.CAMPAIGNS.SHIPPING_ADDRESS" | translate
        }}</mat-header-cell>
        <mat-cell *matCellDef="let event"
          >{{ event.hostUserFull?.shippingData?.addressToString() || "-" }}
        </mat-cell>
      </ng-container>

      @if (campaignId) {
        <!-- Eligible for packages -->
        <ng-container matColumnDef="eligibleForPackages">
          <mat-header-cell *matHeaderCellDef>{{
            "APP.CAMPAIGNS.ELIGIBLE_FOR_RECEIVING_PACKAGES" | translate
          }}</mat-header-cell>
          <mat-cell *matCellDef="let event"
            >{{
              (!event.eventCampaign?.confirmNoPackages ? "APP.YES" : "APP.NO")
                | translate
            }}
          </mat-cell>
        </ng-container>
        <!-- Campaign Security Check Link -->
        <ng-container matColumnDef="campaignInsuranceCheckLink">
          <mat-header-cell *matHeaderCellDef>{{
            "APP.CAMPAIGNS.CAMPAIGN_SECURITY_CHECK_LINK" | translate
          }}</mat-header-cell>
          <mat-cell *matCellDef="let event"
            >{{ event.eventCampaign?.campaignInsuranceCheckLink?.link || "-" }}
          </mat-cell>
        </ng-container>
      }

      <!-- Actions Column -->
      <ng-container matColumnDef="actions" stickyEnd>
        <mat-header-cell *matHeaderCellDef>{{
          "APP.DESIGN_TEMPLATES.TABLE_HEADERS.ACTIONS" | translate
        }}</mat-header-cell>
        <mat-cell *matCellDef="let event">
          <button
            mat-icon-button
            [matMenuTriggerFor]="menu"
            class="more-vert-rotate-90"
            (click)="$event.stopPropagation()"
          >
            <mat-icon>more_vert</mat-icon>
          </button>
          <mat-menu #menu="matMenu" class="table-actions-container">
            <button
              mat-menu-item
              (click)="
                setEventCampaignStatus(event.id, EventCampaignStatus.APPROVED)
              "
              *ngIf="
                [
                  EventCampaignStatus.WAITING_FOR_APPROVAL,
                  EventCampaignStatus.NOT_APPROVED
                ].includes(event.getEventCampaignStatus())
              "
            >
              <span class="menu-item-content">
                <i class="far fa-check"></i>
                <span class="text">{{
                  "APP.CAMPAIGNS.APPROVE" | translate
                }}</span>
              </span>
            </button>

            <button
              mat-menu-item
              (click)="
                setEventCampaignStatus(
                  event.id,
                  EventCampaignStatus.NOT_APPROVED
                )
              "
              *ngIf="
                [
                  EventCampaignStatus.WAITING_FOR_APPROVAL,
                  EventCampaignStatus.APPROVED
                ].includes(event.getEventCampaignStatus())
              "
            >
              <span class="menu-item-content">
                <i class="far fa-times"></i>
                <span class="text">{{
                  "APP.CAMPAIGNS.REJECT" | translate
                }}</span>
              </span>
            </button>
            <button mat-menu-item (click)="downloadKeyVisual(event)">
              <span class="menu-item-content">
                <i class="far fa-cloud-download"></i>
                <span class="text">{{
                  "APP.EVENT.DOWNLOAD_KEY_VISUAL" | translate
                }}</span>
              </span>
            </button>
            @if (!event.eventCampaign) {
              <button mat-menu-item (click)="setEventCampaign(event)">
                <span class="menu-item-content">
                  <i class="far fa-bullhorn"></i>
                  <span class="text">{{
                    "APP.EVENT.SET_CAMPAIGN" | translate
                  }}</span>
                </span>
              </button>
            } @else {
              <button mat-menu-item (click)="removeEventCampaign(event.id)">
                <span class="menu-item-content">
                  <i class="far fa-trash-alt"></i>
                  <span class="text">{{
                    "APP.EVENT.REMOVE_CAMPAIGN" | translate
                  }}</span>
                </span>
              </button>
            }
          </mat-menu>
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row
        [hidden]="dataSource.loading$ | async"
        *matRowDef="let row; columns: displayedColumns"
        (click)="onRowClick(row, $event)"
      ></mat-row>

      <div
        class="spinner-container d-flex justify-content-center"
        *ngIf="dataSource.loading$ | async"
      >
        <mat-spinner></mat-spinner>
      </div>
    </mat-table>
  </div>

  <app-paginator
    [totalItems]="dataSource.numRecords$ | async"
    [pageSizeOptions]="pageSizeOptions"
    (changePage)="onPageChange($event)"
  >
  </app-paginator>
</div>
