<div class="product-details-header-container">
  <div class="left-side" (click)="returnToAffiliateProductsListPage()">
    <i class="far fa-chevron-left"></i>

    <h1 class="page-header">
      {{ "APP.AFFILIATE_PRODUCTS_PAGE_LIST.PRODUCTS_HEADER" | translate }}
    </h1>
  </div>

  <div></div>

  <div class="right-side" *ngIf="product">
    <button mat-menu-item (click)="copyProduct(product)">
            <span class="menu-item-content">
              <i class="far fa-copy"></i>
              <span class="text">{{ 'APP.AFFILIATE_PRODUCTS_PAGE_LIST.ACTIONS.COPY_PRODUCT' | translate }}</span>
            </span>
    </button>

    <button mat-menu-item (click)="copyProductLink(product.link)">
            <span class="menu-item-content">
              <i class="far fa-link"></i>
              <span class="text">{{ 'APP.AFFILIATE_PRODUCTS_PAGE_LIST.ACTIONS.COPY_LINK' | translate }}</span>
            </span>
    </button>

    <button mat-menu-item (click)="pauseProduct(product)" *ngIf="product?.status === 'ACTIVE'">
            <span class="menu-item-content">
              <i class="far fa-pause"></i>
              <span class="text">{{ 'APP.AFFILIATE_PRODUCTS_PAGE_LIST.ACTIONS.PAUSE' | translate }}</span>
            </span>
    </button>

    <button mat-menu-item (click)="continueProduct(product)" *ngIf="product?.status === 'INACTIVE'">
            <span class="menu-item-content">
              <i class="far fa-play"></i>
              <span class="text">{{ 'APP.AFFILIATE_PRODUCTS_PAGE_LIST.ACTIONS.CONTINUE' | translate }}</span>
            </span>
    </button>

    <button mat-menu-item (click)="archiveProduct(product)" *ngIf="product?.status !== 'ARCHIVED'">
            <span class="menu-item-content">
              <i class="far fa-archive"></i>
              <span class="text">{{ 'APP.AFFILIATE_PRODUCTS_PAGE_LIST.ACTIONS.ARCHIVE' | translate }}</span>
            </span>
    </button>

    <button mat-menu-item (click)="restoreProduct(product)" *ngIf="product?.status === 'ARCHIVED'">
            <span class="menu-item-content">
              <i class="far fa-box-open"></i>
              <span class="text">{{ 'APP.AFFILIATE_PRODUCTS_PAGE_LIST.ACTIONS.RESTORE' | translate }}</span>
            </span>
    </button>

    <button mat-menu-item (click)="deleteProduct(product)">
            <span class="menu-item-content">
              <i class="far fa-trash"></i>
              <span class="text">{{ 'APP.AFFILIATE_PRODUCTS_PAGE_LIST.ACTIONS.DELETE' | translate }}</span>
            </span>
    </button>
  </div>
</div>

<hr class="hr"/>
