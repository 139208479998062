import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { FormsModules } from '@utils/shared-modules';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AuthService } from '@services/auth/auth.service';
import { finalize } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { jwtDecode } from 'jwt-decode';
import { sameAsFormControl } from 'app/validators/same-as-form-control.validator';
import { PasswordValidator } from 'app/validators/password.validator';
import { AppRoutes } from 'app/routes';

@Component({
  selector: 'app-reset-password',
  standalone: true,
  imports: [CommonModule, TranslateModule, FormsModules],
  templateUrl: './reset-password.page.html',
  styleUrl: './reset-password.page.scss',
})
export class ResetPasswordPage implements OnInit {
  private token: string = '';
  email: string = '';
  form?: FormGroup;
  inProgress = false;

  showSuccess = false;

  constructor(
    private authService: AuthService,
    private translateService: TranslateService,
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) {
    this.activatedRoute.url.subscribe((segments) => {
      if (segments && segments.length > 0 && segments[0].path === 'success') {
        this.showSuccess = true;
      } else {
        this.form = new FormGroup({
          password: new FormControl('', [
            Validators.required,
            PasswordValidator.strong,
          ]),
          confirmPassword: new FormControl('', [
            Validators.required,
            sameAsFormControl('password'),
          ]),
        });
      }
    });
  }
  ngOnInit(): void {
    this.token = this.activatedRoute.snapshot.queryParams['token'];
    const decodedToken = jwtDecode<any>(this.token);
    this.email = decodedToken.email;
  }

  resetPassword(): void {
    if (!this.form) {
      return;
    }
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return;
    }

    this.inProgress = true;

    this.authService
      .resetPassword(this.form.controls['password'].value, this.token)
      .pipe(finalize(() => (this.inProgress = false)))
      .subscribe({
        next: () => {
          this.router.navigate([AppRoutes.Root.success], {
            relativeTo: this.activatedRoute,
            queryParamsHandling: 'merge',
          });
        },
        error: () => {
          this.form &&
            this.form.controls['email'].setErrors({
              custom: this.translateService.instant(
                'APP.AUTH.ERRORS.RESET_PASSWORD_ERROR'
              ),
            });
        },
      });
  }

  goToLogin(): void {
    this.router.navigate(['/', AppRoutes.Root.login]);
  }
}
