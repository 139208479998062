<div class="list-page-container">
  <app-design-template-details-header
    [eventTemplate]="eventTemplate"
    (onPause)="onPause($event)"
    (onContinue)="onContinue($event)"
    (onArchive)="onArchive($event)"
    (onRestore)="onRestore($event)"
  >
  </app-design-template-details-header>

  <div>
    <div class="body-header-info">
      <img class="image" *ngIf="eventTemplate?.visual?.thumbnailUrl" [src]="eventTemplate?.visual?.thumbnailUrl" alt="Product Image">
      <div class="name">{{ eventTemplate?.name }}</div>
    </div>

    <div class="body-subheader-info">
      <div class="subheader">
        {{ "APP.DESIGN_TEMPLATES.TEMPLATE_OWNER" | translate }}
        <img class="user-image" *ngIf="eventTemplate?.creator?.avatarUrl" [src]="eventTemplate?.creator?.avatarUrl" alt="User Image">
        {{ eventTemplate?.creator?.name }}
      </div>

      <hr class="vr-sm"/>

      <div class="subheader">{{ "APP.DESIGN_TEMPLATES.CREATION_DATE" | translate }}
        {{ createdAt !== undefined && createdAt!.getTime() > 0 ?
          (createdAt | date: 'dd.MM.yyy') :
          'n/a' }}
      </div>
    </div>

    <hr class="hr"/>

    <div class="body-chips-info-container">

      <div class="body-chips-info-segments">
        <div class="chips-title">{{ "APP.DESIGN_TEMPLATES.CATEGORY" | translate }}</div>
        <div class="chips-div">
          <mat-chip class="chips-value" *ngFor="let eventCategory of eventTemplate?.eventCategories">{{ eventCategory?.name }}</mat-chip>
        </div>
      </div>

      <hr class="vr"/>

      <div class="body-chips-info-segments pr-95">
        <div class="chips-title">{{ "APP.DESIGN_TEMPLATES.SUBCATEGORY" | translate }}</div>
        <div class="chips-div">
          <mat-chip class="chips-value" *ngFor="let eventSubcategory of eventTemplate?.eventSubcategories">{{ eventSubcategory?.name }}</mat-chip>
        </div>
      </div>

      <hr class="vr"/>

      <div class="body-chips-info-segments">
        <div class="chips-title">{{ "APP.DESIGN_TEMPLATES.ACCESS_STATUS" | translate }}</div>
        <mat-chip class="chips-value">{{ getDisplayStatus(eventTemplate?.access) | translate }}</mat-chip>
      </div>

      <hr class="vr"/>

      <div class="body-chips-info-segments">
        <div class="chips-title">{{ "APP.DESIGN_TEMPLATES.RELEVANCE_STATUS" | translate }}</div>
        <mat-chip class="chips-value">{{ getDisplayRelevance(eventTemplate?.relevance) | translate }}</mat-chip>
      </div>

      <hr class="vr"/>

      <div class="body-chips-info-segments">
        <div class="chips-title">{{ "APP.DESIGN_TEMPLATES.ID" | translate }}</div>
        <mat-chip class="chips-value">{{ eventTemplate?.id }}</mat-chip>
      </div>

    </div>
  </div>


  <div class="edit-button-container">
    <button
      (click)="editEventTemplate()"
      type="button"
      mat-raised-button
      class="actionButton btn-outline-secondary editButton"
    >
    <span class="actions">
      <i class="far fa-pencil"></i>
      {{ 'APP.DESIGN_TEMPLATES.EDIT_DESIGN_TEMPLATE' | translate }}
    </span>
    </button>
  </div>
</div>
