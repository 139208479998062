@if (loggedUser) {
  <div class="opacity-dialog-content event-dialog-content" mat-dialog-content>
    <div (click)="close()" class="close-button">
      <i class="fal fa-times-circle"></i>
    </div>
    <div class="dialog-body">
      <div class="dialog-title">
        {{ "APP.REFERRAL_INVITE.INVITE" | translate }}
      </div>

      <div
        class="referral-card-container"
        [class.referred-users-view]="isReferredUsersView"
      >
        <div class="referral-card-inner">
          <div class="referral-card referral-card-front">
            <div class="referral-card-info card-info-top">
              {{ "APP.REFERRAL_INVITE.INVITE_FROM" | translate }}
              {{ loggedUser.name }}
            </div>
            <div class="referral-card-body">
              <div class="hole-left"></div>
              <div class="hole-right"></div>

              <img [src]="env.logoAsset" alt="website logo" class="logo-img" />

              <div class="referral-code-cont">
                <qrcode
                  [qrdata]="loggedUser.getReferralLink()"
                  [allowEmptyString]="true"
                  [elementType]="'canvas'"
                  [errorCorrectionLevel]="'M'"
                  [margin]="2"
                  [scale]="1"
                  [width]="175"
                  [imageSrc]="
                    env.platformShort === 'partypage'
                      ? './assets/pp_logo_qr.png'
                      : './assets/ep_logo_qr.png'
                  "
                  [imageHeight]="43"
                  [imageWidth]="43"
                ></qrcode>
                <div class="referral-code">{{ loggedUser.referralCode }}</div>
              </div>

              <div class="referral-card-body-placeholder"></div>
            </div>

            <div class="referral-card-info card-info-bottom">
              {{ env.platform }}
            </div>
          </div>

          <div class="referral-card referral-card-back">
            <div class="referral-card-info card-info-top">
              {{ "APP.REFERRAL_INVITE.INVITE_FROM" | translate }}
              {{ loggedUser.name }}
            </div>
            <div class="referral-card-body">
              <div class="hole-left"></div>
              <div class="hole-right"></div>

              <img [src]="env.logoAsset" alt="website logo" class="logo-img" />

              <div class="referral-card-title">
                {{ referredUsers?.length }}
                {{ "APP.REFERRAL_INVITE.REFERRALS" | translate }}
              </div>

              <div class="referred-users">
                @for (referredUser of referredUsers; track referredUser.id) {
                  <div class="referred-user">
                    <app-user-avatar
                      class="user-avatar"
                      [user]="referredUser"
                      [size]="40"
                      [placeholderFontSize]="14"
                    ></app-user-avatar>
                    <div class="referred-user-info">
                      <div class="referred-user-name">
                        {{ referredUser.name }}
                      </div>
                      <div class="referred-user-join-date">
                        {{ "APP.REFERRAL_INVITE.JOINED" | translate }}:
                        {{ referredUser.createdAt | date: "dd.MM.yyyy" }}
                      </div>
                    </div>
                  </div>
                }
              </div>
            </div>

            <div class="referral-card-info card-info-bottom">
              {{ env.platform }}
            </div>
          </div>
        </div>
      </div>

      <div class="referrals-info">
        @if (referredUsers && referredUsers.length > 0) {
          @if (isReferredUsersView) {
            <span>{{ "APP.REFERRAL_INVITE.INVITE_MORE" | translate }}</span
            >&nbsp;<span class="text-link" (click)="setReferredUsersView(false)"
              >{{ "APP.REFERRAL_INVITE.VIEW_QR_CODE" | translate }}.</span
            >
          } @else {
            <span>{{
              "APP.REFERRAL_INVITE.REFFERED_USERS_INFO"
                | translate: { numReferrals: referredUsers.length }
            }}</span
            >&nbsp;<span class="text-link" (click)="setReferredUsersView(true)"
              >{{ "APP.REFERRAL_INVITE.VIEW_REFERRALS" | translate }}.</span
            >
          }
        } @else {
          <span>{{ "APP.REFERRAL_INVITE.NO_REFERRALS" | translate }}</span>
        }
      </div>
    </div>
    <div mat-dialog-actions class="dialog-actions">
      <button class="btn btn-primary-party-page" (click)="shareInvite()">
        {{ "APP.REFERRAL_INVITE.SHARE_INVITE" | translate }}
      </button>
      <button class="btn btn-primary-outline" (click)="copyCode()">
        {{ "APP.REFERRAL_INVITE.COPY_INVITE_CODE" | translate }}
      </button>
    </div>
  </div>
}
