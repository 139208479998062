import { Component, OnInit, OnDestroy } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { BackService } from '@services/shared/back.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { BackButtonComponent } from '@modules/shared/components/back-button/back-button.component';
import { environment as env } from '@environments/environment';

@Component({
  selector: 'app-header',
  standalone: true,
  imports: [CommonModule, RouterModule, BackButtonComponent],
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss',
})
export class HeaderComponent implements OnInit, OnDestroy {
  showBackButton = false;

  /** Subject that emits when the component has been destroyed. */
  private _onDestroy = new Subject<void>();

  constructor(private backService: BackService) {}

  ngOnInit(): void {
    this.backService.showBackButton$
      .pipe(takeUntil(this._onDestroy))
      .subscribe((showBackButton) => {
        this.showBackButton = showBackButton;
      });
  }

  ngOnDestroy(): void {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  protected env = env;
}
